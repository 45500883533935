export enum InvestorType {
  CORP = "corp",
  JOINT = "joint",
  INDV = "indv",
  TRUST = "trust",
}

/*trust sub-types
Standard
Olympia Trust Company
ComputerShare Trust Company of Canada
Canadian Western Trust Company
Blueshore Financial
Westminster Savings Credit Union
Community Trust

LIF
LIRA
LRIF
LRSP
RESP
RIF
RIF (Prescribed)
RIF (Spousal)
RLIF
RLSP
RRIF
RRSP
RSP
RSP (Spousal)
SDRSP
TFSA

*/

export const investorDetails: { [key in InvestorType]: any } = {
  [InvestorType.INDV]: {
    hasSubType: false,

    //typically for Trusts & Corps
    hasUniqueIdentificationNumber: false,

    //typically for Trusts
    hasTrustName: false,
    hasPlanType: false,

    //typically for Corps
    hasUniqueLegalName: false,
    hasUniqueTaxId: false,

    hasMembers: false,
    requiresMembers: false,
    membersLabel: "",
  },
  [InvestorType.JOINT]: "Joint",
  [InvestorType.TRUST]: "Trust",
  [InvestorType.CORP]: "Corporation",
};

export class InvestorTypeHelper {
  private investorType: InvestorType;
  static _readableInvestorTypes: { [key in InvestorType]: string } = {
    [InvestorType.INDV]: "Individual",
    [InvestorType.JOINT]: "Joint",
    [InvestorType.TRUST]: "Trust",
    [InvestorType.CORP]: "Corporation",
  };

  static _hasSubType: { [key in InvestorType]: boolean } = {
    [InvestorType.INDV]: false,
    [InvestorType.JOINT]: false,
    [InvestorType.TRUST]: true,
    [InvestorType.CORP]: false,
  };

  //AKA Corporation number or trust number
  static _hasUniqueIndentificationNumber: { [key in InvestorType]: boolean } = {
    [InvestorType.INDV]: false,
    [InvestorType.JOINT]: false,
    [InvestorType.TRUST]: true,
    [InvestorType.CORP]: true,
  };

  static _hasTrustCompany: { [key in InvestorType]: boolean } = {
    [InvestorType.INDV]: false,
    [InvestorType.JOINT]: false,
    [InvestorType.TRUST]: true,
    [InvestorType.CORP]: false,
  };

  static _hasUniqueLegalName: { [key in InvestorType]: boolean } = {
    [InvestorType.INDV]: false,
    [InvestorType.JOINT]: false,
    [InvestorType.TRUST]: false,
    [InvestorType.CORP]: true,
  };

  static _hasUniqueTaxId: { [key in InvestorType]: boolean } = {
    [InvestorType.INDV]: false,
    [InvestorType.JOINT]: false,
    [InvestorType.TRUST]: false,
    [InvestorType.CORP]: true,
  };

  static _membership: { [key in InvestorType]: { hasMembers: boolean; required: boolean; label: string | null; labelPlural: string | null } } = {
    [InvestorType.INDV]: { hasMembers: false, required: false, label: null, labelPlural: null },
    [InvestorType.JOINT]: { hasMembers: true, required: true, label: "Individual", labelPlural: "Individuals" },
    [InvestorType.TRUST]: { hasMembers: true, required: false, label: "Trustee", labelPlural: "Trustees" },
    [InvestorType.CORP]: { hasMembers: true, required: false, label: "Director", labelPlural: "Directors" },
  };

  static hasSubType(a: InvestorType | string) {
    var investorType: InvestorType = a as InvestorType;
    return InvestorTypeHelper._hasSubType[investorType];
  }

  static getMembershipAvailability(a: InvestorType | string) {
    var investorType: InvestorType = a as InvestorType;
    return InvestorTypeHelper._membership[investorType];
  }

  constructor(t: string) {
    this.investorType = t as InvestorType;
  }

  static toReadableString(a: InvestorType | string) {
    var investorType: InvestorType = a as InvestorType;
    return InvestorTypeHelper._readableInvestorTypes[investorType];
  }

  static _enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
  }

  static getAllInvestorTypeMembers() {
    var all: string[] = [];
    for (const t of InvestorTypeHelper._enumKeys(InvestorType)) {
      //all.push(InvestorTypeHelper.toReadableString(InvestorType[t]));
      all.push(t);
    }
    return all;
  }

  static getAllInvestorTypes() {
    var all: string[] = [];
    for (const t of InvestorTypeHelper._enumKeys(InvestorType)) {
      all.push(InvestorType[t]);
      //all.push(t);
    }
    return all;
  }
}
