import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { Paper, Typography } from "@material-ui/core";
import CountryLocationInput from "./CountryLocationInput";
import locationData from "./LocationData-CA-ON";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "7px",
  },
}));

export interface AllLocationsInputProps {
  initialLtvs: any;

  addLtv: (placeId: any, tag: any, ltv: any) => void;
  removeLtv: (placeId: any, tag: any) => void;

  ltvBounds: { min: number; max: number };
}
/*
 
    searchable: [],
    full: {},
    t1NameList: []

*/
export default function AllLocationsInput(props: AllLocationsInputProps) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        {locationData.map((c) => {
          return <CountryLocationInput key={c.name} name={c.name} initialLtvs={props.initialLtvs} addLtv={props.addLtv} removeLtv={props.removeLtv} provinces={c.provinces} ltvBounds={props.ltvBounds} />;
        })}
      </div>
    </>
  );
}
