import React, { useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Switch, InputAdornment, FormHelperText, Button } from "@material-ui/core";

import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { Link as RouterLink, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";

import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isURL from "validator/lib/isURL";
import { setDocumentTitle } from "Components/Common/PageHelper";
import { UserContext } from "Authentication/UserProvider";
import ProfileSetupWrapper from "../ProfileSetupWrapper";
import ProfileSetupQuestion, { ProfileSetupQuestionLabel } from "../ProfileSetupQuestion";
import TrueFalseToggle from "Components/Common/TrueFalseToggle";
import TextFieldWithDisableCheckbox from "Components/Common/TextFieldWithDisableCheckbox";
import { uploadDoc, uploadDocumentOld } from "Firebase/InvestorService";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  subDesc: {
    color: "#9e9e9e",
    "& a": {
      color: "#687994",
    },
  },
  marginTop: { marginTop: theme.spacing(3) },
  moreMarginTop: { marginTop: theme.spacing(6) },
  fileInput: {
    display: "none",
  },
}));

export default function OnboardingInvestorBankingInformation() {
  useEffect(() => {
    setDocumentTitle("Banking Information | Investor Account Setup");
  }, []);

  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [instituion, setInstitution] = React.useState(user.activeInvestor?.directDeposit.instituion ?? null);
  const [transit, setTransit] = React.useState(user.activeInvestor?.directDeposit.transit ?? null);
  const [account, setAccount] = React.useState(user.activeInvestor?.directDeposit.account ?? null);
  const [voidCheque, setVoidCheque] = React.useState<any>(!!user.activeInvestor?.docs[0].uri);

  //const [operatingName, setOperatingName] = React.useState(user.activeInvestor?.operatingName ?? null);
  //const [classification, setClassification] = React.useState(user.activeInvestor?.classification ? user.activeInvestor?.classification ?? "INDV" : "INDV");
  //const [logoUrl, setLogoUrl] = React.useState(user.activeInvestor?.logoUrl ? user.activeInvestor?.logoUrl : "");
  //const [permalink, setPermalink] = React.useState(user.activeInvestor?.permalink ?? null);

  const [validationError, setValidationError] = React.useState(false);

  var form: React.RefObject<HTMLFormElement> = React.createRef();

  return (
    <ProfileSetupWrapper
      title="Banking Information"
      role={"investor"}
      validateCallback={() => {
        var error = false;

        //error = error || !logoUrl;

        error = error || !(form.current?.checkValidity() ?? false);

        error = error || !voidCheque;

        if (error) setValidationError(true);

        return !error;
      }}
      locallyStoreUserDataDelegate={() => {
        var currentInvestor = user.activeInvestor;
        if (currentInvestor) {
          currentInvestor.directDeposit.instituion = instituion;
          currentInvestor.directDeposit.transit = transit;
          currentInvestor.directDeposit.account = account;
          //void cheque is auto
        }
      }}
    >
      <form autoComplete="off" ref={form}>
        <ProfileSetupQuestion error={validationError && (!instituion || !transit || !account)} errorMsg={"Please enter the information regarding your direct deposit account."}>
          <ProfileSetupQuestionLabel>
            Bank Account Details *<br />
            <Typography className={classes.subDesc} variant="body2">
              Please ensure accuracy of what you enter here - this is where you payments will be electronically transfered to
            </Typography>
          </ProfileSetupQuestionLabel>
          <TextField
            fullWidth
            required
            variant="outlined"
            id="email"
            label="Institution Number"
            defaultValue={instituion}
            onChange={(e) => {
              setInstitution(e.target.value);
            }}
          />

          <TextField
            className={classes.marginTop}
            fullWidth
            required
            variant="outlined"
            id="email"
            label="Transit Number"
            defaultValue={transit}
            onChange={(e: any) => {
              setTransit(e.currentTarget.value);
            }}
          />

          <TextField
            className={classes.marginTop}
            fullWidth
            required
            variant="outlined"
            label={"Account Number"}
            defaultValue={account}
            onChange={(e) => {
              setAccount(e.currentTarget.value);
            }}
          />
        </ProfileSetupQuestion>

        <ProfileSetupQuestion label="Please upload a copy of your void cheque.">
          <ProfileSetupQuestion error={validationError && !voidCheque} errorMsg="A copy of your void cheque is required.">
            <input
              className={classes.fileInput}
              id={"icon-button-file-VC"}
              type="file"
              onChange={(e) => {
                if (e.target.files && user.uid) {
                  uploadDoc("user", user.uid, "Identification 1", e.target.files[0], "any-authenticated").then((result) => {
                    if (result) {
                      if (user.activeInvestor) {
                        //TODO: find the correct index instead
                        user.activeInvestor.docs[0].uri = result;
                        user.activeInvestor.docs[0].modified = Date.now();
                        setVoidCheque(true);
                      }
                    }
                  });
                }
              }}
            />
            <label htmlFor={"icon-button-file-VC"}>
              <Button color={voidCheque ? "primary" : "secondary"} variant="outlined" aria-label="upload picture" component="span" startIcon={voidCheque ? <CheckCircleIcon /> : <CloudUploadIcon />}>
                Void Cheque
              </Button>
            </label>
          </ProfileSetupQuestion>
        </ProfileSetupQuestion>
      </form>
    </ProfileSetupWrapper>
  );
}
