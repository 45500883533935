import React, { PropsWithChildren, useContext, useEffect } from "react";
import clsx from "clsx";

import { Link as RouterLink, Redirect } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Divider, makeStyles, Typography } from "@material-ui/core";
import { UserContext } from "Authentication/UserProvider";
import { signInWithGoogle } from "Firebase/AuthService";
import PageWrapper from "Components/Common/PageWrapper";
import { getDocument } from "Firebase/StorageService";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
export default function Home() {
  const classes = useStyles();
  const user = useContext(UserContext);
  //getDocument("investors/RiYsZS4O72AgNhsV3Ax5/AdminAgreement.jpg");
  return (
    <PageWrapper title="Home">
      <Typography paragraph>I think we need a dashboard!</Typography>
    </PageWrapper>
  );
}
