import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

console.log("");
console.log("");
console.log("--------------------------------------------------------------------------------");
console.log("");
console.log("__________.__                                         _____    _________");
console.log("\\______   \\  |   ____  ______ __________   _____     /     \\  /   _____/");
console.log(" |    |  _/  |  /  _ \\/  ___//  ___/  _ \\ /     \\   /  \\ /  \\ \\_____  \\ ");
console.log(" |    |   \\  |_(  <_> )___ \\ \\___ (  <_> )  Y Y  \\ /    Y    \\/        \\");
console.log(" |______  /____/\\____/____  >____  >____/|__|_|  / \\____|__  /_______  /");
console.log("        \\/                \\/     \\/            \\/          \\/        \\/");
console.log("");
console.log("Welcome to Blossom's Management System.");
console.log("");
console.log("One questions does come to mind, what are you looking for exactly? Very sus ಠ_ಠ");
console.log("Either way, we'll comply to your requests. Here is some info for you.");
console.log("");
console.log("Btw, we are always looking for developers, so reach out to us with the numbers.");
console.log("");
console.log("Happy hacking,");
console.log("EG from Team Blossom");
console.log("46696E64207468652073656372657420636f6465"); // no, you won't find the answer in the code
console.log("");

console.log("");
console.log("--------------------------------------------------------------------------------");
console.log("");
console.log("");

console.log("> Initiatizing Authentication Services....");
firebase.initializeApp(firebaseConfig);
console.log("> Authentication Services initialized successfully.");

var useLocal: boolean = true && window.location.hostname === "localhost";
if (window.location.hostname === "localhost" && useLocal && process.env.REACT_APP_IS_LOCAL) {
  console.log(">! testing locally -- hitting local functions and firestore emulators");
  firebase.functions().useEmulator("localhost", 5001);
  /*firebase.firestore().settings({
    host: "localhost:8080",
    ssl: false,
  });*/
} else {
  console.log("> Production deployment.");
}

const p = new firebase.auth.GoogleAuthProvider();
//p.addScope("https://www.googleapis.com/auth/contacts.readonly");
console.log(process.env.REACT_APP_DOMAIN);
export const provider = p;
export const auth = firebase.auth();
export const functions = useLocal && process.env.REACT_APP_IS_LOCAL ? firebase.app().functions() : firebase.app().functions("northamerica-northeast1");
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
export const fieldval = firebase.firestore.FieldValue;
export default firebase;
