import { useContext, useEffect, useState } from "react";

import { Link as RouterLink, Redirect, useHistory } from "react-router-dom";
import { Box, Button, ButtonBase, Divider, makeStyles, Typography } from "@material-ui/core";
import { UserContext } from "Authentication/UserProvider";
import PageWrapper from "Components/Common/PageWrapper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LoadingSpinner from "Components/Common/LoadingSpinner";
import SubPageRoutes from "./SubPageRoutes";
import IconButton from "@material-ui/core/IconButton";
import { getAllPendingOrApprovedInvestorsInfo, getLastCapitalUpdateRequest, sendCapitalUpdateRequest } from "Firebase/InvestorService";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import Investor, { InvestorStatus } from "Models/Investor";
import { InvestorTypeHelper } from "Shared/Investor/InvestorType";
import { format, formatDistance } from "date-fns";
import { Alert, Skeleton } from "@material-ui/lab";
import ButtonWithSpinner from "Components/Common/Button/ButtonWithSpinner";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { SKELETON_TABLE_ROW_HEIGHT } from "Utils/Skeleton";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  type: {
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
}));

var rows: any[] = [];
export default function RequestCapitalUpdate() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [investors, setInvestors] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLastRequest, setLoadingLastRequest] = useState<boolean>(true);
  const [sent, setSent] = useState<string>("not sent");
  const [lastRequest, setLastRequest] = useState<any>(null);
  //const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);

  const updateData = (isMounted: boolean, lastRequestedOn: number) => {
    if (isMounted) {
      setLoading(true);

      const promise = getAllPendingOrApprovedInvestorsInfo().then((investors) => {
        console.log(investors);
        rows = [];
        investors.forEach((ii: any) => {
          const i: Investor = Investor.fromDataDocument(ii.id, ii.data);

          if (i.status === InvestorStatus.Approved || i.status === InvestorStatus.Onboarding) {
            rows.push({
              id: ii.id,
              name: (
                <>
                  {i.getDisplayName()[0]}
                  <Typography className={classes.type} variant="subtitle2">
                    {i.getDisplayName()[1]} ({InvestorTypeHelper.toReadableString(i.investorType ?? "indv") + (i.investorSubType !== "" ? i.investorSubType : "")})
                  </Typography>
                </>
              ),
              lastUpdated: i.availableCapital[0].modified,
              updatedSinceLastRequest: i.availableCapital[0].modified > lastRequestedOn,
            });
          }
        });
        if (isMounted) {
          setLoading(false);
          setInvestors(rows);
        }
      });
    }
    /*
    const promise = getUserInvites().then((invites) => {
      rows = [];
      invites.forEach((i: any) => {
        rows.push(createData(i.firstName + " " + i.lastName, i.email, i.role, i.created, i.emailLastSent));
      });
      setLoading(false);
      setUsers(rows);
    });*/
  };

  useEffect(() => {
    let isMounted = true;

    //updateData(isMounted);

    getLastCapitalUpdateRequest().then((results) => {
      setLastRequest(results);
      setLoadingLastRequest(false);
      updateData(isMounted, results ? results.sentOn : 0);
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <PageWrapper title="Investors" subPages={SubPageRoutes} selectedPageRoute="">
        <Box position="relative">
          {loadingLastRequest ? (
            <LoadingSpinner />
          ) : (
            <>
              {sent === "not sent" && (
                <>
                  <Alert severity="error">Sending a Capital Update Request will send an email to ALL investors!</Alert>
                  {lastRequest && (
                    <>
                      <br />
                      <Alert severity="info">
                        Last request was sent <b>{formatDistance(lastRequest.sentOn, new Date())} ago</b> on {format(lastRequest.sentOn, "MMM dd, yyyy 'at' h:m aaa")}.
                      </Alert>
                    </>
                  )}
                </>
              )}
              {sent === "sent" && (
                <>
                  <Alert severity="success">Capital Update Request was successfully sent to all investors! </Alert>
                  {lastRequest && (
                    <>
                      <br />
                      <Alert severity="info">
                        Last request was sent <b>{formatDistance(lastRequest.sentOn, new Date())} ago</b> on {format(lastRequest.sentOn, "MMM dd, yyyy 'at' h:m aaa")}.
                      </Alert>
                    </>
                  )}
                </>
              )}
              {sent === "sending" && <Alert severity="warning">Please wait while the requests are being sent out. </Alert>}

              <br />
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Last Updated</TableCell>
                      <TableCell align="right">Responded to Request?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Skeleton height={SKELETON_TABLE_ROW_HEIGHT} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {investors.length > 0 ? (
                          rows.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="right">{formatDistance(new Date(row.lastUpdated), new Date())} ago</TableCell>
                              <TableCell align="right">{row.updatedSinceLastRequest ? <CheckIcon /> : <CloseIcon color="error" />}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>No investors found.</TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <ButtonWithSpinner
                showSpinner={sent === "sending"}
                disabled={sent === "sent"}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSent("sending");
                  sendCapitalUpdateRequest().then((request) => {
                    setSent("sent");
                    setLastRequest(request);
                  });
                  window.scrollTo(0, 0);
                }}
              >
                Send New Request
              </ButtonWithSpinner>
            </>
          )}
        </Box>
      </PageWrapper>
    </>
  );
}
