import React, { useCallback, useContext, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { FormControl, FormControlLabel, FormGroup, Checkbox, Box, Grid, TextField, InputAdornment, OutlinedInput, InputLabel, Divider, IconButton, Button, ButtonGroup, Paper, Slider } from "@material-ui/core";
import ProfileSetupWrapper from "../ProfileSetupWrapper";

import { useHistory } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InfoIcon from "@material-ui/icons/Info";

import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { setDocumentTitle } from "Components/Common/PageHelper";
import { UserContext } from "Authentication/UserProvider";
import InvestorFinderProducts from "Models/InvestorFinderProducts";
import ProfileSetupQuestion, { ProfileSetupQuestionLabel } from "../ProfileSetupQuestion";
import MinMaxField from "Components/Common/TextField/MinMaxField";
import TrueFalseToggle from "Components/Common/TrueFalseToggle";
import MinMaxFieldWithOverrideCheckBox from "Components/Common/TextField/MinMaxFieldWithOverrideCheckBox";
import { MAX_MORTGAGE_AMOUNT } from "Components/Common/Constants";
import Investor from "Models/Investor";
import FalsifiableTextFieldWithCheckbox from "Components/Common/TextField/FalsifiableTextFieldWithCheckbox";
import AllLocationsInput from "Components/Common/LocationSimple/AllLocationsInput";

import { MIN_LTV, MAX_LTV } from "Components/Common/Constants";

const useStyles = makeStyles((theme) => ({
  subDesc: {
    color: "#9e9e9e",
    "& a": {
      color: "#687994",
    },
  },
  radioLabel: {
    textAlign: "center",
    margin: "0",
    "& label": {
      fontSize: "0.8rem",
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  location: {
    marginTop: theme.spacing(2),
  },
  copyButton: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  addMarginBottom: {
    marginBottom: theme.spacing(2),
  },
  populationFilterRoot: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  populationFilterLabel: {
    paddingTop: "5px",
    paddingRight: theme.spacing(0),
  },
  populationFilterSlider: {
    flex: 1,
  },
  alertInfo: {
    marginBottom: theme.spacing(2),
  },
  propertyLTVComponentWrapper: {
    width: "100%",
  },
  propertyLTVComponentJoinedWrapper: {},
  propertyLTVJoiner: {
    textAlign: "center",
    "& .MuiSvgIcon-root": {
      fill: theme.palette.primary.main,
    },
  },
}));
const marks = [
  {
    value: 0,
    label: "0",
    scaledValue: 0,
  },
  {
    value: 20,
    label: "50K",
    scaledValue: 50000,
  },
  {
    value: 50,
    label: "500K",
    scaledValue: 500000,
  },
  {
    value: 250,
    label: "500K",
    scaledValue: 500000,
  },
  {
    value: 100,
    label: "500K",
    scaledValue: 3000000,
  },
];
function valuetext(value: any) {
  return `${value}°C`;
}

var minv = Math.log(1);
var maxv = Math.log(3000000);
var scale = (maxv - minv) / (100 - 0);

export default function OnboardingInvestorMortgageLTV() {
  useEffect(() => {
    setDocumentTitle("Mortgage LTV Details | Profile Setup");
  }, []);

  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();

  const [validationError, setValidationError] = React.useState(false);

  const [propertyLTV, setPropertyLTV] = React.useState(user.activeInvestor?.mortgageCriteria.propertyLTV);
  const propertyLTVIgnore = [];
  const [locations, setLocations] = React.useState<any>(user.activeInvestor?.mortgageCriteria.locations ?? { ltv: {}, tags: [] });

  const [locationMaxLTVs, setLocationMaxLTVs] = React.useState({});

  const checkForNumber = (value: any, sectionName: string) => {
    if (value == null || value === "") return "Please enter both max & min values for " + sectionName + ".";

    return false;
  };

  const checkForMinMaxError = (min: any, max: any, sectionName?: any) => {
    if (min === null || max === null || min === "" || max === "") return "Please enter both max & min values for " + sectionName + ".";

    if (min > max) {
      return "Min values must be less than or equal to Max.";
    }

    return false;
  };

  const checkForAllObjectsNotSetError = (obj: any, keyList: any, sectionName?: any) => {
    var error: string | boolean = false;

    keyList.forEach((key: any) => {
      if (obj[key] == null) error = "Please enter a value for all fields for " + sectionName;
    });

    return error;
  };

  var locationLTVFunctions = { getResults: () => {} };

  const getMaxPropertyLTV = () => {
    var maxLTV = -1;
    InvestorFinderProducts.variableToDisplayText.property.order.forEach((i: string) => {
      maxLTV = Math.max(maxLTV, propertyLTV ? propertyLTV[i] : maxLTV);
    });
    return maxLTV;
  };

  const getMaxLocationLTV = () => {
    var maxLTV = -1;

    if (!locations) return -1;

    Object.keys(locations.ltv).forEach((placeId) => {
      maxLTV = Math.max(maxLTV, locations.ltv[placeId] === "" ? -1 : locations.ltv[placeId]);
    });

    return maxLTV;
  };

  const hasErrorLocationLTVs = () => {
    if (!locations) return true;

    var locationsCount = 0;
    console.log("###", "check", locations);

    for (const placeId in locations.ltv) {
      locationsCount++;
      if (locations.ltv[placeId] === "" || locations.ltv[placeId] < MIN_LTV || locations.ltv[placeId] == null) return true;
    }

    return locationsCount === 0;
  };

  const handleRemoveLocationLtv = (placeId: any, tag: any) => {
    var tmp = { ...locations };
    delete tmp.ltv[placeId];
    tmp.tags = tmp.tags.filter((v: any) => {
      return v !== tag;
    });
    setLocations(tmp);
  };
  const handleAddLocationLtv = (placeId: any, tag: any, v: any) => {
    var tmp = { ...locations };
    tmp.ltv[placeId] = v;
    if (!tmp.tags.includes(tag)) tmp.tags.push(tag);

    setLocations(tmp);
  };

  var form: React.RefObject<HTMLFormElement> = React.createRef();

  const propertyLTVComponents = [];
  const propertyLTVJoinableComponents: any[] = [];

  console.log("###", "locationLTV", locations);
  InvestorFinderProducts.variableToDisplayText.property.order.forEach((i: string) => {
    const comp = (
      <FalsifiableTextFieldWithCheckbox
        debug={true}
        isNumber={true}
        includesDecimals={false}
        suffix="%"
        defaultValue={propertyLTV[i] === 0 ? false : propertyLTV[i]}
        label={InvestorFinderProducts.variableToDisplayText.property[i as "singleFamily" | "condominium" | "condoTownhomes" | "mixedUse" | "construction" | "wellAndSeptic" | "rental" | "rooming" | "agriculturalZoned" | "farm"]}
        falsePlaceholder=""
        debounceDependancy={propertyLTV}
        overrideLabel="N/A"
        onChange={(v: any) => {
          var tmpState = { ...propertyLTV, [i]: v == null ? null : v === false ? 0 : parseInt(v) };
          if (i === "rental" && !v) tmpState.rooming = 0;
          else if (i === "agriculturalZoned" && !v) tmpState.farm = 0;

          setPropertyLTV(tmpState);
        }}
      />
    );

    if (["rental", "rooming", "agriculturalZoned", "farm"].indexOf(i) === -1) {
      propertyLTVComponents.push(
        <Grid key={i} item xs={12} sm={6}>
          {comp}
        </Grid>
      );
    } else {
      propertyLTVJoinableComponents.push({ i: i, comp: comp });
    }
  });

  for (var index = 0; index < propertyLTVJoinableComponents.length; index++) {
    const i1 = propertyLTVJoinableComponents[index].i;
    const child1 = propertyLTVJoinableComponents[index].comp;
    index++;

    var i2 = "";
    var child2 = null;
    if ((i1 === "rental" && !!propertyLTV.rental) || (i1 === "agriculturalZoned" && !!propertyLTV.agriculturalZoned)) {
      i2 = propertyLTVJoinableComponents[index].i;
      child2 = propertyLTVJoinableComponents[index].comp;
    }

    propertyLTVComponents.push(
      <Grid key={i1 + i2} item xs={12} sm={6}>
        {child1}
        {((i1 === "rental" && !!propertyLTV.rental) || (i1 === "agriculturalZoned" && !!propertyLTV.agriculturalZoned)) && (
          <div className={classes.propertyLTVJoiner}>
            <ArrowDropDownIcon />
          </div>
        )}
        {child2}
      </Grid>
    );
  }

  return (
    <ProfileSetupWrapper
      title="Mortgage LTV Details"
      validateCallback={() => {
        var error = false;

        error = error || !!checkForAllObjectsNotSetError(propertyLTV, InvestorFinderProducts.variableToDisplayText.property.order);

        error = error || hasErrorLocationLTVs();
        if (error) setValidationError(error);
        return !error;
      }}
      locallyStoreUserDataDelegate={() => {
        var currentInvestor = user.activeInvestor;
        if (currentInvestor) {
          currentInvestor.mortgageCriteria.propertyLTV = propertyLTV ?? {};

          currentInvestor.mortgageCriteria.locations = locations;

          currentInvestor.mortgageCriteria.maxLTV = Math.max(getMaxPropertyLTV(), getMaxLocationLTV());
        }
      }}
    >
      <form autoComplete="off" ref={form}>
        <ProfileSetupQuestion error={validationError && checkForAllObjectsNotSetError(propertyLTV, InvestorFinderProducts.variableToDisplayText.property.order)} errorMsg={checkForAllObjectsNotSetError(propertyLTV, InvestorFinderProducts.variableToDisplayText.property.order, "each property")}>
          <ProfileSetupQuestionLabel>
            Maximum LTV for each property type * <br />
            <Typography className={classes.subDesc} variant="body2">
              Max LTV needs to be between 50-90%.
            </Typography>
          </ProfileSetupQuestionLabel>

          <Grid container spacing={3}>
            {propertyLTVComponents}
          </Grid>
        </ProfileSetupQuestion>

        <ProfileSetupQuestion error={validationError && hasErrorLocationLTVs()} errorMsg={"Please enter at least one LTV within each activated/enabled location."}>
          <ProfileSetupQuestionLabel>
            Select the area(s) you will lend in and enter MAX LTVs each * <br />
            <Typography className={classes.subDesc} variant="body2">
              Max LTV needs to be between 50-90%.
            </Typography>
          </ProfileSetupQuestionLabel>

          {false && (
            <div className={classes.populationFilterRoot}>
              <Typography className={classes.populationFilterLabel}>Population Range: </Typography>
              <Slider
                className={classes.populationFilterSlider}
                track="inverted"
                valueLabelDisplay="auto"
                onChange={(e, v) => {
                  //debounceSetMinLocationPopulation(marks.find((m) => m.value === v).scaledValue);
                }}
                getAriaValueText={valuetext}
                defaultValue={0}
                step={null}
                marks={marks}
              />
            </div>
          )}

          {user.activeInvestor && <AllLocationsInput initialLtvs={locations.ltv} ltvBounds={{ min: MIN_LTV, max: MAX_LTV }} addLtv={handleAddLocationLtv} removeLtv={handleRemoveLocationLtv} />}
        </ProfileSetupQuestion>
      </form>
    </ProfileSetupWrapper>
  );
}
//scalex={(x) => Math.exp(minv + scale * (x - 0))}
