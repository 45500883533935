import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { Paper, Typography } from "@material-ui/core";
import ProvinceLocationInput from "./ProvinceLocationInput";

const useStyles = makeStyles((theme) => ({}));

export interface CountryLocationInputProps {
  initialLtvs: any;

  name: string;

  addLtv: (placeId: any, tag: any, ltv: any) => void;
  removeLtv: (placeId: any, tag: any) => void;

  ltvBounds: { min: number; max: number };
  provinces: any[];
}
/*

    searchable: [],
    full: {},
    t1NameList: []

*/
export default function CountryLocationInput(props: CountryLocationInputProps) {
  return (
    <>
      {props.provinces.map((p: any, i: any) => {
        return <ProvinceLocationInput key={p.id} name={p.name} initialLtvs={props.initialLtvs} addLtv={props.addLtv} removeLtv={props.removeLtv} locations={p.locations} ltvBounds={props.ltvBounds} />;
      })}
    </>
  );
}
