import React from "react";
import clsx from "clsx";

import { AppBar, IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";

import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import { useHistory, Router } from "react-router-dom";
import { PropsWithChildren } from "react";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 24,
    display: "inline",
  },
  left: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: 1,
    lineHeight: 0,
  },
  button: {
    borderColor: "#ffffff",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  link: {
    marginLeft: theme.spacing(0),
  },
  appBarSpacer: theme.mixins.toolbar,
  toolbar: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  icon: {
    fill: "#FFF",
  },
  darkIcon: {
    fill: "#FFF",
  },
  appBarTransparent: {
    backgroundColor: "transparent",
  },
  appBar: {},
}));

interface Props extends PropsWithChildren<any> {
  title: string;
  onBackClick?: () => void;
  onCloseClick?: () => void;
  transparent: boolean;
  isFullScreen?: boolean;
}

export default function SubPageBar(props: Props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <AppBar position="absolute" className={props.transparent ? classes.appBarTransparent : classes.appBar} color={props.transparent ? "secondary" : "primary"}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            {props.onBackClick && (
              <IconButton edge="start" color="default" aria-label="back" onClick={props.onBackClick}>
                <ArrowBackIcon className={clsx(props.transparent && classes.icon, !props.transparent && classes.darkIcon)} />
              </IconButton>
            )}
            <Typography variant="h6">{props.title}</Typography>
          </div>
          <div>
            {props.onCloseClick && (
              <IconButton edge="start" color="default" aria-label="close" onClick={props.onCloseClick}>
                <CloseIcon className={clsx(props.transparent && classes.icon, !props.transparent && classes.darkIcon)} />
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {!props.isFullScreen && <div className={classes.appBarSpacer} />}
    </>
  );
}
