import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Button, IconButton, Paper, TextField, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import LocationInput from "./LocationInput";

const provinceStyles = makeStyles((theme) => ({
  root: {
    //padding: theme.spacing(2),
    //margin: theme.spacing(2),
  },
  rootNoChild: {
    //padding: theme.spacing(1),
    //margin: theme.spacing(1),
    //marginLeft: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerButton: {
    color: "rgba(0, 0, 0, 0.87)",
    flex: 1,
    justifyContent: "flex-start",
    textAlign: "left",
  },
  headerName: {
    flex: 1,
    justifyContent: "flex-start",
    textAlign: "left",
  },
}));

export interface ProvinceLocationInputProps {
  initialLtvs: any;
  name: string;

  addLtv: (placeId: any, tag: any, ltv: any) => void;
  removeLtv: (placeId: any, tag: any) => void;

  ltvBounds: { min: number; max: number };
  locations: any[];
}

export default function ProvinceLocationInput(props: ProvinceLocationInputProps) {
  const classes = provinceStyles();
  const [showChildren, setShowChildren] = React.useState(false);
  const [value, setValue] = React.useState("");
  return (
    <>
      <div className={clsx(props.locations && classes.root, !props.locations && classes.rootNoChild)}>
        {false && (
          <div className={classes.header}>
            <Button className={clsx(classes.headerButton)} onClick={() => setShowChildren(!showChildren)}>
              <Typography className={classes.headerName} variant="body1">
                {props.name}
              </Typography>
              <Typography className={classes.headerName} variant="body1"></Typography>
              {<>{showChildren ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</>}
            </Button>
          </div>
        )}
        {props.locations.map((l) => {
          return <LocationInput key={l.id} placeId={l.id} name={l.name} tag={l.tag} mapUrl={l.mapUrl} initialLtv={props.initialLtvs[l.id] ?? null} addLtv={props.addLtv} removeLtv={props.removeLtv} ltvBounds={props.ltvBounds} />;
        })}
      </div>
    </>
  );
}
