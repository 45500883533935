import { useContext, useEffect, useState } from "react";
import clsx from "clsx";

import { Link as RouterLink, Redirect } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Box, makeStyles, Typography, Tooltip } from "@material-ui/core";
import { UserContext } from "Authentication/UserProvider";
import PageWrapper from "Components/Common/PageWrapper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LoadingSpinner from "Components/Common/LoadingSpinner";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import AddInvestorDialog from "Pages/Users/AddInvestorDialog";
import SubPageRoutes from "./SubPageRoutes";
import { deleteUserInvite, getPendingInvestors, notifyUserForPendingInvestor } from "Firebase/Users";
import { timeLapsedText } from "Utils/Time";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import Skeleton from "@material-ui/lab/Skeleton";
import { SKELETON_TABLE_ROW_HEIGHT } from "Utils/Skeleton";
import { InvestorTypeHelper } from "Shared/Investor/InvestorType";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
}));
function createData(id: any, name: any, email: any, role: any, investor: any, created: any, emailLastSent: any) {
  return { id, name, email, role, investor, created, emailLastSent };
}

var rows: any[] = [];
export default function ListPendingInvestors() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);

  const updateData = () => {
    setLoading(true);
    const promise = getPendingInvestors().then((pendingInvestors) => {
      rows = [];
      pendingInvestors.forEach((i: any) => {
        rows.push(createData(i.id, i.members[0].name, i.members[0].email, i.role, InvestorTypeHelper.toReadableString(i.investorType) + (i.investorSubType !== "" ? " - " + i.investorSubType : ""), i.created, i.emailLastSent));
      });
      setLoading(false);
      setUsers(rows);
    });
  };

  useEffect(() => {
    let isMounted = true;
    const promise = getPendingInvestors().then((pendingInvestors) => {
      rows = [];
      pendingInvestors.forEach((i: any) => {
        rows.push(createData(i.id, i.members[0].name, i.members[0].email, i.role, InvestorTypeHelper.toReadableString(i.investorType) + (i.investorSubType !== "" ? " - " + i.investorSubType : ""), i.created, i.emailLastSent));
      });
      if (isMounted) {
        setLoading(false);
        setUsers(rows);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const handleOpenAddUserDialog = () => {
    setOpenAddUserDialog(true);
  };
  const handleCloseAddUserDialog = () => {
    updateData();
    setOpenAddUserDialog(false);
  };
  return (
    <>
      <PageWrapper title="Users" subPages={SubPageRoutes} selectedPageRoute="">
        <Box position="relative">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Role</TableCell>
                  <TableCell align="right">Investor</TableCell>
                  <TableCell align="right">Created</TableCell>
                  <TableCell align="right">Email Sent</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Skeleton height={SKELETON_TABLE_ROW_HEIGHT} />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {users.length > 0 ? (
                      rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.email}</TableCell>
                          <TableCell align="right">{row.role}</TableCell>
                          <TableCell align="right">{row.investor}</TableCell>
                          <TableCell align="right">{timeLapsedText(row.created)}</TableCell>
                          <TableCell align="right">{timeLapsedText(row.emailLastSent)}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="small"
                              aria-label="delete"
                              onClick={() => {
                                setLoading(true);
                                deleteUserInvite(row.id).then(() => {
                                  updateData();
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              aria-label="send"
                              onClick={() => {
                                setLoading(true);
                                notifyUserForPendingInvestor(row.id).then(() => {
                                  updateData();
                                });
                              }}
                            >
                              <SendIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>No pending invites.</TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Tooltip title="Add User" aria-label="AddUser" placement="right">
          <Fab color="secondary" className={classes.fab} onClick={handleOpenAddUserDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </PageWrapper>
      <AddInvestorDialog open={openAddUserDialog} handleClose={handleCloseAddUserDialog} />
    </>
  );
}
