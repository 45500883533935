import { Palette, PaletteOptions } from "@material-ui/core/styles/createPalette";
import { createMuiTheme } from "@material-ui/core";

import grey from "@material-ui/core/colors/grey";

export const customColors = {
  chips: {
    blue: {
      color: "#1E8DDE",
      background: "#DAEFFF",
    },
    coral: {
      color: "#fff",
      background: "#fe6f61",
    },
  },
  padding: {
    xs: "10px",
    sm: "30px",
    md: "30px",
    lg: "30px",
  },
};

const theme = createMuiTheme({
  palette: {
    default: {
      main: "#112941",
    },
    primary: {
      light: "#1967d2",
      main: "#112941",
      dark: "#476282",

      contrastText: "#fff",
    },
    secondary: {
      light: "#e46d52",
      main: "#de4927",
      dark: "#9b331b",
      contrastText: "#fff",
    },

    background: {
      default: "#f6f7f9",
    },
  },
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
    h1: {
      fontFamily: ["Arvo", '"Helvetica Neue"', "Arial", "sans-serif"],
      fontWeight: 400,
      fontSize: "1.7rem",
    },
    h2: {
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"],
      fontSize: "1.6rem",
    },
    h3: {
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"],
      fontSize: "1.5rem",
    },
    h4: {
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"],
      fontSize: "1.4rem",
    },
    h5: {
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"],
      fontSize: "1.3rem",
    },
    h6: {
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"],
      fontSize: "1.2rem",
    },
  },
  overrides: {
    MuiPaper: {
      outlined: true,
      elevation0: {
        borderColor: "#FF0000",
        borderWidth: "2px",
      },
      elevation1: {
        borderColor: grey[300],
        borderWidth: "1px",
        borderStyle: "solid",
        boxShadow: "none",
        WebkitBoxShadow: "none",
      },
    },
    MuiChip: {
      colorPrimary: {
        color: "#ffffff",
        backgroundColor: "#00000040",
      },
      deleteIconColorPrimary: {
        color: "#FFFFFF90",
        "&:hover, &:focus": {
          color: "#FFFFFFFF",
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
        WebkitBoxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          WebkitBoxShadow: "none",
        },
      },
      root: {
        textTransform: "none",
      },
      containedPrimary: {},
      containedSecondary: {},
    },
  },
});

export default theme;
