import React, { useCallback, useEffect } from "react";
import clsx from "clsx";

import PropTypes from "prop-types";

import { Checkbox, FormControlLabel, InputAdornment, makeStyles, OutlinedInputProps, TextField, Tooltip } from "@material-ui/core";

import debounce from "lodash.debounce";

const useStyles = makeStyles((theme) => ({}));

const customParse = (value: any, isDecimal: boolean) => {
  if (isDecimal) {
    return parseFloat(value);
  } else {
    return parseInt(value);
  }
};

const formatCurrency = (x: any) => {
  return x.toLocaleString();
};

export interface MinMaxFieldProps {
  includesDecimals?: boolean;
  min?: number;
  max?: number;
  displayValueOverrideForMin?: string;
  displayValueOverrideForMax?: string;
  defaultValue: number | null;

  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
  size?: "medium" | "small" | undefined;
  debounceDependancy?: any;

  className?: any;
  required?: boolean;
  prefix?: string;
  suffix?: string;
  label: string;
  onChange: (v: any) => void;
}

export default function MinMaxField({ inputMode = "numeric", includesDecimals = false, ...props }: MinMaxFieldProps) {
  const classes = useStyles();

  const [state, setState] = React.useState<{ value: number | null; displayValue: string | null; checkbox?: boolean }>({ value: null, displayValue: "" });

  const handleChange = (newValue: any) => {
    var tmpState = { ...state };
    if (newValue) newValue = newValue.toString().replace(",", "");

    if (newValue == null) {
      tmpState.value = null;
      tmpState.displayValue = "";
    } else if (newValue === "") {
      tmpState.value = null;
      tmpState.displayValue = "";
    } else {
      tmpState.value = customParse(newValue, includesDecimals);
      tmpState.displayValue = formatCurrency(customParse(newValue, includesDecimals));

      if (props.min != null) {
        if (newValue <= props.min) {
          tmpState.value = customParse(props.min, includesDecimals);
          if (props.displayValueOverrideForMin != null) tmpState.displayValue = props.displayValueOverrideForMin;
          else tmpState.displayValue = formatCurrency(customParse(tmpState.value, includesDecimals));
        }
      }

      if (props.max != null) {
        if (newValue >= props.max && props.max !== 0) {
          tmpState.value = customParse(props.max, includesDecimals);
          if (props.displayValueOverrideForMax != null) tmpState.displayValue = props.displayValueOverrideForMax;
          else tmpState.displayValue = formatCurrency(customParse(tmpState.value, includesDecimals));
        }
      }
    }

    props.onChange(tmpState.value);
    setState(tmpState);
  };

  useEffect(() => {
    handleChange(props.defaultValue);
  }, [props.min, props.max, props.defaultValue]);

  const debounceHandleChange = useCallback(
    debounce((newValue) => handleChange(newValue), 150, { leading: false, trailing: true }),
    [props.min, props.max, props.defaultValue, props.debounceDependancy]
  );
  const inputProps = { inputMode: inputMode };
  if (includesDecimals) {
    inputProps.inputMode = "decimal";
    //inputProps.pattern = "[0-9]+(.[0-9]+)?";
  }
  const InputProps: Partial<OutlinedInputProps> = {};
  if (props.prefix) {
    if (!state.checkbox) InputProps.startAdornment = <InputAdornment position="start">{props.prefix}</InputAdornment>;
  }
  if (props.suffix) {
    if (!state.checkbox) InputProps.endAdornment = <InputAdornment position="end">{props.suffix}</InputAdornment>;
  }

  return (
    <TextField
      className={clsx(props.className)}
      size={props.size}
      value={state.displayValue}
      type="text"
      inputProps={inputProps}
      InputProps={InputProps}
      fullWidth
      variant="outlined"
      label={props.label}
      onChange={(e) => {
        setState({ ...state, displayValue: formatCurrency(e.target.value.toString().replace(",", "")) });
        debounceHandleChange(e.target.value);
      }}
    />
  );
}
