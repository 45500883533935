import { useContext, useEffect, useState } from "react";
import clsx from "clsx";

import { Link as RouterLink, Redirect, useHistory } from "react-router-dom";
import { Box, makeStyles, Typography, Tooltip, Button } from "@material-ui/core";
import { UserContext } from "Authentication/UserProvider";
import PageWrapper from "Components/Common/PageWrapper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LoadingSpinner from "Components/Common/LoadingSpinner";
import { approveInvestor, getInvestorInfo, updateInvestor, uploadDocument } from "Firebase/InvestorService";
import { BrowserRouter as Router, Route, RouteComponentProps } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import InvestorInfoDocRow from "./InvestorInfoDocRow";
import { format } from "date-fns";
import Investor, { InvestorStatus } from "Models/Investor";
import { InvestorType, InvestorTypeHelper } from "Shared/Investor/InvestorType";
import { Alert } from "@material-ui/lab";
import ButtonWithSpinner from "Components/Common/Button/ButtonWithSpinner";

import { Chip, CircularProgress, Divider, Hidden, Link } from "@material-ui/core";
import ContentWrapper from "Components/Common/ContentWrapper";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import EmailIcon from "@material-ui/icons/Email";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { customColors } from "theme";
//import LenderFinderProducts from "Authentication/Models/LenderFinderProducts";
//import { setDocumentTitle } from "Common/DocumentHelper";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ReplayIcon from "@material-ui/icons/Replay";
import { MAX_MORTGAGE_AMOUNT } from "Components/Common/Constants";
import InvestorFinderProducts from "Models/InvestorFinderProducts";
import locationData from "Components/Common/LocationSimple/LocationData-CA-ON";

const useStylesProfileSection = makeStyles((theme) => ({
  childWrapper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  error: {
    border: "2px solid #f44336;",
  },
  errorMsg: {
    marginBottom: theme.spacing(2),
  },
}));

function InvestorProfileSection(props: any) {
  const classes = useStylesProfileSection();
  return (
    <Paper className={clsx(classes.childWrapper, props.error && classes.error, props.className)}>
      {props.error && (
        <Alert severity="error" className={classes.errorMsg}>
          {props.errorMsg}
        </Alert>
      )}

      {props.label && (
        <>
          <Typography component="div">{props.label}</Typography>
          {props.withDivider && <Divider className={classes.divider} />}
        </>
      )}
      {props.children}
    </Paper>
  );
}

function InvestorProfileSectionCustom(props: any) {
  const classes = useStylesProfileSection();
  return (
    <>
      {props.children}
      <Divider className={classes.divider} />
    </>
  );
}

interface MatchParams {
  id: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  fieldHeading: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
  },
  fieldContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fileInput: {
    display: "none",
  },
  standaloneHeading: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },

  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },

  root: {
    maxWidth: "960px",
    marginLeft: "auto",
    marginRight: "auto",
  },

  lenderHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  logo: {
    width: "150px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),

    padding: theme.spacing(2),

    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  businessInfo: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  operatingName: {
    display: "flex",
    alignItems: "center",
    "& .MuiChip-root": {
      marginLeft: theme.spacing(1),
    },
  },
  contact: {
    padding: theme.spacing(2),

    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "auto",
    },

    "& .MuiButtonBase-root": {
      width: "100%",
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-start",
      },
    },
  },

  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addTopMargin: {
    marginTop: theme.spacing(2),
  },
  addRightMargin1: {
    marginRight: theme.spacing(1),
  },
  paperPadding: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: theme.spacing(2),
  },
  generalInfo: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  addDisplayFlex: { display: "flex" },
  addJustifyCenter: { justifyContent: "center" },
  column: { flexDirection: "column" },
  chip: {
    color: customColors.chips.blue.color,
    background: customColors.chips.blue.background,
    fontSize: "11px",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
  },

  lenderTypeChip: {},

  disabledColor: { color: theme.palette.grey[500] },

  subSection: {
    width: "100%",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
  },
  subSectionLabel: {
    width: "100%",
    padding: theme.spacing(1),
    borderBottom: "1px solid " + theme.palette.grey[300],
  },
  subSectionLabelWithoutBorder: {
    width: "100%",
    padding: theme.spacing(1),
  },
  subSectionContent: {
    padding: theme.spacing(1),
  },
  underHeading: {
    color: theme.palette.grey[600],
    textTransform: "uppercase",
    fontSize: "0.7rem",
  },
  t1Container: {
    border: "1px solid " + theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    "&:last-child": {
      marginBottom: theme.spacing(2),
    },
  },

  ltvList: {
    width: "100%",
    display: "flex",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderBottom: "1px solid " + theme.palette.grey[200],
    "&:last-child": {
      borderBottom: "none",
    },
  },
  lightBold: {
    fontWeight: 500,
  },
  locationltvList: {
    width: "100%",
    display: "flex",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: "1px solid " + theme.palette.grey[200],
    "&:last-child": {
      borderBottom: "none",
    },
  },
  locationltvT2List: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(3),
  },
  ltvListLabel: {
    flex: 1,
  },
  yellow: {
    color: "rgb(13, 60, 97)",
    backgroundColor: "rgb(232, 244, 253)",
  },
  blue: {
    color: "rgb(13, 60, 97)",
    backgroundColor: "rgb(232, 244, 253)",
  },
  green: {
    color: "rgb(30, 70, 32)",
    backgroundColor: "rgb(237, 247, 237)",
  },
}));

export default function InvestorInfo(props: MatchProps) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [data, setData] = useState<{ u: any; i: Investor; loading: boolean }>({ u: {}, i: new Investor(""), loading: true });
  const [approving, setApproving] = useState(false);

  const getData = (isMounted: boolean) => {
    getInvestorInfo(props.match.params.id).then((results) => {
      if (results && isMounted) {
        setData({ u: results.u, i: results.i, loading: false });

        setApproving(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;

    getData(isMounted);

    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      <PageWrapper title={!data || data.loading ? "Loading Investor..." : `${data.i?.getDisplayName()[0] + " - " + data.i?.getDisplayName()[1]} (Investor) `}>
        <Box position="relative">
          {!data || data.loading ? (
            <LoadingSpinner />
          ) : (
            <>
              {data.i.status !== InvestorStatus.Approved && (
                <>
                  <Alert severity="error">
                    This Investor requires approval.{" "}
                    <ButtonWithSpinner
                      size="small"
                      variant="contained"
                      color="secondary"
                      showSpinner={approving}
                      onClick={() => {
                        setApproving(true);
                        approveInvestor(data.i).then((success) => {
                          getData(true);
                        });
                      }}
                    >
                      Approve Now
                    </ButtonWithSpinner>
                  </Alert>
                  <br />
                </>
              )}
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Members
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {data.i.members.map((m, i) => (
                      <Typography key={m.email + i} variant="body1" align="left">
                        {m.name}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="left">
                      {InvestorTypeHelper.toReadableString(data.i.investorType ?? "indv") + (data.i.investorSubType !== "" ? " - " + data.i.investorSubType : "")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="left">
                      {data.i.status}
                    </Typography>
                  </Grid>
                </Grid>

                {data.i.investorType === InvestorType.TRUST && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={6} className={classes.fieldContainer}>
                        <Typography variant="caption" align="right" className={classes.fieldHeading}>
                          Trust Company
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="left">
                          {data.i.legalName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6} className={classes.fieldContainer}>
                        <Typography variant="caption" align="right" className={classes.fieldHeading}>
                          Trust Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="left">
                          {data.i.legalIdentificationNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                {data.i.investorType === InvestorType.CORP && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={6} className={classes.fieldContainer}>
                        <Typography variant="caption" align="right" className={classes.fieldHeading}>
                          Legal Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="left">
                          {data.i.legalName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6} className={classes.fieldContainer}>
                        <Typography variant="caption" align="right" className={classes.fieldHeading}>
                          Coporation Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="left">
                          {data.i.legalIdentificationNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Accredited
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="left">
                      {data.i.accreditedInvestor ? "Yes" : "No"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Fully Managed
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        variant="outlined"
                        value={data.i.fullyManaged ?? ""}
                        style={{ textAlign: "left" }}
                        onChange={(e) => {
                          var tmpData = { ...data };
                          tmpData.i.fullyManaged = e.target.value as boolean;
                          setData(tmpData);
                          updateInvestor(tmpData.i);
                        }}
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Laywer
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        variant="outlined"
                        value={data.i.lawyer ?? ""}
                        style={{ textAlign: "left" }}
                        onChange={(e) => {
                          var tmpData = { ...data };
                          tmpData.i.lawyer = e.target.value as string;
                          setData(tmpData);
                          updateInvestor(tmpData.i);
                        }}
                      >
                        <MenuItem value={"Vantage"}>Vantage</MenuItem>
                        <MenuItem value={"Harpreet"}>Harpreet</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <br />
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Typography variant="subtitle2" align="right" className={classes.standaloneHeading}>
                    Notes
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      variant="outlined"
                      value={data.i.internalUse?.notes ?? ""}
                      onChange={(e) => {
                        var tmpData = { ...data };
                        tmpData.i.internalUse.notes = e.target.value as string;
                        setData(tmpData);
                        updateInvestor(tmpData.i);
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>

              {/*
          <Paper className={clsx(classes.lenderHeader, classes.paperPadding)}>
            <div className={classes.businessInfo}>
              <Typography className={classes.operatingName} variant="h5">
                {`${data.i?.getDisplayName()[0] + " - " + data.i?.getDisplayName()[1]} (Investor) `} <Chip className={classes.chip} label={data.i.investorType} size="small" />
              </Typography>

              {data.i.accreditedInvestor && (
                <Typography className={classes.addTopMargin} color="primary">
                  Designated class lender{" "}
                </Typography>
              )}
            </div>
            <div className={classes.contact}>
              {data.i.website && (
                <Button color="secondary" variant="contained" startIcon={<OpenInNewIcon />}>
                  Website
                </Button>
              )}
            </div>
          </Paper>

          <Paper className={clsx(classes.lenderHeader, classes.paperPadding, classes.paperMargin, classes.green)}>
            <Grid container spacing={3}>
              <Grid item xs={6} className={classes.fieldContainer}>
                <Typography variant="caption" align="right" className={classes.fieldHeading}>
                  Fully Managed
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    variant="outlined"
                    value={data.i.fullyManaged ?? ""}
                    style={{ textAlign: "left" }}
                    onChange={(e) => {
                      var tmpData = { ...data };
                      tmpData.i.fullyManaged = e.target.value as boolean;
                      setData(tmpData);
                      updateInvestor(tmpData.i);
                    }}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
*/}

              <Paper className={clsx(classes.lenderHeader, classes.paperPadding, classes.paperMargin, classes.green)}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} sm={4} className={(classes.addJustifyCenter, classes.column)}>
                    <Typography align="center">{data.i.mortgageCriteria.maxLTV}</Typography>
                    <Typography align="center" className={classes.underHeading}>
                      Max LTV
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={4} className={(classes.addJustifyCenter, classes.column)}>
                    <Typography align="center">${(data.i.availableCapital[0].now ?? 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</Typography>
                    <Typography align="center" className={classes.underHeading}>
                      Capital Now
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={4} className={(classes.addJustifyCenter, classes.column)}>
                    <Typography align="center">${(data.i.availableCapital[0].mo6 ?? 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</Typography>
                    <Typography align="center" className={classes.underHeading}>
                      Capital 6MO
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>

              <Paper className={clsx(classes.lenderHeader, classes.paperPadding, classes.paperMargin, classes.blue)}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} sm={4} className={(classes.addJustifyCenter, classes.column)}>
                    <Typography align="center">{data.i.mortgageCriteria.minCreditScore || "Any"}</Typography>
                    <Typography align="center" className={classes.underHeading}>
                      Min Credit Score
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={4} className={(classes.addJustifyCenter, classes.column)}>
                    <Typography align="center">{(data.i.mortgageCriteria && data.i.mortgageCriteria?.maxGDS) ?? 0 >= 100 ? "Any" : data.i.mortgageCriteria.maxGDS}</Typography>
                    <Typography align="center" className={classes.underHeading}>
                      Max GDS
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={4} className={(classes.addJustifyCenter, classes.column)}>
                    <Typography align="center">{(data.i.mortgageCriteria && data.i.mortgageCriteria?.maxTDS) ?? 0 >= 100 ? "Any" : data.i.mortgageCriteria.maxGDS}</Typography>
                    <Typography align="center" className={classes.underHeading}>
                      Max TDS
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>

              <InvestorProfileSection>
                <InvestorProfileSectionCustom>
                  <Typography className={clsx(classes.subSectionLabelWithoutBorder, classes.addDisplayFlex)}>
                    <ReplayIcon className={classes.addRightMargin1} /> Length / Term
                  </Typography>
                </InvestorProfileSectionCustom>

                <Grid container spacing={2}>
                  <Hidden only={!data.i.mortgageCriteria.length.mo6 ? "xs" : undefined}>
                    <Grid container item xs={6} sm={3} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.length.mo6 && classes.disabledColor)}>{data.i.mortgageCriteria.length.mo6 ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} 6 months</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden only={!data.i.mortgageCriteria.length.yr1 ? "xs" : undefined}>
                    <Grid container item xs={6} sm={3} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.length.yr1 && classes.disabledColor)}>{data.i.mortgageCriteria.length.yr1 ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} 1 year</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden only={!data.i.mortgageCriteria.length.yr2 ? "xs" : undefined}>
                    <Grid container item xs={6} sm={3} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.length.yr2 && classes.disabledColor)}>{data.i.mortgageCriteria.length.yr2 ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} 2 years</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden only={!data.i.mortgageCriteria.length.moreyr2 ? "xs" : undefined}>
                    <Grid container item xs={6} sm={3} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.length.moreyr2 && classes.disabledColor)}>
                        {data.i.mortgageCriteria.length.moreyr2 ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} {">"}2 years
                      </Typography>
                    </Grid>
                  </Hidden>
                </Grid>
              </InvestorProfileSection>

              <InvestorProfileSection>
                <InvestorProfileSectionCustom>
                  <Typography className={clsx(classes.subSectionLabelWithoutBorder, classes.addDisplayFlex)}>
                    <CalendarTodayIcon className={classes.addRightMargin1} /> Amortization
                  </Typography>
                </InvestorProfileSectionCustom>

                <Grid container spacing={2}>
                  <Hidden only={!data.i.mortgageCriteria.amortization.interestOnly ? "xs" : undefined}>
                    <Grid container item xs={12} sm={4} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.amortization.interestOnly && classes.disabledColor)}>{data.i.mortgageCriteria.amortization.interestOnly ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} Interest Only</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden only={!data.i.mortgageCriteria.amortization.yr20 ? "xs" : undefined}>
                    <Grid container item xs={12} sm={4} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.amortization.yr20 && classes.disabledColor)}>{data.i.mortgageCriteria.amortization.yr20 ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} 20 years</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden only={!data.i.mortgageCriteria.amortization.yr25 ? "xs" : undefined}>
                    <Grid container item xs={12} sm={4} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.amortization.yr25 && classes.disabledColor)}>{data.i.mortgageCriteria.amortization.yr25 ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} 25 years</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden only={!data.i.mortgageCriteria.amortization.yr30 ? "xs" : undefined}>
                    <Grid container item xs={12} sm={4} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.amortization.yr30 && classes.disabledColor)}>{data.i.mortgageCriteria.amortization.yr30 ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} 30 years</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden only={!data.i.mortgageCriteria.amortization.yr35 ? "xs" : undefined}>
                    <Grid container item xs={12} sm={4} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.amortization.yr35 && classes.disabledColor)}>{data.i.mortgageCriteria.amortization.yr35 ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} 35 years</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden only={!data.i.mortgageCriteria.amortization.yr40 ? "xs" : undefined}>
                    <Grid container item xs={12} sm={4} className={classes.addJustifyCenter}>
                      <Typography className={clsx(classes.addDisplayFlex, !data.i.mortgageCriteria.amortization.yr40 && classes.disabledColor)}>{data.i.mortgageCriteria.amortization.yr40 ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} 40 years</Typography>
                    </Grid>
                  </Hidden>
                </Grid>
              </InvestorProfileSection>

              <InvestorProfileSection>
                <InvestorProfileSectionCustom>
                  <Typography className={clsx(classes.addDisplayFlex)}>
                    <CheckIcon className={classes.addRightMargin1} color="primary" /> Mortgages Details
                  </Typography>
                </InvestorProfileSectionCustom>

                <ContentWrapper>
                  <Grid container spacing={2}>
                    <Grid container item xs={12} sm={4} className={classes.addJustifyCenter}>
                      <div className={classes.subSection}>
                        <Typography align="center" className={clsx(classes.subSectionLabel, classes.addDisplayFlex, classes.addJustifyCenter)}>
                          <MonetizationOnIcon className={classes.addRightMargin1} /> Amount
                        </Typography>
                        <Grid container spacing={2} className={classes.subSectionContent}>
                          <Grid container item xs={12} sm={6} className={(classes.addJustifyCenter, classes.column)}>
                            <Typography align="center">{data.i.mortgageCriteria.amount.min ? "$" + data.i.mortgageCriteria.amount.min.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "Any"}</Typography>
                            <Typography align="center" className={classes.underHeading}>
                              Min
                            </Typography>
                          </Grid>
                          <Grid container item xs={12} sm={6} className={(classes.addJustifyCenter, classes.column)}>
                            <Typography align="center">{data.i.mortgageCriteria.amount.max && data.i.mortgageCriteria.amount.max < MAX_MORTGAGE_AMOUNT ? "$" + (data.i.mortgageCriteria.amount.max ?? 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "Any"}</Typography>
                            <Typography align="center" className={classes.underHeading}>
                              Max
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    {/*
                <Grid container item xs={12} sm={4} className={classes.addJustifyCenter}>
                  <div className={clsx(classes.subSection, classes.blue)}>
                    <Typography align="center" className={clsx(classes.subSectionLabel, classes.addDisplayFlex, classes.addJustifyCenter)}>
                      <TrendingUpIcon className={classes.addRightMargin1} /> Rate
                    </Typography>
                    <Grid container spacing={2} className={classes.subSectionContent}>
                      <Grid container item xs={12} sm={6} className={(classes.addJustifyCenter, classes.column)}>
                        <Typography align="center">{data.i.mortgageCriteria.rate.min + "%"}</Typography>
                        <Typography align="center" className={classes.underHeading}>
                          Min
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} sm={6} className={(classes.addJustifyCenter, classes.column)}>
                        <Typography align="center">{data.i.mortgageCriteria.rate.max + "%"}</Typography>
                        <Typography align="center" className={classes.underHeading}>
                          Max
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid container item xs={12} sm={4} className={classes.addJustifyCenter}>
                  <div className={classes.subSection}>
                    <Typography align="center" className={clsx(classes.subSectionLabel, classes.addDisplayFlex, classes.addJustifyCenter)}>
                      <PieChartIcon className={classes.addRightMargin1} /> Fee
                    </Typography>
                    <Grid container spacing={2} className={classes.subSectionContent}>
                      <Grid container item xs={12} sm={6} className={(classes.addJustifyCenter, classes.column)}>
                        <Typography align="center">{data.i.mortgageCriteria.fee.min ? (data.i.mortgageCriteria.fee.isPercentage ? "" : "$") + data.i.mortgageCriteria.fee.min + (data.i.mortgageCriteria.fee.isPercentage ? "%" : "") : "None"}</Typography>
                        <Typography align="center" className={classes.underHeading}>
                          Min
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} sm={6} className={(classes.addJustifyCenter, classes.column)}>
                        <Typography align="center">{data.i.mortgageCriteria.fee.max ? (data.i.mortgageCriteria.fee.isPercentage ? "" : "$") + data.i.mortgageCriteria.fee.max + (data.i.mortgageCriteria.fee.isPercentage ? "%" : "") : "None"}</Typography>
                        <Typography align="center" className={classes.underHeading}>
                          Max
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
*/}
                  </Grid>

                  <div className={clsx(classes.addTopMargin, classes.subSection)}>
                    <Typography align="left" className={clsx(classes.subSectionLabel, classes.addDisplayFlex)}>
                      <LocationCityIcon className={classes.addRightMargin1} /> Property based LTV
                    </Typography>

                    {InvestorFinderProducts.variableToDisplayText.property.order.map((property: string) => (
                      <div key={property} className={classes.ltvList}>
                        <Typography align="left" className={clsx(classes.ltvListLabel, !data.i.mortgageCriteria.propertyLTV[property] && classes.disabledColor, classes.addDisplayFlex)}>
                          {data.i.mortgageCriteria.propertyLTV[property] ? <CheckIcon className={classes.addRightMargin1} color="primary" /> : <CloseIcon className={classes.addRightMargin1} />} {InvestorFinderProducts.variableToDisplayText.property[property]}
                        </Typography>
                        <Typography align="right" className={clsx()}>
                          {data.i.mortgageCriteria.propertyLTV[property] ? data.i.mortgageCriteria.propertyLTV[property] + "%" : ""}
                        </Typography>
                      </div>
                    ))}
                  </div>

                  <div className={clsx(classes.addTopMargin, classes.subSection)}>
                    <Typography align="left" className={clsx(classes.subSectionLabel, classes.addDisplayFlex)}>
                      <LocationOnIcon className={classes.addRightMargin1} /> Location based LTV
                    </Typography>
                    {locationData[0].provinces[0].locations.map((t1, i) => {
                      if (data.i.mortgageCriteria.locations.tags.includes(t1.tag)) {
                        console.log(t1.tag);
                        return (
                          <div key={i} className={classes.t1Container}>
                            <div className={classes.locationltvList}>
                              <Typography align="left" className={clsx(classes.ltvListLabel, classes.lightBold)}>
                                {t1.shortname}
                              </Typography>
                              <Typography align="right" className={clsx(classes.lightBold)}>
                                {data.i.mortgageCriteria.locations.ltv[t1.id]} %
                              </Typography>
                            </div>
                          </div>
                        );
                      } else return null;
                    })}

                    {/*
                    {false &&
                      //TODO: MAP OUT LOCATIONS
                      data.i.mortgageCriteria.locations.tags.map((loc) => (
                        <div key={loc.label} className={classes.ltvList}>
                          <Typography align="left" className={clsx(classes.ltvListLabel)}>
                            {loc}
                          </Typography>
                          <Typography align="right" className={clsx(classes.ltvListValue)}>
                            {loc + "%"}
                          </Typography>
                        </div>
                      ))}
                    */}
                  </div>
                </ContentWrapper>
              </InvestorProfileSection>
              <br />
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Typography variant="subtitle2" align="right" className={classes.standaloneHeading}>
                    Banking Information
                  </Typography>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Institution
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="left">
                      {data.i.directDeposit.instituion}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Transit
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="left">
                      {data.i.directDeposit.transit}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Account
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="left">
                      {data.i.directDeposit.account}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Typography variant="caption" align="right" className={classes.fieldHeading}>
                      Added to Automator
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        variant="outlined"
                        value={data.i.directDeposit.addedToAutomator ? "true" : "false"}
                        style={{ textAlign: "left" }}
                        onChange={(e) => {
                          var tmpData = { ...data };
                          tmpData.i.directDeposit.addedToAutomator = (e.target.value as string) === "true";
                          setData(tmpData);
                          updateInvestor(tmpData.i);
                        }}
                      >
                        <MenuItem value={"true"}>Yes</MenuItem>
                        <MenuItem value={"false"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <br />
              <br />
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Document Type</TableCell>
                      <TableCell>Modified</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <InvestorInfoDocRow label="Void Cheque" docName="VoidCheque" docIndex={0} documentAccessType="any-authenticated" setData={setData} data={data} classes={classes} />
                    <InvestorInfoDocRow label="Identification 1" docName="ID1" docIndex={1} documentAccessType="any-authenticated" setData={setData} data={data} classes={classes} />
                    <InvestorInfoDocRow label="Identification 2" docName="ID2" docIndex={2} documentAccessType="any-authenticated" setData={setData} data={data} classes={classes} />
                    <InvestorInfoDocRow label="Admin Agreement" docName="AdminAgreement" docIndex={3} documentAccessType="any-authenticated" setData={setData} data={data} classes={classes} />
                    <InvestorInfoDocRow label="KYC" docName="KYC" docIndex={4} documentAccessType="any-authenticated" setData={setData} data={data} classes={classes} />
                    <InvestorInfoDocRow label="Survey" docName="Survey" docIndex={5} documentAccessType="any-authenticated" setData={setData} data={data} classes={classes} />
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </PageWrapper>
    </>
  );
}
