import React, { useContext, useEffect, useState } from "react";

import { Button, CircularProgress, Divider, InputAdornment, makeStyles, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { Link as RouterLink, Redirect } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

import { auth, signInWithGoogle, signOut, maskPhone, enroll2FAMobile, recaptchaVerifier } from "Firebase/AuthService";
import { default as GoogleIcon } from "Icons/Google";

import logo from "full-logo.svg";
import logoDev from "full-logo-dev.svg";
import { UserContext } from "Authentication/UserProvider";
import ContentWrapper from "Components/Common/ContentWrapper";
import SubPageBar from "Components/Navigation/SubPageBar";
import { SettingsPhone } from "@material-ui/icons";

import isMobilePhone from "validator/lib/isMobilePhone";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100vh",
  },
  paper: { maxWidth: 420, flex: 1, margin: "auto" },
  formInput: {
    width: "80%",
  },
  form: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  logoContainer: {
    flexGrow: 1,
    lineHeight: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },
  logo: {
    height: theme.spacing(5),
  },
  forgotPasswordLinkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "0",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  buttonWithSpinner: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

declare global {
  interface Window {
    recaptchaVerifier: any;
  }
}

window.recaptchaVerifier = recaptchaVerifier("2fa-captcha");

export default function SignUpAccount() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();

  window.recaptchaVerifier.reset();
  const [phone, setPhone] = useState("");
  const [sendingCode, setSendingCode] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const [accountCreated, setAccountCreated] = React.useState(false);

  const sendPhoneCode = (phone: string) => {
    setError(null);
    if (isMobilePhone("+1" + phone, "en-CA", { strictMode: true })) {
      setSendingCode(true);
      window.recaptchaVerifier.reset();
      enroll2FAMobile("+1" + phone, user).then((success) => {
        if (success) {
          setCompleted(true);
        } else {
          window.recaptchaVerifier.reset();
          setSendingCode(false);
        }
      });
    } else {
      setSendingCode(false);
      window.recaptchaVerifier.reset();
      setError(<Alert severity="error">Please enter a valid mobile phone number.</Alert>);
    }
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    sendPhoneCode(phone);
  };

  const allowEmailPasswordLogin = true;

  return (
    <>
      {completed && <Redirect to={{ pathname: "/signup/2fa/verify", state: { phone: phone, maskedPhone: maskPhone(phone), referrer: "/signup" } }} />}
      <SubPageBar
        transparent={false}
        isFullScreen={true}
        title="Two-factor Authentication"
        onBackClick={() => {
          history.push("/signup");
        }}
      ></SubPageBar>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <form className={classes.form} noValidate autoComplete="off" onSubmit={onFormSubmit}>
            <Typography align="center" variant="body1">
              Please enter the <b>mobile number</b> you would like to use for two-factor authentication.
            </Typography>
            <>
              {error}
              <div>
                <TextField
                  fullWidth
                  autoComplete="phone"
                  type="tel"
                  name="userPhone"
                  label="Phone #"
                  variant="outlined"
                  onChange={(e: any) => {
                    setPhone(e.currentTarget.value);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                />
              </div>
              <div className={classes.buttonWithSpinner}>
                <Button type="submit" fullWidth={true} disabled={sendingCode || !isMobilePhone("+1" + phone, "en-CA", { strictMode: true })} variant="contained" size="large" color="secondary" endIcon={<ArrowForwardIcon />}>
                  Send Code
                </Button>
                {sendingCode && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
              </div>
            </>
          </form>
        </Paper>
      </div>
    </>
  );
}
