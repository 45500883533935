import React, { PropsWithChildren, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useHistory, Router } from "react-router-dom";

import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button, Divider, IconButton, Paper } from "@material-ui/core";
import SubPageBar from "Components/Navigation/SubPageBar";
import ContentWrapper from "Components/Common/ContentWrapper";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { FormatListBulleted } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import User from "Authentication/User";
import { UserContext } from "Authentication/UserProvider";
import ButtonWithSpinner from "Components/Common/Button/ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  currentStep: {
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  totalSteps: {
    fontSize: "0.7em",
    marginTop: "-5px",
  },
  titleContainer: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "start",
  },
  titles: {
    marginLeft: theme.spacing(2),
  },
  fullCircle: {
    color: "#efefef",
  },
  alert: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  nextButton: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },
  noMail: {
    color: "#8e8e8e",
  },
  buttonContainer: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export interface CircularProgressWithLabelProps {
  current: number;
  total: number;
}

function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  const classes = useStyles();

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" className={classes.fullCircle} size={50} value={100} />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={50} variant="determinate" value={(100 * props.current) / props.total} />
      </Box>
      {props.current !== 0 && (
        <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
          <div>
            <Typography className={classes.currentStep} align="center" variant="caption" component="p" color="textSecondary">{`${props.current}`}</Typography>
            <Typography className={classes.totalSteps} align="center" variant="caption" component="p" color="textSecondary">{`of ${props.total}`}</Typography>
          </div>
        </Box>
      )}
    </Box>
  );
}

export interface SinglePageProps extends PropsWithChildren<any> {
  appBarTitle: string;
  title?: any;
  nextText: string;
  onNextClick: () => Promise<boolean>;
  onCancelClick?: () => any;
  nextUrl: string;
  validateCallback: () => boolean;
}

export default function SinglePage(props: SinglePageProps) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [processing, setProcessing] = React.useState(false);
  const [errorCount, setErrorCount] = React.useState(0);

  const handleNext = async (e: any) => {
    e.preventDefault();
    setProcessing(true);
    const valid = props.validateCallback();

    if (!valid) setErrorCount(errorCount + 1);
    else setErrorCount(0);

    if (valid) {
      var success = await props.onNextClick();
      if (!success) setProcessing(false);
      else history.push(props.nextUrl);
      //console.log("next", getNextUrl(step + 1));
      //setProcessing(false);
    } else {
      setProcessing(false);
    }
  };

  const handleCancel = () => {
    console.info("Save Data");
  };

  useEffect(() => {
    if (errorCount) {
      const refs = [...(document.querySelectorAll("div[error]:not([error='false'])") as any)];

      if (refs && refs.length > 0) refs[0].scrollIntoView({ behavior: "smooth" });
    }
  }, [errorCount]);

  return (
    <>
      <SubPageBar title={props.appBarTitle} transparent={false} />
      <ContentWrapper>
        {(props.title != null || props.onCancelClick != null) && (
          <Paper className={classes.titleContainer}>
            {props.onCancelClick && (
              <IconButton
                onClick={() => {
                  if (props.onCancelClick) props.onCancelClick();
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            {props.title != null && (
              <Typography className={classes.titles} align="left" variant="h5" component="h5">
                {props.title}
              </Typography>
            )}
          </Paper>
        )}

        {errorCount !== 0 && (
          <Alert className={classes.alert} severity="error">
            Please complete all sections with required fields (*).
          </Alert>
        )}
        {props.children}
        {errorCount !== 0 && (
          <Alert className={classes.alert} severity="error">
            Please complete all sections with required fields (*).
          </Alert>
        )}
        <div className={classes.actions}>
          <ButtonWithSpinner
            showSpinner={processing}
            fullWidth={true}
            variant="contained"
            size="large"
            color="secondary"
            endIcon={!processing ? <ArrowForwardIcon /> : null}
            onClick={async (e: any) => {
              handleNext(e);
            }}
          >
            {processing ? "Saving..." : "Save & continue"}
          </ButtonWithSpinner>
        </div>
      </ContentWrapper>
    </>
  );
}
