import React, { Component, PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Button, Tooltip, useMediaQuery } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import logo from "logo.svg";
import logoDev from "logo.svg";
import fullLogo from "full-logo.svg";
import fullLogoDev from "full-logo.svg";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import AssessmentIcon from "@material-ui/icons/Assessment";
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FaceIcon from "@material-ui/icons/Face";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DescriptionIcon from "@material-ui/icons/Description";
import GlobalPageHeader from "./GlobalPageHeader";

const drawerWidth = 200;
interface StyleProps {
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6) + 5,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "48px !important",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  openListIcon: {
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(1),
      justifyContent: "flex-end",
      paddingRight: (props: StyleProps) => (props.open ? theme.spacing(0) : theme.spacing(3)),
      flex: "1",
    },
  },
  openListButton: {
    alignItems: "flex-start",
  },
  coverRemainingSpace: {
    display: "flex",
    flex: 1,
  },
  list: {},
  fullLogo: { height: "20px" },
  logo: { height: "20px" },
  drawerPaper: {
    overflow: "hidden",
    //backgroundColor: theme.palette.primary.main,
  },
  divider: {
    //backgroundColor: "rgba(255, 255, 255, 0.12)",
  },
  logoLink: {
    fontSize: 0,
  },
  listButton: {
    //color: "rgba(255, 255, 255, 0.8)",
    paddingLeft: theme.spacing(1),
    paddingBottom: "9px",
  },
  listIcon: {
    //color: "rgba(255, 255, 255, 0.8)",
    paddingLeft: theme.spacing(0),
    minWidth: "45px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(1),
    },
  },
  listLabel: {
    fontSize: "14px",
    marginBottom: 2,
  },
  mainContainer: { flex: 1, paddingBottom: "70px" },
}));

interface NavItemProps extends PropsWithChildren<any> {
  name: string;
  icon: ReactNode;
  path: string;
  isDrawerOpen: boolean;
  disabled?: boolean;
}
function NavItem(props: NavItemProps) {
  const classes = useStyles({ open: props.isDrawerOpen });

  return (
    <Tooltip title={props.name} aria-label={props.name} placement="right" disableFocusListener={props.isDrawerOpen} disableHoverListener={props.isDrawerOpen} disableTouchListener={props.isDrawerOpen}>
      <ListItem disabled={props.disabled ?? false} component={RouterLink} to={props.path} button className={classes.listButton}>
        <ListItemIcon className={classes.listIcon}>{props.icon}</ListItemIcon>
        <ListItemText className={classes.listLabel} primaryTypographyProps={{ variant: "subtitle2" }} primary={props.name} />
      </ListItem>
    </Tooltip>
  );
}

interface Props extends PropsWithChildren<any> {}
export default function Nav(props: Props) {
  const theme = useTheme();
  const isSmWidthOrBigger = useMediaQuery(theme.breakpoints.up("sm"));
  const isSmWidthOrSmaller = useMediaQuery(theme.breakpoints.down("xs"));
  const isMdWidthOrBigger = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({ open });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isOpen = () => {
    return open || isMdWidthOrBigger;
  };

  const list = () => (
    <>
      <List>
        <NavItem disabled name="Inbox" path="/inbox" icon={<InboxIcon fontSize="small" />} isDrawerOpen={isOpen()} />
      </List>
      <Divider className={classes.divider} />
      <List>
        <NavItem name="Investors" path="/investors" icon={<AccountBalanceIcon fontSize="small" />} isDrawerOpen={isOpen()} />
        <NavItem disabled name="Borrowers" path="/borrowers" icon={<FaceIcon fontSize="small" />} isDrawerOpen={isOpen()} />
        <NavItem disabled name="Mortgages" path="/mortgages" icon={<DescriptionIcon fontSize="small" />} isDrawerOpen={isOpen()} />
        <NavItem disabled name="Reports" path="/reports" icon={<AssessmentIcon fontSize="small" />} isDrawerOpen={isOpen()} />
      </List>
      <Divider className={classes.divider} />
      <List>
        <NavItem name="Users" path="/users" icon={<GroupIcon fontSize="small" />} isDrawerOpen={isOpen()} />
        <NavItem disabled name="Settings" path="/settings" icon={<SettingsIcon fontSize="small" />} isDrawerOpen={isOpen()} />
        <NavItem disabled name="Profile" path="/profile" icon={<AccountCircleIcon fontSize="small" />} isDrawerOpen={isOpen()} />
      </List>
      {!isMdWidthOrBigger && isSmWidthOrBigger && (
        <List className={(classes.listButton, classes.coverRemainingSpace)}>
          <ListItem button onClick={() => setOpen(!open)} className={classes.openListButton}>
            <ListItemIcon className={clsx(classes.listIcon, classes.openListIcon)}>{isOpen() ? <ChevronLeftIcon /> : <ChevronRightIcon />}</ListItemIcon>
          </ListItem>
        </List>
      )}
    </>
  );

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant={isMdWidthOrBigger ? "permanent" : isOpen() ? "temporary" : isSmWidthOrSmaller ? "temporary" : "permanent"}
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isOpen(),
            [classes.drawerClose]: !isOpen(),
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: isOpen(),
              [classes.drawerClose]: !isOpen(),
            }),
          }}
          open={isOpen()}
          onClose={handleDrawerClose}
        >
          <div className={classes.toolbar}>
            {false && !isMdWidthOrBigger && <IconButton onClick={() => !open}>{isOpen() && isSmWidthOrBigger && <ChevronLeftIcon />}</IconButton>}
            <Link component={RouterLink} to="/" className={classes.logoLink}>
              {isOpen() ? <img src={process.env.REACT_APP_ENV === "dev" ? fullLogoDev : fullLogo} alt="logo" className={classes.logo} /> : <img src={process.env.REACT_APP_ENV === "dev" ? logoDev : logo} alt="logo" className={classes.logo} />}
            </Link>
          </div>
          <Divider className={classes.divider} />
          {isSmWidthOrBigger ? (
            list()
          ) : (
            <div className={clsx(classes.list)} role="presentation" onClick={handleDrawerClose}>
              {list()}
            </div>
          )}
        </Drawer>
      </div>
    </>
  );
}
