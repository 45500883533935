import React, { PropsWithChildren, useContext, useEffect, useState } from "react";

import { Button, CircularProgress, Divider, InputAdornment, makeStyles, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { Link as RouterLink, Redirect, RouteComponentProps } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

import { auth, signInWithGoogle, signOut, maskPhone } from "Firebase/AuthService";
import { default as GoogleIcon } from "Icons/Google";

import logo from "full-logo.svg";
import logoDev from "full-logo-dev.svg";
import { UserContext } from "Authentication/UserProvider";
import ContentWrapper from "Components/Common/ContentWrapper";
import SubPageBar from "Components/Navigation/SubPageBar";
import { SettingsPhone } from "@material-ui/icons";

import isInt from "validator/lib/isInt";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { InvestorStatus } from "Models/Investor";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100vh",
  },
  paper: { maxWidth: 420, flex: 1, margin: "auto" },
  formInput: {
    width: "80%",
  },
  form: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  logoContainer: {
    flexGrow: 1,
    lineHeight: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },
  logo: {
    height: theme.spacing(5),
  },
  forgotPasswordLinkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "0",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  buttonWithSpinner: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props extends PropsWithChildren<any> {}
type LocationState = {
  maskedPhone: string;
};
export default function SignUpComplete(props: RouteComponentProps<Props, {}, LocationState>) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.form}>
            <Typography align="center" variant="body1">
              <CheckCircleIcon style={{ fontSize: 60, color: "#4caf50" }} />
            </Typography>
            <Typography align="center" variant="h1">
              Sign up completed!
            </Typography>
            <Typography align="center" variant="body1">
              {user.activeInvestor?.status === InvestorStatus.PendingApproval && <>You have completed setting up your profile. We will reach out to you once your account is activated to provide an overview.</>}
              {user.activeInvestor?.status === InvestorStatus.Onboarding && <>You have completed setting up your profile. We are waiting on other members of your investor account to complete their portions.</>}
            </Typography>
          </div>
        </Paper>
      </div>
    </>
  );
}
