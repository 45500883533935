import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ButtonWithSpinner from "Components/Common/Button/ButtonWithSpinner";
import { addPendingInvestor } from "Firebase/Users";
import { useHistory, Redirect } from "react-router-dom";
import User from "Authentication/User";
import { InvestorType, InvestorTypeHelper } from "Shared/Investor/InvestorType";
import { InvestorMemberStatus } from "Models/Investor";

const useStyles = makeStyles((theme) => ({
  selectSpacer: {
    marginTop: theme.spacing(1),
  },
}));

interface AddUserDialogProps {
  open: boolean;
  handleClose: () => void;
}

export default function AddInvestorDialog(props: AddUserDialogProps) {
  const classes = useStyles();
  const history = useHistory();

  const emptyMembersList = () => {
    return [
      { name: "", email: "", status: InvestorMemberStatus.None, completedSteps: 0 },
      { name: "", email: "", status: InvestorMemberStatus.None, completedSteps: 0 },
      { name: "", email: "", status: InvestorMemberStatus.None, completedSteps: 0 },
      { name: "", email: "", status: InvestorMemberStatus.None, completedSteps: 0 },
    ];
  };

  const [userInfo, setUserInfo] = useState<{ name: string; email: string; role: string; investorType: string; investorSubType: string; members: { name: string; email: string; status: InvestorMemberStatus; completedSteps: number }[] }>({
    name: "",
    email: "",
    role: User.Roles.INVESTOR,
    investorType: InvestorType.INDV,
    investorSubType: "",
    members: emptyMembersList(),
  });

  const [processing, setProcessing] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState("");

  const onFormSubmit = (e: any) => {
    //TODO: Form validation

    e.preventDefault();
    setProcessing(true);
    setError("");
    if (userInfo.email !== "" && userInfo.name !== "" && (InvestorTypeHelper.hasSubType(userInfo.investorType) ? userInfo.investorSubType !== "" : true)) {
      addPendingInvestor({ email: userInfo.email, name: userInfo.name, role: userInfo.role, investorType: userInfo.investorType, investorSubType: userInfo.investorSubType, members: userInfo.members }).then((error) => {
        if (error) {
          setError(error.code);
        }
        setProcessing(false);
        if (!error) onClose();
      });
    } else {
      setProcessing(false);
    }
  };

  const onClose = () => {
    setUserInfo({ name: "", email: "", role: User.Roles.INVESTOR, investorType: InvestorType.INDV, investorSubType: "", members: emptyMembersList() });
    setProcessing(false);
    setError("");

    props.handleClose();
  };
  return (
    <div>
      {redirect && <Redirect to="/users/invites" />}
      <Dialog open={props.open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>{" "}
        <form noValidate autoComplete="off" onSubmit={onFormSubmit}>
          <DialogContent>
            <DialogContentText>Adding a user will not automatically send an invitation email. You will need to trigger that in the next screen.</DialogContentText>
            {error !== "" && (
              <>
                <Alert severity="error">
                  {error === "data/already-exists" && "There is already an invite for at least one member of this invite."}
                  {error !== "data/already-exists" && "An error occured."}
                </Alert>
              </>
            )}

            <TextField
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              required
              onChange={(e) => {
                setUserInfo({ ...userInfo, name: e.target.value });
              }}
            />

            <TextField
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              required
              onChange={(e) => {
                setUserInfo({ ...userInfo, email: e.target.value });
              }}
            />
            <FormControl fullWidth className={classes.selectSpacer}>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userInfo.role === User.Roles.INVESTOR ? User.Roles.INVESTOR : User.Roles.INVESTOR}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, role: (e.currentTarget.value as string) === "0" ? User.Roles.INVESTOR : User.Roles.INVESTOR });
                }}
              >
                <MenuItem value={User.Roles.INVESTOR}>Investor</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.selectSpacer}>
              <InputLabel id="demo-simple-select-label">Investor Type</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userInfo.investorType}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, investorType: e.target.value as string, investorSubType: "", members: emptyMembersList() });
                }}
              >
                {InvestorTypeHelper.getAllInvestorTypes().map((investorType) => (
                  <MenuItem key={investorType} value={investorType}>
                    {InvestorTypeHelper.toReadableString(investorType)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {InvestorTypeHelper.hasSubType(userInfo.investorType) && (
              <TextField
                margin="dense"
                label="Sub Investor Type"
                type="text"
                fullWidth
                required
                value={userInfo.investorSubType}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, investorSubType: e.target.value });
                }}
              />
            )}
            <br />
            <br />
            {InvestorTypeHelper.getMembershipAvailability(userInfo.investorType).hasMembers && (
              <>
                <FormControl fullWidth className={classes.selectSpacer}>
                  <Typography variant="body1">Additional Members</Typography>
                </FormControl>

                {[0, 1, 2, 3].map((i) => (
                  <Grid key={"members" + i} container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        required
                        onChange={(e) => {
                          var tmpMembers = [...userInfo.members];
                          tmpMembers[i].name = e.target.value;
                          setUserInfo({ ...userInfo, members: tmpMembers });
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Email"
                        type="text"
                        fullWidth
                        required
                        onChange={(e) => {
                          var tmpMembers = [...userInfo.members];
                          tmpMembers[i].email = e.target.value;
                          setUserInfo({ ...userInfo, members: tmpMembers });
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={processing} onClick={onClose} color="primary">
              Cancel
            </Button>

            <ButtonWithSpinner showSpinner={processing} type="submit">
              Add
            </ButtonWithSpinner>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
