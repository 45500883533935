import React, { useContext, useEffect } from "react";

import { UserContext } from "Authentication/UserProvider";
import { useHistory, Router, RouteProps, RouteComponentProps, useLocation } from "react-router-dom";
import User from "Authentication/User";
import { clearRecaptchaVerifier, maskPhone } from "Firebase/AuthService";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const WaitFor2FAAuthentication = () => {
  const history = useHistory();
  const user = useContext(UserContext);
  let query = useQuery();

  var done = false;

  const referrer = query.get("referrer") ?? localStorage.getItem("referrer") ?? "/";

  function doRedirect() {
    console.log("> Checking if authentication flow updated...");
    if (user.isLoggedIn() && User.REQUIRE_2FA) {
      console.log("> Authentication flow completed. User is logged in with 2FA.");
      done = true;

      //clearRecaptchaVerifier();

      if (!user.isOnboardingComplete() && !user.isRole(User.Roles.ADMIN)) {
        console.log("> Investor: profile incomplete. Redirecting to complete onboarding.");
        history.replace(user.getNextOnboardingPageLink());
      } else if (user.isOnboardingComplete() && referrer !== "/") {
        console.log("> Investor: profile is complete. Instead of redirecting to placeholder screen, forward to referrer.");
        localStorage.removeItem("referrer");
        history.replace(referrer);
      } else if (user.isOnboardingComplete() && !user.isRole(User.Roles.ADMIN)) {
        console.log("> Investor: profile is complete. Redirecting to 'Complete' placeholder screen.");
        history.replace(`/signup/complete`);
      } else if (user.isRole(User.Roles.ADMIN)) {
        console.log("> Admin in the house! Redirecting to the dashboard.");
        localStorage.removeItem("referrer");
        history.replace(referrer);
      } else {
        console.log("> We are stuck in limbo...");
      }
    } else {
      console.log("> Authentication flow didn't finish yet. Waiting for authentication flow to update...");
      timer = setTimeout(function () {
        doRedirect();
      }, 500);
    }
  }

  var timer: any = null;
  useEffect(() => {
    doRedirect();

    return () => {
      clearTimeout(timer);
    };
    // seslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default WaitFor2FAAuthentication;
