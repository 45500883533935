import React, { PropsWithChildren, useContext, useEffect, useState } from "react";

import { Button, CircularProgress, Divider, InputAdornment, makeStyles, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { Link as RouterLink, Redirect, RouteComponentProps } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

import { auth, signInWithGoogle, signOut, maskPhone, updateUser, complete2FAMobileEnrollment, verify2FAMobile } from "Firebase/AuthService";
import { default as GoogleIcon } from "Icons/Google";

import logo from "full-logo.svg";
import logoDev from "full-logo-dev.svg";
import { UserContext } from "Authentication/UserProvider";
import ContentWrapper from "Components/Common/ContentWrapper";
import SubPageBar from "Components/Navigation/SubPageBar";
import { SettingsPhone } from "@material-ui/icons";

import isInt from "validator/lib/isInt";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100vh",
  },
  paper: { maxWidth: 420, flex: 1, margin: "auto" },
  formInput: {
    width: "80%",
  },
  form: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  logoContainer: {
    flexGrow: 1,
    lineHeight: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },
  logo: {
    height: theme.spacing(5),
  },
  forgotPasswordLinkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "0",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  buttonWithSpinner: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props extends PropsWithChildren<any> {}
type LocationState = {
  phone?: string;
  maskedPhone: string;
  referrer: string;
};
export default function Verify2FA(props: RouteComponentProps<Props, {}, LocationState>) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();

  const [code, setCode] = useState("");
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const [accountCreated, setAccountCreated] = React.useState(false);

  const verifyPhoneCode = (code: string) => {
    setError(null);
    if (code.length === 6 && isInt(code)) {
      setVerifyingCode(true);

      const verifyCode = props.location.state.referrer === "/signup" ? complete2FAMobileEnrollment : verify2FAMobile;

      verifyCode(code, user).then((success) => {
        if (success) {
          if (props.location.state.referrer === "/signup") {
            user.phone = props.location.state.phone ? "+1" + props.location.state.phone : null;
            user.save().then(() => {
              setTimeout(function () {
                history.replace(user.getNextOnboardingPageLink());
              }, 100);
            });
          } else {
            setTimeout(function () {
              history.replace("/login/2fa/authenticate");
            }, 100);
          }
        } else {
          setVerifyingCode(false);
          setError(<Alert severity="error">Please check the SMS code and try again.</Alert>);
        }
      });
    } else {
      setVerifyingCode(false);
      setError(<Alert severity="error">Please enter the SMS code you received.</Alert>);
    }
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    verifyPhoneCode(code);
  };

  const allowEmailPasswordLogin = true;

  return (
    <>
      {completed && props.location.state.referrer === "/signup" && <Redirect to={{ pathname: user.getNextOnboardingPageLink() }} />}
      {completed && props.location.state.referrer === "/login" && <Redirect to={{ pathname: "/login/2fa/authenticate" }} />}
      <SubPageBar
        transparent={false}
        isFullScreen={true}
        title="SMS Code Verification"
        onBackClick={() => {
          history.push("/signup");
        }}
      ></SubPageBar>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <form className={classes.form} noValidate autoComplete="off" onSubmit={onFormSubmit}>
            <Typography align="center" variant="body1">
              Please enter the code that was sent to <br /> <b>{props.location.state.maskedPhone}</b>
            </Typography>
            <>
              {error}
              <div>
                <TextField fullWidth type="text" name="phoneCode" label="SMS Code" variant="outlined" onChange={(e: any) => setCode(e.currentTarget.value)} />
              </div>
              <div className={classes.buttonWithSpinner}>
                <Button
                  type="submit"
                  fullWidth={true}
                  disabled={verifyingCode || !(code.length === 6 && isInt(code))}
                  variant="contained"
                  size="large"
                  color="secondary"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => {
                    verifyPhoneCode(code);
                  }}
                >
                  Verify
                </Button>
                {verifyingCode && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
              </div>
            </>
          </form>
        </Paper>
      </div>
    </>
  );
}
