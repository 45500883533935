import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { Button, Divider, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField } from "@material-ui/core";
import { UserContext } from "Authentication/UserProvider";
import { useHistory } from "react-router-dom";
import { setDocumentTitle } from "Components/Common/PageHelper";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { updateAvailableCapital, uploadDoc, uploadDocumentOld } from "Firebase/InvestorService";
import User from "Authentication/User";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ProfileSetupWrapper from "Pages/Onboarding/ProfileSetupWrapper";
import ProfileSetupQuestion from "Pages/Onboarding/ProfileSetupQuestion";
import SinglePage from "Components/Common/SinglePage";
import SinglePageQuestion from "Components/Common/SinglePageSection";
import { InvestorType, InvestorTypeHelper } from "Shared/Investor/InvestorType";
import { templateSettings } from "lodash";
import CurrencyField from "Components/Common/TextField/CurrenyField";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  textFieldSpacer: {
    marginTop: theme.spacing(2),
  },
  fileInput: {
    display: "none",
  },
}));

const formatCurrency = (x: any) => {
  return x.toLocaleString();
};
export default function UpdateCapital() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  console.log(user);
  const [validationError, setValidationError] = React.useState(false);
  const [firstName, setFirstName] = React.useState<string | null>(user.firstName);
  const [lastName, setLastName] = React.useState<string | null>(user.lastName);
  const [address, setAddress] = React.useState<any>(user.address ?? User.NullUser().address);
  const [dob, setDob] = React.useState<any>(user.dob || format(new Date(), "yyyy-MM-dd"));
  const [value, setValue] = React.useState(user.role ? user.role : "broker");
  const [uploaded, setUploaded] = React.useState<{ ID1: boolean; ID2: boolean }>({ ID1: !!user.docs[2].uri, ID2: !!user.docs[3].uri });

  const [capitalUpdates, setCapitalUpdates] = React.useState<any>(null);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setDocumentTitle("Update Capital");

    var cu: any = [];
    for (const [key, value] of Object.entries(user.investorsRaw)) {
      if (user.investorsRaw[key].memberEmails[0] === user.email) {
        var tmp: any = {};
        tmp.id = key;
        tmp.name = "Account";

        switch (user.investorsRaw[key].investorType) {
          case InvestorType.CORP:
            tmp.name = user.investorsRaw[key].legalName;
            break;
          case InvestorType.INDV:
            tmp.name = InvestorTypeHelper.toReadableString(user.investorsRaw[key].investorType) + user.investorsRaw[key].investorSubType !== "" ? " - " + user.investorsRaw[key].investorSubType : "";
            break;
          case InvestorType.JOINT:
            tmp.name = InvestorTypeHelper.toReadableString(user.investorsRaw[key].investorType) + user.investorsRaw[key].investorSubType !== "" ? " - " + user.investorsRaw[key].investorSubType : "";
            break;
          case InvestorType.TRUST:
            tmp.name = InvestorTypeHelper.toReadableString(user.investorsRaw[key].investorType) + user.investorsRaw[key].investorSubType !== "" ? " - " + user.investorsRaw[key].investorSubType : "";
            break;
        }

        tmp.type = user.investorsRaw[key].investorType === InvestorType.TRUST ? user.investorsRaw[key].legalName : InvestorTypeHelper.toReadableString(user.investorsRaw[key].investorType);

        tmp.members = user.investorsRaw[key].members
          .map((m: any) => m.name)
          .filter((x: any) => x !== "")
          .join(", ");

        tmp.availableCapital = {
          now: "",
          mo6: "",
          modified: Date.now(),
        };
        tmp.prevAvailableCapital = {
          now: user.investorsRaw[key].availableCapital[0].now,
          mo6: user.investorsRaw[key].availableCapital[0].mo6,
        };

        cu.push(tmp);
      }
    }

    setCapitalUpdates(cu);
  }, []);

  var form: React.RefObject<HTMLFormElement> = React.createRef();
  console.log(capitalUpdates);
  return (
    <SinglePage
      isOnboardingProfile={true}
      appBarTitle="Capital Update"
      title={
        <>
          <Typography variant="body1">Please provide updates to your available capital below.</Typography>
        </>
      }
      nextText="Save"
      onNextClick={async () => {
        for (var i = 0; i < capitalUpdates.length; i++) {
          var r = await updateAvailableCapital(capitalUpdates[i].id, capitalUpdates[i].availableCapital);
          console.log(capitalUpdates[i].id, r);
        }

        return true;
      }}
      nextUrl="/authenticate"
      validateCallback={() => {
        var error = false;

        error = error || !(form.current?.checkValidity() ?? false);
        error = error || !uploaded.ID1 || !uploaded.ID2;

        if (error) setValidationError(true);

        return !error;
      }}
      locallyStoreUserDataDelegate={() => {
        user.firstName = firstName;
        user.lastName = lastName;
        user.address = address;
        user.dob = dob;
      }}
    >
      <form autoComplete="off" ref={form}>
        <br />
        <Alert severity="info">Only include funds available to deploy at the time(s) requested.</Alert>
        {capitalUpdates &&
          capitalUpdates.map((m: any) => (
            <SinglePageQuestion
              key={m.id}
              labelIsComponent
              label={
                <>
                  <Typography component="div" variant="h5">
                    {m.name}
                  </Typography>
                  <Typography component="div" variant="subtitle1">
                    {m.type}
                  </Typography>
                  <Typography component="div" variant="body2">
                    Members: {m.members}
                  </Typography>
                </>
              }
              error={validationError && (!firstName || !lastName)}
              errorMsg="Please enter both your capital available now and in 6 months."
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <CurrencyField
                    required
                    debounceDependancy={m}
                    defaultValue={null}
                    label={"Now"}
                    onChange={(v) => {
                      var tmp = [...capitalUpdates];
                      var i = capitalUpdates.findIndex((x: any) => x.id === m.id);
                      console.log(i);
                      tmp[i].availableCapital.now = v;
                      setCapitalUpdates(tmp);
                    }}
                  />
                  <Typography variant="caption">Previously Provided: {"$" + formatCurrency(m.prevAvailableCapital.now)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CurrencyField
                    required
                    debounceDependancy={m}
                    defaultValue={null}
                    label={"6 months from now"}
                    onChange={(v) => {
                      var tmp = [...capitalUpdates];
                      var i = capitalUpdates.findIndex((x: any) => x.id === m.id);
                      tmp[i].availableCapital.mo6 = v;
                      setCapitalUpdates(tmp);
                    }}
                  />
                  <Typography variant="caption">Previously Provided: {"$" + formatCurrency(m.prevAvailableCapital.mo6)}</Typography>
                </Grid>
              </Grid>
            </SinglePageQuestion>
          ))}
      </form>
    </SinglePage>
  );
}
function DocumentAccessType(arg0: string, uid: string | null, arg2: string, arg3: File, DocumentAccessType: any) {
  throw new Error("Function not implemented.");
}
