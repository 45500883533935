import { updateUser } from "Firebase/AuthService";
import { updateInvestor } from "Firebase/InvestorService";
import { StringifyOptions } from "querystring";
import User from "Authentication/User";
import { DockSharp } from "@material-ui/icons";
import merge from "lodash/merge";
import { DocumentInfo } from "Authentication/User";
import { InvestorType, InvestorTypeHelper } from "Shared/Investor/InvestorType";
import { format } from "date-fns";

interface mailingList {
  explicitConfirmation?: string;
  signUpDate?: string;
}

interface InternalUseData {
  notes?: string | null;
}

export interface AvailableCapitalType {
  modified: number;
  now: number | null;
  mo6: number | null;
}

export enum InvestorStatus {
  Onboarding = "Onboarding", // not started
  PendingApproval = "Pending Approval",
  Approved = "Approved",
  Removed = "Removed",
}
export enum InvestorMemberStatus {
  None = "", // not started
  EmailSent = "Email sent",
  Onboarding = "Onboarding",
  WaitingOnOtherMembers = "Waiting on other member(s)",
  WaitingForApproval = "Waiting on Approval",
  Done = "Done",
}

export default class Investor {
  static VERSION: number = 1;
  static REQUIRED_DOCS: DocumentInfo[] = [
    { friendlyName: "Void Cheque", internalName: "Void Cheque", uri: null, modified: null, required: true },
    { friendlyName: "Identification 1", internalName: "Identification 1", uri: null, modified: null, required: true },
    { friendlyName: "Identification 2", internalName: "Identification 2", uri: null, modified: null, required: true },
    { friendlyName: "Admin Agreement", internalName: "Admin Agreement", uri: null, modified: null, required: true },
    { friendlyName: "KYC", internalName: "KYC", uri: null, modified: null, required: true },
    { friendlyName: "Survey", internalName: "Survey", uri: null, modified: null, required: true },
  ];

  id: string | null = null;

  investorType: InvestorType | null = null;
  investorSubType: string | null = null;
  members: { name: string; email: string; status: InvestorMemberStatus; completedSteps: number }[] = [];
  memberEmails: string[] = [];
  created: number | null = null;
  emailLastSent: string = "Never";
  status: InvestorStatus = InvestorStatus.Onboarding;

  legalIdentificationNumber: string | null = null;
  legalName: string | null = null;

  accreditedInvestor: boolean | null = null;
  logoUrl: string | null = null;
  email: string | null = null;
  phone: string | null = null;

  address: {
    address1: string | null;
    province: string | null;
    country: string | null;
    postalcode: string | null;
  } = {
    address1: null,
    province: "Ontario",
    country: "Canada",
    postalcode: null,
  };
  website: null | string | boolean = null;
  permalink: string | null = null;

  fullyManaged: boolean | null = null;
  directDeposit: {
    instituion: string | null;
    transit: string | null;
    account: string | null;
    addedToAutomator: boolean | null;
  } = {
    instituion: null,
    transit: null,
    account: null,
    addedToAutomator: false,
  };

  availableCapital: AvailableCapitalType[] = [];
  lastDeal: number | null = new Date().setFullYear(new Date().getFullYear() - 1);

  mortgageCriteria: {
    incomeDocImportance: null | string;
    maxGDS: null | number;
    maxTDS: null | number;
    minCreditScore: null | number;
    fullyOpen: null | boolean;

    amount: {
      min: null | number;
      max: null | number;
    };

    length: {
      mo6: boolean;
      yr1: boolean;
      yr2: boolean;
      moreyr2: boolean;
    };

    amortization: {
      interestOnly: boolean;
      yr20: boolean;
      yr25: boolean;
      yr30: boolean;
      yr35: boolean;
      yr40: boolean;
    };

    rate: {
      min: null | number;
      max: null | number;
    };
    fee: {
      min: null | number;
      max: null | number;
      isPercentage: boolean;
    };
    propertyLTV: any | null;
    locations: any | null;
    maxLTV: any | null;
  } = {
    incomeDocImportance: null,
    maxGDS: null,
    maxTDS: null,
    minCreditScore: null,
    fullyOpen: null,

    amount: {
      min: null,
      max: null,
    },

    length: {
      mo6: false,
      yr1: false,
      yr2: false,
      moreyr2: false,
    },

    amortization: {
      interestOnly: false,
      yr20: false,
      yr25: false,
      yr30: false,
      yr35: false,
      yr40: false,
    },

    maxLTV: null,
    rate: {
      min: null,
      max: null,
    },
    fee: {
      min: null,
      max: null,
      isPercentage: true,
    },
    propertyLTV: {},
    locations: null,
  };

  docs: DocumentInfo[] = [];
  lawyer: string | null = null;
  compensationSplit: {
    type: string | null;
    amount: number | null;
  } = {
    type: "",
    amount: 1,
  };

  referrer: {
    type: string | null;
    amount: number | null;
  } = {
    type: "",
    amount: 0,
  };

  internalUse: InternalUseData = { notes: "" };

  constructor(id: string) {
    this.id = id;

    this.docs = [];
    Investor.REQUIRED_DOCS.forEach((doc) => {
      this.docs?.push({ ...doc });
    });
  }

  static fromDataDocument(id: any, doc: any) {
    console.log(doc);
    return merge(new Investor(id), doc);
  }

  concatMemberNames() {
    var tmp = "";
    for (var i = 0; i < this.memberEmails.length; i++) {
      tmp += this.members[i].name + (i < this.memberEmails.length - 1 ? ", " : "");
    }
    return tmp;
  }

  getDisplayName(): string[] {
    switch (this.investorType) {
      case InvestorType.INDV:
        return [this.members[0].name, ""];
      case InvestorType.JOINT:
        return [this.concatMemberNames(), ""];
      case InvestorType.CORP:
        return [this.legalName ?? "", this.members[0].name];
      case InvestorType.TRUST:
        return [this.concatMemberNames(), this.legalName ?? ""];
      default:
        return ["", ""];
    }
  }

  getDataDocument() {
    return {
      investorType: this.investorType,
      investorSubType: this.investorSubType,
      members: this.members,
      memberEmails: this.memberEmails,
      created: this.created,
      emailLastSent: this.emailLastSent,
      status: this.status,

      legalIdentificationNumber: this.legalIdentificationNumber,

      legalName: this.legalName,
      accreditedInvestor: this.accreditedInvestor,
      logoUrl: this.logoUrl,
      email: this.email,
      phone: this.phone,
      address: this.address,
      permalink: this.permalink,
      website: this.website,

      directDeposit: this.directDeposit,

      availableCapital: this.availableCapital,
      lastDeal: this.lastDeal,
      mortgageCriteria: this.mortgageCriteria,
      fullyManaged: this.fullyManaged,
      internalUse: this.internalUse,
      docs: this.docs,
      lawyer: this.lawyer,
      compensationSplit: this.compensationSplit,
      referrer: this.referrer,
    };
  }

  async save() {
    return await updateInvestor(this);
  }
}
