import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { Button, Divider, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from "@material-ui/core";
import { UserContext } from "Authentication/UserProvider";
import { useHistory } from "react-router-dom";
import { setDocumentTitle } from "Components/Common/PageHelper";
import ProfileSetupWrapper from "../ProfileSetupWrapper";
import ProfileSetupQuestion from "../ProfileSetupQuestion";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { uploadDoc, uploadDocumentOld } from "Firebase/InvestorService";
import User from "Authentication/User";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
const useStyles = makeStyles((theme) => ({
  textFieldSpacer: {
    marginTop: theme.spacing(2),
  },
  fileInput: {
    display: "none",
  },
}));

export default function OnboardingUserBasicInformation() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [validationError, setValidationError] = React.useState(false);
  const [firstName, setFirstName] = React.useState<string | null>(user.firstName);
  const [lastName, setLastName] = React.useState<string | null>(user.lastName);
  const [address, setAddress] = React.useState<any>(user.address ?? User.NullUser().address);
  const [dob, setDob] = React.useState<any>(user.dob || format(new Date(), "yyyy-MM-dd"));
  const [value, setValue] = React.useState(user.role ? user.role : "broker");
  const [uploaded, setUploaded] = React.useState<{ ID1: boolean; ID2: boolean }>({ ID1: !!user.docs[2].uri, ID2: !!user.docs[3].uri });

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setDocumentTitle("Account Details | Onboarding");
  }, []);

  var form: React.RefObject<HTMLFormElement> = React.createRef();
  return (
    <ProfileSetupWrapper
      isOnboardingProfile={true}
      title="Account Details"
      validateCallback={() => {
        var error = false;

        error = error || !(form.current?.checkValidity() ?? false);
        error = error || !uploaded.ID1 || !uploaded.ID2;

        if (error) setValidationError(true);

        return !error;
      }}
      locallyStoreUserDataDelegate={() => {
        user.firstName = firstName;
        user.lastName = lastName;
        user.address = address;
        user.dob = dob;
      }}
    >
      <form autoComplete="off" ref={form}>
        <ProfileSetupQuestion label="Please enter your name.">
          <ProfileSetupQuestion error={validationError && (!firstName || !lastName)} errorMsg="Please enter both your first and last name.">
            <TextField
              fullWidth
              required
              variant="outlined"
              label={"Full Legal First Name"}
              value={firstName || ""}
              onChange={(e) => {
                setFirstName(e.target.value as string);
              }}
            />

            <TextField
              className={classes.textFieldSpacer}
              fullWidth
              required
              variant="outlined"
              label={"Full Legal Last Name"}
              value={lastName || ""}
              onChange={(e) => {
                setLastName(e.target.value as string);
              }}
            />
          </ProfileSetupQuestion>
        </ProfileSetupQuestion>

        <ProfileSetupQuestion label="Please enter your personal mailing address.">
          <ProfileSetupQuestion error={validationError && (!address.address1 || !address.city || !address.postalcode)} errorMsg="Please enter your full address below.">
            <TextField
              fullWidth
              required
              variant="outlined"
              label={"Street Address"}
              value={address.address1 || ""}
              onChange={(e) => {
                setAddress({ ...address, address1: e.target.value as string });
              }}
            />

            <TextField
              className={classes.textFieldSpacer}
              fullWidth
              required
              variant="outlined"
              label={"City"}
              value={address.city || ""}
              onChange={(e) => {
                setAddress({ ...address, city: e.target.value as string });
              }}
            />
            <TextField
              className={classes.textFieldSpacer}
              fullWidth
              required
              variant="outlined"
              label={"Postal Code"}
              value={address.postalcode || ""}
              onChange={(e) => {
                setAddress({ ...address, postalcode: e.target.value as string });
              }}
            />
          </ProfileSetupQuestion>
        </ProfileSetupQuestion>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ProfileSetupQuestion label="Please enter date of birth.">
            <ProfileSetupQuestion error={validationError && (!dob || dob === format(new Date(), "yyyy-MM-dd"))} errorMsg="Please change this date to your date of birth.">
              <DatePicker
                disableFuture
                openTo="year"
                format="dd/MM/yyyy"
                label="Date of birth"
                views={["year", "month", "date"]}
                value={dob}
                onChange={(e) => {
                  var formatedDate = format(e ? new Date(e.getFullYear(), e.getMonth(), e.getDate()) : new Date(), "yyyy-MM-dd");
                  setDob(formatedDate);
                }}
              />
            </ProfileSetupQuestion>
          </ProfileSetupQuestion>
        </MuiPickersUtilsProvider>

        <ProfileSetupQuestion label="Please upload two pieces of identification.">
          <ProfileSetupQuestion error={validationError && (!uploaded.ID1 || !uploaded.ID2)} errorMsg="Both pieces of identification are required.">
            <input
              className={classes.fileInput}
              id={"icon-button-file-ID1"}
              type="file"
              onChange={(e) => {
                if (e.target.files && user.uid) {
                  uploadDoc("user", user.uid, "Identification 1", e.target.files[0], "any-authenticated").then((result) => {
                    if (result) {
                      user.docs[2].uri = result;
                      user.docs[2].modified = Date.now();
                      setUploaded({ ...uploaded, ID1: true });
                    }
                  });
                }
              }}
            />
            <label htmlFor={"icon-button-file-ID1"}>
              <Button color={uploaded.ID1 ? "primary" : "secondary"} variant="outlined" aria-label="upload picture" component="span" startIcon={uploaded.ID1 ? <CheckCircleIcon /> : <CloudUploadIcon />}>
                Identification 1
              </Button>
            </label>
            <br />
            <br />
            <input
              className={classes.fileInput}
              id={"icon-button-file-ID2"}
              type="file"
              onChange={(e) => {
                if (e.target.files && user.uid) {
                  uploadDoc("user", user.uid, "Identification 2", e.target.files[0], "any-authenticated").then((result) => {
                    if (result) {
                      user.docs[3].uri = result;
                      user.docs[3].modified = Date.now();
                      setUploaded({ ...uploaded, ID2: true });
                    }
                  });
                }
              }}
            />
            <label htmlFor={"icon-button-file-ID2"}>
              <Button color={uploaded.ID2 ? "primary" : "secondary"} variant="outlined" aria-label="upload picture" component="span" startIcon={uploaded.ID2 ? <CheckCircleIcon /> : <CloudUploadIcon />}>
                Identification 2
              </Button>
            </label>
          </ProfileSetupQuestion>
        </ProfileSetupQuestion>
      </form>
    </ProfileSetupWrapper>
  );
}
function DocumentAccessType(arg0: string, uid: string | null, arg2: string, arg3: File, DocumentAccessType: any) {
  throw new Error("Function not implemented.");
}
