const lenderLocationData = [
  {
    CA: {
      ON: [{}],
    },
  },
];
const locationData = [
  {
    id: "CA",
    order: 1,
    name: "Canada",
    code: "CA",
    tag: "CA",
    mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
    provinces: [
      {
        id: "CA-ON",
        order: 1,
        name: "Ontario",
        code: "ON",
        tag: "CA - ON",
        mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
        locations: [
          {
            id: "CA-ON-GTA",
            order: 1,
            shortname: "GTA",
            name: "Greater Toronto Area",
            tag: "ON - GTA",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-LondonArea",
            order: 2,
            shortname: "London Area",
            name: "London Area",
            tag: "ON - London",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-NiagaraArea",
            order: 3,
            shortname: "Niagara Area",
            name: "Niagara Area",
            tag: "ON - Niagara",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-SarniaArea",
            order: 4,
            shortname: "Sarnia Area",
            name: "Sarnia Area",
            tag: "ON - Sarnia",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-HamiltonArea",
            order: 5,
            shortname: "Hamilton Area",
            name: "Hamilton Area",
            tag: "ON - Hamilton",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-BarrieArea",
            order: 6,
            shortname: "Barrie Area",
            name: "Barrie Area",
            tag: "ON - Barrie",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-OwneSound",
            order: 7,
            shortname: "Owne Sound",
            name: "Owne Sound",
            tag: "ON - Owne Sound",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-Peterborough",
            order: 8,
            shortname: "Peterborough",
            name: "Peterborough",
            tag: "ON - Peterborough",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-Kingston",
            order: 9,
            shortname: "Kingston",
            name: "Kingston",
            tag: "ON - Kingston",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-Ottawa",
            order: 10,
            shortname: "Ottawa",
            name: "Ottawa",
            tag: "ON - Ottawa",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
          {
            id: "CA-ON-NorthOntario",
            order: 11,
            shortname: "North Ontario",
            name: "North Ontario",
            tag: "ON - North Ontario",
            mapUrl: "https://www.google.com/maps/place/Blossom+Capital+Inc./@43.8509106,-79.3870201,17z/data=!4m5!3m4!1s0x882b2bacd2ebfded:0x868c38f53be01419!8m2!3d43.8509068!4d-79.3848314",
          },
        ],
      },
    ],
  },
];

export default locationData;
