import { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import { ClickAwayListener, Dialog, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { isNaN } from "lodash";

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(4),
    border: "1px solid " + theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    marginTop: "-6px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  title: {
    flex: "1 1 100%",
    whiteSpace: "nowrap",
  },
  LTVInput: {
    maxWidth: "50px",
    "& .MuiInputBase-input": {
      fontSize: "0.875rem",
      textAlign: "center",
      paddingLeft: "11px",
      paddingRight: "10px",
    },
    "& input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },

  modal: {
    padding: theme.spacing(2),
  },
  iframe: {
    width: "100%",
    height: "80vw",
    border: "none",
  },
  mapToolbar: {
    position: "absolute",
    width: "100%",
    height: "50px",
    background: "white",
    display: "flex",
    justifyContent: "flex-end",
  },
  contentColumnHeight: {
    height: "51px",
  },
  t1LocationContainer: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  highlight: {
    borderColor: theme.palette.primary.main,

    backgroundColor: "#eff7ff",
  },
  highlightText: {
    fontWeight: 500,
  },
  ltvError: {
    borderColor: "#f44336",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336",
    },
  },
}));

const getMaxLTVForT1 = (results: any, t2s: any) => {
  var maxLTVForT1 = -1;

  t2s.forEach((t2: any) => {
    if (results[t2.id] != null) maxLTVForT1 = Math.max(maxLTVForT1, results[t2.id] === "" ? -1 : results[t2.id]);
  });

  return maxLTVForT1 === -1 ? "" : maxLTVForT1;
};

export interface LocationInputProps {
  placeId: string;
  name: string;
  tag: string;
  mapUrl: string;

  initialLtv: null | number;

  ltvBounds: { min: number; max: number };

  addLtv: (placeId: any, tag: any, ltv: any) => void;
  removeLtv: (placeId: any, tag: any) => void;
}

export default function LocationInput(props: LocationInputProps) {
  const classes = useStyles();
  var { placeId, tag, name, mapUrl, initialLtv, ltvBounds, addLtv, removeLtv } = props;

  const [active, setActive] = useState(initialLtv == null ? false : true);
  const [ltv, setLtv] = useState<null | number | string>(props.initialLtv ?? "");
  const [openMap, setOpenMap] = useState(false);

  const handleActiveChanged = (event: any) => {
    var isActive: boolean = event.target.checked;

    setActive(isActive);

    setLtv("");

    if (isActive) {
      addLtv(placeId, tag, null);
    } else {
      removeLtv(placeId, tag);
    }
  };

  const handleLTVChanged = (value: any, id: any, isOverride = false) => {
    var v: string | number | null = "";
    if (value === "" || isNaN(value)) {
      setLtv("");
      removeLtv(placeId, tag);
    } else {
      v = parseInt(value);

      if (v >= ltvBounds.min) {
        if (v > ltvBounds.max) v = ltvBounds.max;
        addLtv(placeId, tag, v);
      } else {
        addLtv(placeId, tag, null);
      }
      setLtv(v);
    }
  };

  /*
      const [mapUrl, setMapUrl] = useState<boolean | string>(false);
  const handleOpenMap = (url: any) => {
    if (url.indexOf("http") >= 0) {
      setMapUrl(url);
      setOpenMap(true);
    } else if (url.length > 10) {
      var tmpId = url.substring(4, 9);
      setMapUrl("https://geoprod.statcan.gc.ca/datatomap/index.html?language=en&client=census&action=wf_identify&value={%22Layers%22:[{%22id%22:%22" + tmpId + "%22,%22values%22:[%22" + url + "%22]}]}");
      setOpenMap(true);
    }
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };
*/

  return (
    <div className={clsx(classes.root, active && classes.highlight)}>
      <div className={classes.t1LocationContainer}>
        <Typography className={clsx(classes.title, active && classes.highlightText)} variant="subtitle1" id="tableTitle" component="div">
          <Switch checked={active} onChange={handleActiveChanged} color="primary" name="checkedB" inputProps={{ "aria-label": "primary checkbox" }} />
          {active && <TextField value={ltv} InputLabelProps={{ shrink: true }} className={clsx(classes.LTVInput, (ltv === "" || ltv == null || ltv < ltvBounds.min) && classes.ltvError)} size="small" label={"LTV"} inputProps={{ inputMode: "numeric", type: "number" }} variant="outlined" onChange={(e) => handleLTVChanged(e.target.value, placeId)} />}
          {name}
        </Typography>
      </div>
      {/*
        <Dialog fullWidth maxWidth="lg" open={openMap} onClose={handleCloseMap} className={classes.modal}>
          <div className={classes.mapToolbar}>
            <IconButton onClick={handleCloseMap}>
              <CloseIcon />
            </IconButton>
          </div>
          <iframe title={"map"} className={classes.iframe} src={mapUrl as string} />
        </Dialog>
        */}
    </div>
  );
}
