import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { UserContext } from "Authentication/UserProvider";
import { useHistory } from "react-router-dom";
import { setDocumentTitle } from "Components/Common/PageHelper";
import ProfileSetupWrapper from "../ProfileSetupWrapper";
import ProfileSetupQuestion from "../ProfileSetupQuestion";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  textFieldSpacer: {
    marginTop: theme.spacing(2),
  },
}));

export default function OnboardingUserKYC() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [validationError, setValidationError] = React.useState(false);

  const [value, setValue] = React.useState(user.role ? user.role : "broker");

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setDocumentTitle("Know Your Investor | Onboarding");
  }, []);

  var form: React.RefObject<HTMLFormElement> = React.createRef();
  return (
    <ProfileSetupWrapper
      title="Know Your Investor"
      validateCallback={() => {
        return true;
      }}
      locallyStoreUserDataDelegate={() => {}}
    >
      <form autoComplete="off" ref={form}>
        <ProfileSetupQuestion label="Placeholder for Docusign KYC integration."></ProfileSetupQuestion>
      </form>
    </ProfileSetupWrapper>
  );
}
