import React, { useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Switch, InputAdornment, FormHelperText, Button } from "@material-ui/core";

import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { Link as RouterLink, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";

import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isURL from "validator/lib/isURL";
import { setDocumentTitle } from "Components/Common/PageHelper";
import { UserContext } from "Authentication/UserProvider";
import ProfileSetupWrapper from "../../ProfileSetupWrapper";
import ProfileSetupQuestion, { ProfileSetupQuestionLabel } from "../../ProfileSetupQuestion";
import TrueFalseToggle from "Components/Common/TrueFalseToggle";
import TextFieldWithDisableCheckbox from "Components/Common/TextFieldWithDisableCheckbox";

import { uploadDoc, uploadDocumentOld } from "Firebase/InvestorService";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
const useStyles = makeStyles((theme) => ({
  subDesc: {
    color: "#9e9e9e",
    "& a": {
      color: "#687994",
    },
  },
  marginTop: { marginTop: theme.spacing(3) },
  moreMarginTop: { marginTop: theme.spacing(6) },
  fileInput: {
    display: "none",
  },
}));
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function OnboardingInvestorIndividualInformation() {
  useEffect(() => {
    setDocumentTitle("Individual Account Information | Investor Account Setup");
  }, []);

  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [firstName, setLegalName] = React.useState(user.firstName ?? null);
  const [lastName, setLastName] = React.useState(user.lastName ?? null);
  const [legalIdentificationNumber, setLegalIdentificationNumber] = React.useState(user.activeInvestor?.legalIdentificationNumber ?? null);
  const [accreditedInvestor, setAccreditedInvestor] = React.useState(user.activeInvestor?.accreditedInvestor ?? null);

  //const [operatingName, setOperatingName] = React.useState(user.activeInvestor?.operatingName ?? null);
  //const [classification, setClassification] = React.useState(user.activeInvestor?.classification ? user.activeInvestor?.classification ?? "INDV" : "INDV");
  //const [logoUrl, setLogoUrl] = React.useState(user.activeInvestor?.logoUrl ? user.activeInvestor?.logoUrl : "");
  //const [permalink, setPermalink] = React.useState(user.activeInvestor?.permalink ?? null);

  const [validationError, setValidationError] = React.useState(false);

  var form: React.RefObject<HTMLFormElement> = React.createRef();

  return (
    <ProfileSetupWrapper
      title="Individual Information"
      role={"investor"}
      validateCallback={() => {
        var error = false;

        //error = error || !logoUrl;

        error = error || !(form.current?.checkValidity() ?? false);

        error = error || accreditedInvestor == null;

        if (error) setValidationError(true);

        return !error;
      }}
      locallyStoreUserDataDelegate={() => {
        var currentInvestor = user.activeInvestor;
        if (currentInvestor) {
          currentInvestor.legalIdentificationNumber = legalIdentificationNumber;

          currentInvestor.accreditedInvestor = accreditedInvestor;

          currentInvestor.members[user.getMyPendingInvestorOnboardingIndex()].name = user.firstName + " " + user.lastName;
          //currentInvestor.logoUrl = logoUrl;
          //user.avatar = logoUrl;
        }
      }}
    >
      <form autoComplete="off" ref={form}>
        <ProfileSetupQuestion>
          <TextField fullWidth variant="outlined" label={"Legal First Name"} value={firstName || ""} disabled />
          <TextField className={classes.marginTop} fullWidth required variant="outlined" label={"Legal Last Name"} value={lastName || ""} disabled />

          <TextField
            className={classes.marginTop}
            fullWidth
            required
            variant="outlined"
            label={"SIN"}
            value={legalIdentificationNumber || ""}
            onChange={(e) => {
              setLegalIdentificationNumber(e.target.value);
            }}
          />
        </ProfileSetupQuestion>

        <ProfileSetupQuestion error={validationError && accreditedInvestor == null} errorMsg={"Please select whether or not you are a designed/accredited investor."}>
          <ProfileSetupQuestionLabel>
            Are you a designed/accredited investor? *<br />
            <Typography className={classes.subDesc} variant="body2">
              See Section 2 of{" "}
              <Link color="secondary" target="_blank" underline="always" href="https://www.ontario.ca/laws/regulation/080188#BK2">
                Mortgage Brokerages, Lenders and Administrators Act
              </Link>
            </Typography>
          </ProfileSetupQuestionLabel>

          <TrueFalseToggle defaultValue={accreditedInvestor} onChange={(v) => setAccreditedInvestor(v)} trueLabel="Yes" falseLabel="No" />
        </ProfileSetupQuestion>
      </form>
    </ProfileSetupWrapper>
  );
}
