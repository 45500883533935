import React, { useEffect } from "react";
import clsx from "clsx";
import { Checkbox, FormControlLabel, InputBaseComponentProps, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inlineCheckbox: {
    border: "1px solid #c4c4c4",
    borderRight: 0,
    background: "#e0e0e0",
    marginRight: 0,
    paddingRight: "9px",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    color: "#777",
    width: "123px",
    marginLeft: 0,
    "& .MuiTypography-root": {
      fontSize: "0.8rem",
    },
  },
  inlineCheckboxSmall: {
    width: "130px",
    "& .MuiCheckbox-root": {
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    "& .MuiTypography-root": {
      fontSize: "0.7rem",
    },
  },
  hasInlineCheckbox: {
    "& .MuiInputBase-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  displayFlex: {
    display: "flex",
    width: "100%",
  },
  flex: {
    flex: 1,
  },
}));

export interface TextFieldWithDisableCheckboxProps {
  inputMode: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
  flex?: boolean;
  checkboxLabel: string;
  type?: any;
  label: string;

  size?: "medium" | "small" | undefined;
  defaultValue: any;
  className?: any;
  onChange: (v: any) => void;
}

export default function TextFieldWithDisableCheckbox(props: TextFieldWithDisableCheckboxProps) {
  const classes = useStyles();

  const [state, setState] = React.useState({ value: props.defaultValue, displayValue: "", checkbox: false });

  const handleChange = (newValue: any) => {
    var tmpState = { ...state };

    if (newValue === null) {
      tmpState.value = null;
      tmpState.displayValue = "";
      tmpState.checkbox = false;
    } else if (newValue === false) {
      tmpState.value = false;
      tmpState.displayValue = "";
      tmpState.checkbox = true;
    } else {
      tmpState.value = newValue;
      tmpState.displayValue = newValue;
      tmpState.checkbox = false;
    }

    props.onChange(tmpState.value);
    setState(tmpState);
  };

  useEffect(() => {
    handleChange(state.value);
  }, []);

  const inputProps: InputBaseComponentProps = { inputMode: props.inputMode };

  return (
    <div className={clsx(classes.displayFlex, props.flex && classes.flex, props.className)}>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkbox}
            color="secondary"
            onChange={(e) => {
              handleChange(e.target.checked ? false : "");
            }}
          />
        }
        label={props.checkboxLabel}
        className={clsx(classes.inlineCheckbox, props.size === "small" && classes.inlineCheckboxSmall)}
      />
      <TextField
        className={classes.hasInlineCheckbox}
        size={props.size ? props.size : undefined}
        value={state.displayValue}
        type={props.type}
        inputProps={inputProps}
        fullWidth
        variant="outlined"
        label={props.label}
        onChange={(e) => {
          handleChange(e.target.value === "" ? false : e.target.value);
        }}
      />
    </div>
  );
}
