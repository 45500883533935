import React, { useContext, useEffect, useState } from "react";

import { Button, Divider, makeStyles, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { Link as RouterLink, Redirect, useLocation } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

import { signInWithGoogle, createUserWithEmailAndPassword, ILoginResults, signOut } from "Firebase/AuthService";
import { default as GoogleIcon } from "Icons/Google";

import logo from "full-logo.svg";
import logoDev from "full-logo-dev.svg";
import { UserContext } from "Authentication/UserProvider";
import ContentWrapper from "Components/Common/ContentWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100vh",
  },
  paper: { maxWidth: 420, flex: 1, margin: "auto" },
  formInput: {
    width: "80%",
  },
  form: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  logoContainer: {
    flexGrow: 1,
    lineHeight: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },
  logo: {
    height: theme.spacing(5),
  },
  forgotPasswordLinkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "0",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

export default function SignUpAccount() {
  const classes = useStyles();
  const location = useLocation();
  //const user = useContext(UserContext);
  //const history = useHistory();

  useEffect(() => {
    signOut();
  }, []);

  const [email, setEmail] = useState(new URLSearchParams(location.search ?? "").get("email") ?? "");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any | null>(null);

  const [results, setResults] = React.useState<ILoginResults>({
    isSuccessful: false,
    requireNewEmailVerification: false,
    require2FAEnrollment: false,
    require2FALogin: false,
    masked2FADestination: "",
    unmasked2FADestination: "",
    error: false,
  });

  const createUserWithEmailAndPasswordHandler = async (event: any) => {
    event.preventDefault();
    createUserWithEmailAndPassword(email, password, (res) => {
      if (res.error) {
        if (res.error === "auth/email-already-in-use") {
          setError(
            <>
              <Alert severity="error">This email is already in use</Alert>
              <Alert severity="info">
                <Link component={RouterLink} to="/login">
                  Click here to Log In
                </Link>
              </Alert>
            </>
          );
        } else {
          setError(<Alert severity="error">Error Signing up with email and password</Alert>);
        }
      } else {
        setResults({ ...res });
      }
    });
  };

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  let logInWithGoogle = () => {
    signInWithGoogle((res) => {
      if (error) {
        setError(<Alert severity="error">Unable to login via Google.</Alert>);
      } else {
        setResults({ ...res });
      }
    });
  };

  const allowEmailPasswordLogin = true;

  const onFormSubmit = (e: any) => {
    createUserWithEmailAndPasswordHandler(e);
  };
  return (
    <>
      {results.isSuccessful && <Redirect push to={{ pathname: "/authenticate", state: { ...results } }} />}
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Alert severity="info">
            To get started creating your account <b>please create a password</b> - please select a strong password as security and privacy is important to us here at Blossom Capital.
          </Alert>
          <form className={classes.form} noValidate autoComplete="off" onSubmit={onFormSubmit}>
            <div className={classes.logoContainer}>
              <img src={process.env.REACT_APP_ENV === "prod" ? logo : logoDev} className={classes.logo} alt="Blossom MS" />
            </div>
            {allowEmailPasswordLogin && (
              <>
                {error}
                <div>
                  <TextField fullWidth autoComplete="email" type="email" name="userEmail" label="Email" variant="outlined" InputLabelProps={{ shrink: email.length > 0 ? true : undefined }} value={email} onChange={onChangeHandler} />
                </div>
                <div>
                  <TextField fullWidth autoComplete="current-password" type="password" name="userPassword" label="Create a password" variant="outlined" onChange={onChangeHandler} />
                </div>
                {/*} <div className={classes.forgotPasswordLinkContainer}>
                  <Link component={RouterLink} to="/login/forgot">
                    Forgot Password?
                  </Link>
                </div>
            */}
                <div>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    size="large"
                    color="primary"
                    endIcon={<ArrowForwardIcon />}
                    onClick={(event) => {
                      createUserWithEmailAndPasswordHandler(event);
                    }}
                  >
                    Sign Up
                  </Button>
                </div>

                <Divider variant="middle" />
              </>
            )}
            {/*
            <div>
              <Button
                fullWidth
                variant="contained"
                size="large"
                color="default"
                startIcon={<GoogleIcon />}
                onClick={() => {
                  logInWithGoogle();
                }}
              >
                Sign up with Google
              </Button>
            </div>
            <Divider variant="middle" />

              */}
            <Typography align="center" variant="body2">
              Already have an account?{" "}
              <Link replace component={RouterLink} color="secondary" to="/login">
                Log in
              </Link>{" "}
            </Typography>
          </form>
        </Paper>
      </div>
    </>
  );
}
