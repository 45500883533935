import React, { useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Checkbox, FormControlLabel, Grid, Radio, RadioGroup, TextField, Tooltip } from "@material-ui/core";
import ProfileSetupWrapper from "../ProfileSetupWrapper";
import { setDocumentTitle } from "Components/Common/PageHelper";
import { UserContext } from "Authentication/UserProvider";
import ProfileSetupQuestion from "../ProfileSetupQuestion";
import MinMaxFieldWithOverrideCheckBox from "Components/Common/TextField/MinMaxFieldWithOverrideCheckBox";
import { AvailableCapitalType } from "Models/Investor";
import CurrencyField from "Components/Common/TextField/CurrenyField";

const useStyles = makeStyles((theme) => ({
  subDesc: {
    color: "#9e9e9e",
    "& a": {
      color: "#687994",
    },
  },
  radioLabel: {
    textAlign: "center",
    margin: "0",
    "& $label": {
      fontSize: "0.8rem",
    },
  },
  inlineCheckbox: {
    border: "1px solid #c4c4c4",
    borderRight: 0,
    background: "#e0e0e0",
    marginRight: 0,
    paddingRight: "9px",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    color: "#777",
    width: "123px",
  },
  hasInlineCheckbox: {
    "& .MuiInputBase-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  flex: {
    display: "flex",
  },
  textFieldSpacer: {
    marginTop: theme.spacing(2),
  },
}));

const FormControlLabelLinear = withStyles((theme) => ({
  root: {
    textAlign: "center",
    margin: "0",
  },
  label: {
    fontSize: "0.8rem",
  },
}))(FormControlLabel);

export default function OnboardingInvestorUnderwriting() {
  useEffect(() => {
    setDocumentTitle("Underwritting | Profile Setup");
  }, []);
  const classes = useStyles();
  const user = useContext(UserContext);

  const [incomeDocImportance, setIncomeDocImportance] = React.useState(user.activeInvestor?.mortgageCriteria.incomeDocImportance ?? null);

  const [maxGDS, setMaxGDS] = React.useState(user.activeInvestor?.mortgageCriteria.maxGDS ?? null);
  const [maxTDS, setMaxTDS] = React.useState(user.activeInvestor?.mortgageCriteria.maxTDS ?? null);
  const [minCreditScore, setMinCreditScore] = React.useState(user.activeInvestor?.mortgageCriteria.minCreditScore ?? null);
  const [availableCapital, setAvailableCapital] = React.useState<AvailableCapitalType>({ modified: Date.now(), now: (user.activeInvestor?.availableCapital.length ?? -1) > 0 ? user.activeInvestor?.availableCapital[0].now ?? null : null, mo6: (user.activeInvestor?.availableCapital.length ?? -1) > 0 ? user.activeInvestor?.availableCapital[0].mo6 ?? null : null });

  const [validationError, setValidationError] = React.useState(false);

  var form: React.RefObject<HTMLFormElement> = React.createRef();
  return (
    <ProfileSetupWrapper
      title="Underwriting"
      validateCallback={() => {
        var error = false;

        error = error || !incomeDocImportance;

        error = error || maxGDS == null;
        error = error || maxTDS == null;
        error = error || minCreditScore == null;
        error = error || availableCapital.now == null || availableCapital.mo6 == null;

        error = (error || !form.current?.checkValidity()) ?? false;

        if (error) setValidationError(error);

        return !error;
      }}
      locallyStoreUserDataDelegate={() => {
        var currentInvestor = user.activeInvestor;
        if (currentInvestor) {
          currentInvestor.mortgageCriteria.incomeDocImportance = incomeDocImportance;
          currentInvestor.mortgageCriteria.maxGDS = maxGDS;
          currentInvestor.mortgageCriteria.maxTDS = maxTDS;
          currentInvestor.mortgageCriteria.minCreditScore = minCreditScore;
          currentInvestor.availableCapital = [availableCapital];
        }
      }}
    >
      <form autoComplete="off" ref={form}>
        <ProfileSetupQuestion label="Importance of income documents are as a qualification criteria. *" error={validationError && !incomeDocImportance} errorMsg="Please select a level of importance.">
          <RadioGroup
            row
            aria-label="income"
            name="income"
            defaultValue={incomeDocImportance ?? ""}
            value={incomeDocImportance}
            onChange={(e) => {
              setIncomeDocImportance(e.target.value as string);
            }}
          >
            <FormControlLabelLinear className={classes.radioLabel} value="1" control={<Radio color="secondary" />} label={["1", <br key={"br"} />, "Low"]} labelPlacement="bottom" />
            <FormControlLabelLinear className={classes.radioLabel} value="2" control={<Radio color="secondary" />} label="2" labelPlacement="bottom" />
            <FormControlLabelLinear className={classes.radioLabel} value="3" control={<Radio color="secondary" />} label="3" labelPlacement="bottom" />
            <FormControlLabelLinear className={classes.radioLabel} value="4" control={<Radio color="secondary" />} label="4" labelPlacement="bottom" />
            <FormControlLabelLinear className={classes.radioLabel} value="5" control={<Radio color="secondary" />} label={["5", <br key={"br"} />, "High"]} labelPlacement="bottom" />
          </RadioGroup>
        </ProfileSetupQuestion>

        <ProfileSetupQuestion error={validationError && maxGDS == null} errorMsg="Please enter the max GDS.">
          <MinMaxFieldWithOverrideCheckBox
            label="Max GDS"
            type="max"
            debounceDependancy={maxGDS}
            min={0}
            max={100}
            suffix="%"
            defaultValue={maxGDS}
            onChange={(v) => {
              setMaxGDS(v == null ? null : parseInt(v));
            }}
            overrideLabel="No max"
          />
        </ProfileSetupQuestion>

        <ProfileSetupQuestion error={validationError && maxTDS == null} errorMsg="Please enter the max TDS.">
          <MinMaxFieldWithOverrideCheckBox
            label="Max TDS"
            type="max"
            debounceDependancy={maxTDS}
            min={0}
            max={100}
            suffix="%"
            defaultValue={maxTDS}
            onChange={(v) => {
              setMaxTDS(v == null ? null : parseInt(v));
            }}
            overrideLabel="No max"
          />
        </ProfileSetupQuestion>

        <ProfileSetupQuestion error={validationError && minCreditScore == null} errorMsg="Please enter the min Credit Score.">
          <MinMaxFieldWithOverrideCheckBox
            label="Min Credit Score"
            type="min"
            debounceDependancy={minCreditScore}
            min={0}
            max={900}
            suffix="%"
            defaultValue={minCreditScore}
            onChange={(v) => {
              setMinCreditScore(v == null ? null : parseInt(v));
            }}
            overrideLabel="No min"
          />
        </ProfileSetupQuestion>

        <ProfileSetupQuestion label="How much capital do you have available to invest into mortgages? *" error={validationError && (availableCapital.now == null || availableCapital.mo6 == null)} errorMsg="Please enter total amount for each time period.">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <CurrencyField
                required
                label={"Now"}
                debounceDependancy={availableCapital}
                defaultValue={availableCapital.now}
                onChange={(v) => {
                  setAvailableCapital({ ...availableCapital, now: v });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CurrencyField
                required
                label={"6 months from now"}
                debounceDependancy={availableCapital}
                defaultValue={availableCapital.mo6}
                onChange={(v) => {
                  setAvailableCapital({ ...availableCapital, mo6: v });
                }}
              />
            </Grid>
          </Grid>
        </ProfileSetupQuestion>
      </form>
    </ProfileSetupWrapper>
  );
}
