import { PropsWithChildren, useState } from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  buttonWithSpinner: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    //top: "50%",
    //right: theme.spacing(1),
    //marginTop: -12,
    //marginLeft: -12,
  },
  fullWidth: {
    width: "100%",
  },
}));

interface Props extends PropsWithChildren<any> {
  fullwidth?: boolean;
  disabled?: boolean;
  showSpinner: boolean;
}

function ButtonWithSpinner({ disabled = false, showSpinner, children, fullwidth = false, ...rest }: Props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.buttonWithSpinner, classes.fullWidth && fullwidth)}>
      <Button disabled={disabled || showSpinner} {...rest}>
        {showSpinner && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
        {children}
      </Button>
    </div>
  );
}

export default ButtonWithSpinner;
