import React, { useCallback, useEffect } from "react";
import clsx from "clsx";

import PropTypes from "prop-types";

import { Checkbox, FormControlLabel, InputAdornment, makeStyles, TextField } from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";

import debounce from "lodash.debounce";
import MinMaxField from "./MinMaxField";
import FalsifiableTextField from "./FalsifiableTextField";

const useStyles = makeStyles((theme) => ({
  inlineCheckbox: {
    border: "1px solid #c4c4c4",
    borderRight: 0,
    background: "#e0e0e0",
    marginRight: 0,
    paddingRight: "9px",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    color: "#777",
    width: "123px",
    marginLeft: 0,
    "& .MuiTypography-root": {
      fontSize: "0.8rem",
    },
  },
  inlineCheckboxSmall: {
    width: "130px",
    "& .MuiCheckbox-root": {
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    "& .MuiTypography-root": {
      fontSize: "0.7rem",
    },
  },
  hasInlineCheckbox: {
    "& .MuiInputBase-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  displayFlex: {
    display: "flex",
    width: "100%",
  },
  flex: {
    flex: 1,
  },
}));

FalsifiableTextFieldWithCheckbox.propTypes = {
  ...FalsifiableTextField.propTypes,
  overrideLabel: PropTypes.string,
  size: PropTypes.string,
};

export default function FalsifiableTextFieldWithCheckbox(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({ checkbox: false, key: uuidv4(), defaultValue: props.defaultValue });

  const handleChange = (newValue) => {
    var tmpState = { ...state };

    if (newValue == null) {
      tmpState.checkbox = false;
    } else if (newValue === false) {
      tmpState.checkbox = true;
      tmpState.defaultValue = props.falsePlaceholder;
    } else {
      tmpState.checkbox = false;
      tmpState.defaultValue = newValue;
    }

    props.onChange(newValue);
    setState(tmpState);
  };

  return (
    <div className={clsx(classes.displayFlex, props.className)}>
      <FormControlLabel
        control={<Checkbox checked={state.checkbox} color="secondary" />}
        onChange={(e) => {
          if (e.target.checked) {
            setState({ ...state, key: uuidv4(), checkbox: true, defaultValue: props.falsePlaceholder });
          } else {
            setState({ ...state, key: uuidv4(), checkbox: false, defaultValue: null });
          }
        }}
        label={props.overrideLabel}
        className={clsx(classes.inlineCheckbox, props.size === "small" && classes.inlineCheckboxSmall)}
      />
      <FalsifiableTextField
        {...props}
        className={classes.hasInlineCheckbox}
        prefix={state.checkbox ? undefined : props.prefix}
        suffix={props.suffix}
        key={state.key}
        defaultValue={state.defaultValue}
        onChange={(v) => {
          handleChange(v);
        }}
      />
    </div>
  );
}
