import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { PropsWithChildren } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Avatar from "@material-ui/core/Avatar";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStylesHeader = makeStyles((theme) => ({
  menuButtonContainer: { flex: 1 },
  menuButton: {},
  menuIcon: { color: theme.palette.primary.dark },

  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarStatic: {
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "transparent",
    },
  },

  hide: {
    display: "none",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.grey[700],
    backgroundColor: "transparent",
  },
  toolbar: {
    justifyContent: "flex-end",
  },
  notificationIcon: {
    fill: theme.palette.primary.dark,
  },
}));

interface GlobalPageHeaderProps extends PropsWithChildren<any> {
  isSmWidthOrBigger: boolean;
  isDrawerOpen: boolean;
  handleDrawerOpen: () => void;
}
export default function GlobalPageHeader(props: GlobalPageHeaderProps) {
  const classes = useStylesHeader();

  return (
    <AppBar elevation={0} position="static" className={clsx(classes.appBar, classes.appBarStatic)}>
      <Toolbar variant="dense" className={classes.toolbar}>
        {!props.isSmWidthOrBigger && (
          <div className={classes.menuButtonContainer}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: props.isDrawerOpen && props.isSmWidthOrBigger,
              })}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </div>
        )}
        <IconButton edge="start">
          <Badge
            color="secondary"
            overlap="circle"
            variant="dot"
            invisible={false}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <NotificationsIcon className={classes.notificationIcon} />
          </Badge>
        </IconButton>

        {/*<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.avatar} />*/}
        <Avatar className={classes.avatar}>
          <AccountCircleIcon />
        </Avatar>
      </Toolbar>
    </AppBar>
  );
}
