import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { timeLapsedText } from "Utils/Time";
import IconButton from "@material-ui/core/IconButton";
import { updateInvestor, uploadDocument, uploadDocumentOld } from "Firebase/InvestorService";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { DocumentAccessType } from "Firebase/StorageService";

export interface DocRowProps {
  label: string;
  docName: string;
  docIndex: number;
  setData: any;
  data: any;
  classes: any;
  documentAccessType: DocumentAccessType;
}

export default function InvestorInfoDocRow(props: DocRowProps) {
  const useOld = true;
  const fileToBase64 = (file: any, cb: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result, file.name);
    };
    reader.onerror = function (error) {
      cb(error, null, null);
    };
  };

  return (
    <TableRow key={props.docName}>
      <TableCell component="th" scope="row">
        {props.label}
      </TableCell>
      <TableCell component="th" scope="row">
        {props.data.i.docs && props.data.i.docs[props.docIndex] && props.data.i.docs[props.docIndex].modified ? <>{timeLapsedText(props.data.i.docs[props.docIndex].modified)}</> : <>-</>}
      </TableCell>
      <TableCell align="right" component="th" scope="row">
        <input
          className={props.classes.fileInput}
          id={"icon-button-file" + props.docName}
          type="file"
          onChange={(e) => {
            if (e.target.files) {
              if (!useOld) {
                fileToBase64(e.target.files[0], (error: any, base64EncodedFile: any, filename: any) => {
                  uploadDocument(props.data.u.investorProfileId, props.docName, filename, base64EncodedFile, props.documentAccessType).then((result) => {
                    if (result) {
                      var tmpData = { u: props.data.u, i: props.data.i };

                      tmpData.i.docs[props.docIndex] = { ...tmpData.i.docs[props.docIndex], uri: result, modified: Date.now() };

                      props.setData(tmpData);
                      updateInvestor(tmpData.i);
                    }
                  });
                });
              } else {
                uploadDocumentOld(props.data.u.investorProfileId, props.docName, e.target.files[0], props.documentAccessType).then((result) => {
                  if (result) {
                    var tmpData = { u: props.data.u, i: props.data.i };

                    tmpData.i.docs[props.docIndex] = { ...tmpData.i.docs[props.docIndex], uri: result, modified: Date.now() };

                    props.setData(tmpData);
                    updateInvestor(tmpData.i);
                  }
                });
              }
            }
          }}
        />
        <label htmlFor={"icon-button-file" + props.docName}>
          <IconButton color="primary" aria-label="upload picture" component="span">
            <CloudUploadIcon />
          </IconButton>
        </label>

        {props.data.i.docs && props.data.i.docs[props.docIndex] && props.data.i.docs[props.docIndex]?.uri && (
          <IconButton
            size="small"
            aria-label="download"
            onClick={() => {
              window.open(props.data.i.docs[props.docIndex].uri, "_blank");
            }}
          >
            <CloudDownloadIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
