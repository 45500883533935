import React, { PropsWithChildren } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import ContentWrapper from "./ContentWrapper";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #c3cfdd",
  },
  indicator: {
    backgroundColor: theme.palette.primary.light,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
    "&:hover": {
      color: theme.palette.primary.light,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.primary.light,
    },
  },
  selected: {},
}))((props: any) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0),
  },
}));

export interface SubPageRouteInfo {
  name: string;
  path: string;
}
interface Props extends PropsWithChildren<any> {
  subPages?: SubPageRouteInfo[];
}
export default function CustomizedTabs(props: Props) {
  const classes = useStyles();
  const location = useLocation();
  var index = props.subPages?.findIndex((p) => p.path.includes(location.pathname)) ?? 0;
  if (index < 0) index = 0;
  const [value, setValue] = React.useState(index);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <>
      {props.subPages && props.subPages.length > 0 && (
        <ContentWrapper noTopPadding noBottomPadding>
          <div className={classes.root}>
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              {props.subPages.map((t) => (
                <AntTab key={t.name} component={RouterLink} label={t.name} to={t.path} />
              ))}
            </AntTabs>
          </div>
        </ContentWrapper>
      )}
    </>
  );
}
