import { useContext, useEffect, useState } from "react";
import clsx from "clsx";

import { Link as RouterLink, Redirect } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Box, makeStyles, Typography, Tooltip, IconButton } from "@material-ui/core";
import { UserContext } from "Authentication/UserProvider";
import PageWrapper from "Components/Common/PageWrapper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LoadingSpinner from "Components/Common/LoadingSpinner";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import AddInvestorDialog from "Pages/Users/AddInvestorDialog";
import SubPageRoutes from "./SubPageRoutes";
import { getAllUsersStatus } from "Firebase/AuthService";
import { sortAsc, sortDesc } from "Utils/Compare";
import Skeleton from "@material-ui/lab/Skeleton";
import { SKELETON_TABLE_ROW_HEIGHT } from "Utils/Skeleton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
}));
function createData(name: any, role: any, status: any, lastLogin: any) {
  return { name, role, status, lastLogin };
}

export default function ListUsers() {
  const classes = useStyles();
  const user = useContext(UserContext);

  const [data, setData] = useState<any>({ loading: true });
  const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);

  const getData = (isMounted: boolean) => {
    getAllUsersStatus().then((results) => {
      results.sort((a, b) => sortAsc(a.name, b.name));
      setData({ users: [...results], loading: false });
    });
  };

  useEffect(() => {
    let isMounted = true;

    getData(isMounted);

    return () => {
      isMounted = false;
    };
  }, []);

  const handleOpenAddUserDialog = () => {
    setOpenAddUserDialog(true);
  };
  const handleCloseAddUserDialog = () => {
    setOpenAddUserDialog(false);
  };

  return (
    <>
      <PageWrapper title="Users" subPages={SubPageRoutes}>
        <Box position="relative">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Role</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.loading ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Skeleton height={SKELETON_TABLE_ROW_HEIGHT} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {data.users?.length > 0 ? (
                      data.users.map((row: any) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.role}</TableCell>
                          <TableCell align="right">{row.status}</TableCell>
                          <TableCell align="right">
                            <IconButton style={{ visibility: "hidden" }} size="small" aria-label="send">
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>No users found.</TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Tooltip title="Add User" aria-label="AddUser" placement="right">
          <Fab color="secondary" className={classes.fab} onClick={handleOpenAddUserDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </PageWrapper>
      <AddInvestorDialog open={openAddUserDialog} handleClose={handleCloseAddUserDialog} />
    </>
  );
}
