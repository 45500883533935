import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    position: "relative",
    width: "100%",
    height: "75px",
  },
  loadingContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

function LoadingSpinner() {
  const classes = useStyles();

  return (
    <div className={classes.loadingWrapper}>
      <div className={classes.loadingContainer}>
        <CircularProgress variant="indeterminate" color="primary" />
      </div>
    </div>
  );
}

export default LoadingSpinner;
