import User from "Authentication/User";
import Investor from "Models/Investor";
import { DocumentAccessType } from "./StorageService";
import firebase, { functions, firestore, storage } from "./_firebase";

export const updateInvestor = async (investor: Investor) => {
  console.log("> Updating investor...");
  if (!investor) return false;

  try {
    var updateUserAPI = functions.httpsCallable("updateInvestor");
    var results = await updateUserAPI({ id: investor.id, investor: investor.getDataDocument() });
    console.log(results.data);
    if (!results.data.error) {
      console.log("> Successfully updated investor.");
      return true;
    } else {
      console.log(">! Something went wrong while trying to update investor.");
      return false;
    }
  } catch (e) {
    console.log(">! Something went wrong while trying to update investor.");
    return false;
  }
};

export const approveInvestor = async (investor: Investor) => {
  console.log("> Approving investor...");
  if (!investor) return false;

  try {
    var approveInvestorAPI = functions.httpsCallable("approveInvestor");
    var results = await approveInvestorAPI({ id: investor.id });
    if (!results.data.error) {
      console.log("> Successfully approved investor.");
      return true;
    } else {
      console.log(">! Something went wrong while trying to approving investor.");
      return false;
    }
  } catch (e) {
    console.log(">! Something went wrong while trying to approving investor.");
    return false;
  }
};

/*
//TODO: replace getInvestorDoc to not use firestore
export const getInvestorDoc = async (id: string) => {
  console.log("> Retrieving investor...");
  if (!id) return false;

  try {
    const userRef = firestore.doc(`investors/${id}`);
    const results = await userRef.get();

    if (!results.data()) {
      console.log(">! Something went wrong while trying to get the investor data.");
      return false;
    } else {
      console.log(">! Successfully retrieved investor data.");
      return Investor.fromDataDocument(id, results.data());
    }
  } catch (error) {
    console.log(">! Something went wrong while trying to get the investor data.");
    return false;
  }
};
*/

export const getAllPendingOrApprovedInvestorsInfo = async () => {
  console.log("> Retrieving investors list...");
  try {
    var getAllPendingOrApprovedInvestorsInfoAPI = functions.httpsCallable("getAllPendingOrApprovedInvestorsInfo");
    var results = await getAllPendingOrApprovedInvestorsInfoAPI();
    if (results && results.data && !results.data.error) {
      console.log("> Successfully retrieved investor list.");
      return results.data.investors;
    } else {
      console.log(">! Something went wrong while trying to get investors list.");
      return [];
    }
  } catch (error) {
    console.log(">! Something went wrong while trying to get investors list.");
    return [];
  }
};

export const getInvestorInfo = async (id: string) => {
  console.log("> Retrieving full investor information...");

  try {
    var getInvestorInfoAPI = functions.httpsCallable("getInvestorInfo");
    var results = await getInvestorInfoAPI({ id: id });

    if (results && results.data && !results.data.error) {
      console.log("> Successfully retrieved full investor information.");
      return {
        u: {}, //await User.(results.data.uid, results.data.user),
        i: await Investor.fromDataDocument(results.data.investor.id, results.data.investor.data),
      };
    } else {
      console.log(">! Something went wrong while trying to get full investor information.");
      return null;
    }
  } catch (error) {
    console.log(">!! Something went wrong while trying to get full investor information.", error);
    return null;
  }
};

export const sendCapitalUpdateRequest = async () => {
  console.log("> Sending requests for capital updates...");

  try {
    var sendCapitalUpdateRequestAPI = functions.httpsCallable("sendCapitalUpdateRequest");
    var results = await sendCapitalUpdateRequestAPI({});

    if (results && results.data && !results.data.error) {
      console.log("> Successfully sent requests for capital updates.");
      return {
        ...results.data.data,
      };
    } else {
      console.log(">! Something went wrong while trying to send requests for capital updates.");
      return null;
    }
  } catch (error) {
    console.log(">!! Something went wrong while trying to send requests for capital updates.", error);
    return null;
  }
};

export const getLastCapitalUpdateRequest = async () => {
  console.log("> Getting details of the last request for capital updates...");

  try {
    var getLastCapitalUpdateRequestAPI = functions.httpsCallable("getLastCapitalUpdateRequest");
    var results = await getLastCapitalUpdateRequestAPI({});

    if (results && results.data && !results.data.error) {
      console.log("> Successfully got details about the last request for capital updates.");
      return results.data.data;
    } else {
      console.log(">! Something went wrong while trying to get details of the last request for capital updates.");
      return null;
    }
  } catch (error) {
    console.log(">!! Something went wrong while trying to get details of the last request for capital updates.", error);
    return null;
  }
};

export const updateAvailableCapital = async (id: string, availableCapital: { now: any; mo6: any; modified: any }) => {
  console.log("> Updating capital available for investor...");

  try {
    var updateInvestorAvailableCapitalAPI = functions.httpsCallable("updateInvestorAvailableCapital");
    var results = await updateInvestorAvailableCapitalAPI({ id: id, availableCapital: availableCapital });

    if (results && results.data && !results.data.error) {
      console.log("> Successfully updated available capital for investor.");
      return true;
    } else {
      console.log(">! Something went wrong while trying to update the available capital for the investor.");
      return false;
    }
  } catch (error) {
    console.log(">!! Something went wrong while trying to update the available capital for the investor.", error);
    return false;
  }
};

/*
export const uploadDocument = async (id: string, docName: string, doc: any, accessType: DocumentAccessType) => {
  if (doc == null) return false;
  var re = /(?:\.([^.]+))?$/;
  var extension: any = re.exec(doc.name);
  if (extension) {
    extension = extension[0];
  } else {
    extension = "";
  }
  console.log(extension);

  return storage
    .ref(`/investors/${id}/${accessType}/${docName}${extension}`)
    .put(doc)
    .then((snapshot) => {
      return snapshot.ref.getDownloadURL().then((uri) => {
        return uri;
      });
    })
    .catch(() => {
      return false;
    });
};*/

export const uploadDocument = async (id: string, docName: string, filename: any, base64EncodedFile: any, accessType: DocumentAccessType) => {
  console.log("> Uploading file...");

  try {
    var uploadFileAPI = functions.httpsCallable("uploadFile");
    var results = await uploadFileAPI({ filename: filename, base64EncodedFile: base64EncodedFile });

    if (results && results.data && !results.data.error) {
      console.log("> Successfully uploaded file.");
      return "http://google.com";
    } else {
      console.log(">! Something went wrong while trying to upload the file.");
      return false;
    }
  } catch (error) {
    console.log(">! Something went wrong while trying to upload the file.");
    return false;
  }
};

export const uploadDocumentOld = async (id: string, docName: string, doc: any, accessType: DocumentAccessType) => {
  if (doc == null) return false;
  var re = /(?:\.([^.]+))?$/;
  var extension: any = re.exec(doc.name);
  if (extension) {
    extension = extension[0];
  } else {
    extension = "";
  }

  return storage
    .ref(`/investors/${id}/${accessType}/${docName}${extension}`)
    .put(doc)
    .then((snapshot) => {
      return snapshot.ref.getDownloadURL().then((uri) => {
        return uri;
      });
    })
    .catch(() => {
      return false;
    });
};

export const uploadDoc = async (folder: string, id: string, docName: string, doc: any, accessType: DocumentAccessType) => {
  if (doc == null) return false;
  var re = /(?:\.([^.]+))?$/;
  var extension: any = re.exec(doc.name);
  if (extension) {
    extension = extension[0];
  } else {
    extension = "";
  }

  return storage
    .ref(`/${folder}/${id}/${accessType}/${docName}${extension}`)
    .put(doc)
    .then((snapshot) => {
      return snapshot.ref.getDownloadURL().then((uri) => {
        return uri;
      });
    })
    .catch(() => {
      return false;
    });
};
