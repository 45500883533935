import { CollectionsBookmarkOutlined } from "@material-ui/icons";
import { updateUser } from "Firebase/AuthService";
import { savePendingInvestorData } from "Firebase/Users";
import Investor, { InvestorMemberStatus, InvestorStatus } from "Models/Investor";
import { StringifyOptions } from "querystring";
import { InvestorType } from "Shared/Investor/InvestorType";

interface mailingList {
  explicitConfirmation?: string;
  signUpDate?: string;
}

interface OnboardingDetails {
  LastStepNumber: number;
  TotalSteps: number;
  StepsToSkipIfNotPrimary: number[];
}
export interface DocumentInfo {
  friendlyName: string;
  internalName: string;
  uri: string | null;
  modified: number | null;
  required: boolean;
}
export default class User {
  static Roles = {
    NONE: "none",
    ADMIN: "admin",
    INVESTOR: "investor",
    LAWYER: "lawyer",
    ACCOUNTANT: "accountant",
    APPRAISER: "appraiser",
  };

  static ProfileOnboardingDetails: OnboardingDetails = {
    LastStepNumber: 1,
    TotalSteps: 1,
    StepsToSkipIfNotPrimary: [],
  };
  static InvestorOnboardingDetails: { [char: string]: OnboardingDetails } = {
    //excludes step 0 and last/submit steps

    corp: {
      LastStepNumber: 5,
      TotalSteps: 6,
      StepsToSkipIfNotPrimary: [1, 2, 3, 4, 5],
    },
    indv: {
      LastStepNumber: 5,
      TotalSteps: 6,
      StepsToSkipIfNotPrimary: [1, 2, 3, 4, 5],
    },
    joint: {
      LastStepNumber: 5,
      TotalSteps: 6,
      StepsToSkipIfNotPrimary: [1, 2, 3, 4, 5],
    },
    trust: {
      LastStepNumber: 5,
      TotalSteps: 6,
      StepsToSkipIfNotPrimary: [1, 2, 3, 4, 5],
    },
  };

  static TotalProfileSetupStepsForRoles: { [char: string]: number } = {
    //excludes step 0 and last/submit steps
    admin: 1,
    investor: 6,
  };

  isRole(roleToCheckAgainst: string) {
    return (this.role?.toLowerCase() ?? "") === roleToCheckAgainst.toLowerCase();
  }

  static REQUIRED_DOCS: DocumentInfo[] = [
    { friendlyName: "KYC", internalName: "KYC", uri: null, modified: null, required: false },
    { friendlyName: "Admin Agreement", internalName: "Admin Agreement", uri: null, modified: null, required: false },
    { friendlyName: "Identification 1", internalName: "Identification 1", uri: null, modified: null, required: true },
    { friendlyName: "Identification 2", internalName: "Identification 2", uri: null, modified: null, required: true },
  ];

  static VERSION: number = 1;
  static REQUIRE_2FA: boolean = true;

  updateCount: number = 0;

  uid: string | null = null;
  email: string | null = null;
  phone: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  dob: string | null = null;
  address: {
    address1: string | null;
    province: string | null;
    country: string | null;
    postalcode: string | null;
  } = {
    address1: null,
    province: "Ontario",
    country: "Canada",
    postalcode: null,
  };

  docs: DocumentInfo[] = [];

  enrolledFor2FA: boolean | null = false;
  emailVerified: boolean | null = false;

  role: string | null = null;
  permissions: Array<string> = [];

  avatarUrl: string | null = null;

  mailingList?: {
    explicitConfirmation?: boolean | null;
    signUpDate?: string | null;
  } = {
    explicitConfirmation: null,
    signUpDate: null,
  };

  profileCompletion: {
    completedVersion: number | null;
    completedSteps: number | null;
  } = {
    completedVersion: 0,
    completedSteps: 0,
  };

  investorsRaw: { [char: string]: any } = {};
  private activeInvestorId: string | null = null;
  activeInvestor: Investor | null = null;

  setActiveInvestorId(id: string | null) {
    if (id == null) {
      this.activeInvestorId = null;
    } else {
      if (Object.keys(this.investorsRaw).length > 0 && this.investorsRaw[id]) {
        this.activeInvestorId = id;
      } else {
        this.activeInvestorId = null;
      }
    }

    if (this.activeInvestorId == null) this.activeInvestor = null;
    else this.activeInvestor = Investor.fromDataDocument(this.activeInvestorId, this.investorsRaw[this.activeInvestorId]);
  }

  //pendingInvestor: { investorType: string; investorSubType: string; created: number; emailLastSent: null | number; memberEmails: string[]; members: { email: string; name: string; status: InvestorMemberStatus; completedSteps: number }[] } | null = null;

  constructor(uid: string | null) {
    this.uid = uid;
    User.REQUIRED_DOCS.forEach((doc) => {
      this.docs?.push({ ...doc });
    });
  }

  static NullUser() {
    return new User(null);
  }
  /*
  static async fromUserDocument(uid: string, doc: any) {
    const u = new User(uid);
    console.log("doc", doc);

    if (doc.email) u.email = doc.email;
    if (doc.phone) u.email = doc.phone;
    if (doc.firstName) u.firstName = doc.firstName;
    if (doc.lastName) u.lastName = doc.lastName;
    if (doc.address) u.address = doc.address;
    if (doc.dob) u.dob = doc.dob;

    if (doc.enrolledFor2FA) u.enrolledFor2FA = doc.enrolledFor2FA;
    if (doc.emailVerified) u.emailVerified = doc.emailVerified;

    if (doc.profileCompletion) u.profileCompletion = doc.profileCompletion;

    if (doc.role) u.role = doc.role;
    if (doc.avatarUrl) u.avatarUrl = doc.avatar;

    if (doc.mailingList) u.mailingList = doc.mailingList;

    if (doc.role) u.role = doc.role;

    if (doc.permissions) u.permissions = doc.permissions;

    if (doc.activeInvestor) u.activeInvestorId = doc.activeInvestorId;

    return u;
  }*/

  isLoggedIn() {
    if (User.REQUIRE_2FA && this.uid) return true;

    if (!User.REQUIRE_2FA && this.uid) return true;

    return false;
  }

  isPartialLoggedIn() {
    if (!this.uid) return false;
    return true;
  }

  getUserDocument() {
    return {
      email: this.email,
      phone: this.phone,
      firstName: this.firstName,
      lastName: this.lastName,
      address: this.address,
      dob: this.dob,
      docs: this.docs,

      avatarUrl: this.avatarUrl,

      enrolledFor2FA: this.enrolledFor2FA,
      emailVerified: this.emailVerified,

      role: this.role,
      permissions: this.permissions,

      profileCompletion: this.profileCompletion,
      mailingList: this.mailingList,
    };
  }

  async save() {
    return await updateUser(this);
  }

  private isProfileOnboardingComplete() {
    if (!this.profileCompletion || !this.profileCompletion.completedVersion || this.profileCompletion.completedVersion !== User.VERSION) return false;
    return true;
  }
  private isMyPendingInvestorOnboardingComplete() {
    if (!this.activeInvestor || this.isRole(User.Roles.ADMIN)) return true;
    else {
      if ((this.activeInvestor?.status === InvestorStatus.Onboarding || this.activeInvestor?.status === InvestorStatus.PendingApproval) && this.activeInvestor?.members[this.getMyPendingInvestorOnboardingIndex()].status !== InvestorMemberStatus.None) return false;
      return true;
    }
  }

  isOnboardingComplete() {
    return this.isProfileOnboardingComplete() && this.isMyPendingInvestorOnboardingComplete();
  }

  setProfileOnboardingCompletedSteps(completedStep: number) {
    this.profileCompletion.completedSteps = completedStep;

    if (completedStep === User.ProfileOnboardingDetails.LastStepNumber) this.setProfileOnboardingComplete();
  }

  setProfileOnboardingComplete() {
    this.profileCompletion.completedVersion = User.VERSION;
  }

  getMyPendingInvestorOnboardingIndex() {
    const currentInvestor = this.activeInvestor;
    if (currentInvestor) {
      for (var i = 0; i < currentInvestor.members.length; i++) {
        if (currentInvestor.members[i].email === this.email) return i;
      }
    }

    return -1;
  }

  setMyPendingInvestorOnboardingCompletedSteps(completedStep: number) {
    var currentInvestor = this.activeInvestor;
    console.log("completedStep", completedStep);
    if (currentInvestor) {
      const myMember = currentInvestor.members[this.getMyPendingInvestorOnboardingIndex()];

      var nextStep = myMember.completedSteps + 1;

      console.log("nextStep", nextStep);
      console.log("index", this.getMyPendingInvestorOnboardingIndex());

      if (this.getMyPendingInvestorOnboardingIndex() !== 0) {
        //is not the primary
        const stepsToSkipIfNotPrimary = User.InvestorOnboardingDetails[this.activeInvestor?.investorType ?? InvestorType.INDV].StepsToSkipIfNotPrimary;
        console.log("stepsToSkipIfNotPrimary", stepsToSkipIfNotPrimary);
        while (stepsToSkipIfNotPrimary.includes(nextStep)) nextStep++;
        console.log("nextStep", nextStep);

        if (nextStep > User.InvestorOnboardingDetails[this.activeInvestor?.investorType ?? InvestorType.INDV].LastStepNumber) {
          nextStep = User.InvestorOnboardingDetails[this.activeInvestor?.investorType ?? InvestorType.INDV].LastStepNumber;
        }

        completedStep = nextStep > 0 ? nextStep : 0;
      }

      myMember.completedSteps = completedStep;
      console.log(myMember);
    }

    if (completedStep === User.InvestorOnboardingDetails[this.activeInvestor?.investorType ?? "indv"].LastStepNumber) this.setMyPendingInvestorOnboardingCompleted();
    //currentInvestor = await savePendingInvestorData(currentInvestor);
    return;
  }

  setMyPendingInvestorOnboardingCompleted() {
    //this.profileCompletion.completedVersion = User.VERSION;
    console.log("> Checking which state to complete the investor member to...", this.activeInvestor);
    var currentInvestor = this.activeInvestor;
    if (currentInvestor) {
      const myMemberIndex = this.getMyPendingInvestorOnboardingIndex();
      currentInvestor.members[this.getMyPendingInvestorOnboardingIndex()].status = InvestorMemberStatus.WaitingOnOtherMembers;
      /*

      if (currentInvestor.memberEmails.length > 1) {
        console.log("> Setting investor member to wait on other members...", this.activeInvestor);
      } else {
        console.log("> Setting investor member to completed and awaiting approval...", this.activeInvestor);
        currentInvestor.members[this.getMyPendingInvestorOnboardingIndex()].status = InvestorMemberStatus.WaitingForApproval;
      }

      
      var howManyWaitingOnOtherMembers = 0;
      for (var i = 0; i < currentInvestor.memberEmails.length; i++) {
        if (currentInvestor.members[i].status === InvestorMemberStatus.WaitingForApproval) howManyWaitingOnOtherMembers++;
      }
      //if (howManyWaitingOnOtherMembers === currentInvestor.memberEmails.length) currentInvestor.status = InvestorStatus.PendingApproval;
      console.log(`> Investor has ${currentInvestor.memberEmails.length} members and ${howManyWaitingOnOtherMembers} are awaiting other members`);
      //currentInvestor = await savePendingInvestorData(currentInvestor);
      */
    }
  }

  private getProfileOnboardingNextStep() {
    if (!this.profileCompletion) return 1;
    if (this.profileCompletion.completedSteps == null) return 1;

    if (!this.profileCompletion.completedVersion) {
      return this.profileCompletion.completedSteps + 1;
    } else {
      if (this.profileCompletion.completedVersion !== User.VERSION) return 1;
      else return 1;
    }
  }

  setMyPendingInvestorOnboardingStatusToOnboarding() {
    if (this.activeInvestor) {
      const myMember = this.activeInvestor.members[this.getMyPendingInvestorOnboardingIndex()];
      myMember.status = InvestorMemberStatus.Onboarding;
      this.setMyPendingInvestorOnboardingCompletedSteps(0);
    }
  }

  private getMyPendingInvestorNextStep() {
    var currentInvestor = this.activeInvestor;
    if (!currentInvestor) return -1;

    var nextStep = 1;
    const myMember = currentInvestor.members[this.getMyPendingInvestorOnboardingIndex()];
    if (myMember.completedSteps == null) return null;

    switch (myMember.status) {
      case InvestorMemberStatus.None:
        return 1;
      case InvestorMemberStatus.EmailSent:
        return 1;
      case InvestorMemberStatus.Onboarding:
        return myMember.completedSteps + 1;
      case InvestorMemberStatus.WaitingOnOtherMembers:
        return myMember.completedSteps + 1;
      case InvestorMemberStatus.WaitingForApproval:
        return myMember.completedSteps + 1;
      default:
        return 1;
    }
  }

  getNextOnboardingPageLink() {
    var currentInvestor = this.activeInvestor;
    console.log("currentInvestor", currentInvestor);
    console.log("this.isProfileOnboardingComplete()", this.isProfileOnboardingComplete());
    console.log("this.isMyPendingInvestorOnboardingComplete()", this.isMyPendingInvestorOnboardingComplete());
    if (!this.isProfileOnboardingComplete()) return "/signup/onboarding/" + (this.getProfileOnboardingNextStep() === 1 ? "" : this.getMyPendingInvestorNextStep());
    else if (!this.isMyPendingInvestorOnboardingComplete() && currentInvestor) return "/signup/onboarding/investor/" + currentInvestor.investorType + "/" + this.getMyPendingInvestorNextStep();
    else return "/signup/onboarding/0";
  }

  setMailingListNewConfirmation() {
    this.mailingList = {
      explicitConfirmation: true,
      signUpDate: new Date().getUTCDate().toString(),
    };
  }
}
