import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Grid, Typography, withStyles, Switch, makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  spaceLeft: {
    marginLeft: theme.spacing(1),
  },
  spaceRight: {
    marginRight: theme.spacing(1),
  },
  toggle: {
    "&.MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
}));
TrueFalseToggle.propTypes = {};

TrueFalseToggle.defaultProps = {
  falseLabel: "Off",
  trueLabel: "On",
};

export interface TrueFalseToggleProps {
  falseLabel: any;
  trueLabel: any;
  defaultValue?: any;
  className?: any;
  required?: boolean;
  onChange: (v: any) => void;
}

export default function TrueFalseToggle({ falseLabel = "Off", trueLabel = "On", ...props }: TrueFalseToggleProps) {
  const [value, setValue] = React.useState(props.defaultValue == null ? "" : props.defaultValue);
  const classes = useStyles();
  return (
    <ToggleButtonGroup
      className={clsx(props.className)}
      value={value == null ? "" : value}
      exclusive
      onChange={(_, v) => {
        setValue(v);
        props.onChange(v);
      }}
      aria-label="text alignment"
    >
      <ToggleButton className={classes.toggle} value={true} aria-label="left aligned">
        {trueLabel}
      </ToggleButton>
      <ToggleButton className={classes.toggle} value={false} aria-label="centered">
        {falseLabel}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
