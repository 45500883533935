import React from "react";

import { UserProvider } from "Authentication/UserProvider";
import { BrowserRouter as Router } from "react-router-dom";

import ScrollRestoiration from "Components/Common/ScrollRestoration";

import { CssBaseline, MuiThemeProvider } from "@material-ui/core";

import AppRouter from "AppRouter";

import theme from "theme";
import "./App.css";

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <ScrollRestoiration />
        <UserProvider>
          <CssBaseline />
          <AppRouter />
        </UserProvider>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
