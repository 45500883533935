import React, { useCallback, useEffect } from "react";
import clsx from "clsx";

import PropTypes from "prop-types";

import { Checkbox, FormControlLabel, InputAdornment, InputBaseComponentProps, makeStyles, OutlinedInputProps, TextField, Tooltip } from "@material-ui/core";

import debounce from "lodash.debounce";

const useStyles = makeStyles((theme) => ({}));

const customParse = (value: any, isDecimal: boolean) => {
  if (isDecimal) {
    return parseFloat(value);
  } else {
    return parseInt(value);
  }
};

const formatCurrency = (x: any) => {
  return x.toLocaleString();
};

export interface CurrencyFieldProps {
  includesDecimals?: boolean;
  defaultValue: number | null;

  size?: "medium" | "small" | undefined;
  debounceDependancy?: any;

  className?: any;
  required?: boolean;
  label: string;
  onChange: (v: any) => void;
}

export default function CurrencyField({ includesDecimals = false, ...props }: CurrencyFieldProps) {
  const classes = useStyles();

  const [state, setState] = React.useState<{ value: number | null; displayValue: string | null }>({ value: null, displayValue: "" });

  const handleChange = (newValue: any) => {
    var tmpState = { ...state };
    if (newValue) newValue = newValue.toString().replace(",", "");

    if (newValue == null) {
      tmpState.value = null;
      tmpState.displayValue = "";
    } else if (newValue === "") {
      tmpState.value = null;
      tmpState.displayValue = "";
    } else {
      tmpState.value = customParse(newValue, includesDecimals);
      tmpState.displayValue = formatCurrency(customParse(newValue, includesDecimals));
    }

    props.onChange(tmpState.value);
    setState(tmpState);
  };

  useEffect(() => {
    handleChange(props.defaultValue);
  }, [props.defaultValue]);

  const debounceHandleChange = useCallback(
    debounce((newValue) => handleChange(newValue), 500, { leading: false, trailing: true }),
    [props.defaultValue, props.debounceDependancy]
  );
  const inputProps: InputBaseComponentProps = { inputMode: "numeric" };
  if (includesDecimals) {
    inputProps.inputMode = "decimal";
    //inputProps.pattern = "[0-9]+(.[0-9]+)?";
  }
  const InputProps: Partial<OutlinedInputProps> = {};
  InputProps.startAdornment = <InputAdornment position="start">$</InputAdornment>;

  return (
    <TextField
      className={clsx(props.className)}
      size={props.size}
      value={state.displayValue}
      type="text"
      inputProps={inputProps}
      InputProps={InputProps}
      fullWidth
      variant="outlined"
      label={props.label}
      onChange={(e) => {
        setState({ ...state, displayValue: formatCurrency(e.target.value.toString().replace(",", "")) });
        debounceHandleChange(e.target.value);
      }}
    />
  );
}
