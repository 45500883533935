import React, { useContext } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { Switch, Route, useHistory, RouteProps, RouteComponentProps, Redirect } from "react-router-dom";

import { CircularProgress, makeStyles } from "@material-ui/core";

import Nav from "Components/Navigation/Nav";

import Home from "Home/Home";
import { UserContext } from "Authentication/UserProvider";
import LogIn from "Authentication/Pages/LogIn";
import SignUp2FA from "Authentication/Pages/SignUp2FA";
import SignUpAccount from "Authentication/Pages/SignUpAccount";
import WaitForAuthentication from "Authentication/Pages/WaitForAuthentication";
import Verify2FA from "Authentication/Pages/Verify2FA";
import VerifyEmail from "Authentication/Pages/VerifyEmail";
import SignUpComplete from "Authentication/Pages/SignUpComplete";
import WaitFor2FAAuthentication from "Authentication/Pages/WaitFor2FAAuthentication";
import LoadingSpinnerFullScreen from "Components/Common/LoadingSpinnerFullScreen";
import ListUsers from "Pages/Users/ListUsers";
import ListPendingInvestors from "Pages/Users/ListPendingInvestors";
import ListInvestors from "Pages/Investors/ListInvestors";
import InvestorInfo from "Pages/Investors/InvestorInfo";
import GlobalPageHeader from "Components/Navigation/GlobalPageHeader";
import OnboardingUserBasicInformation from "Pages/Onboarding/User/OnboardingUserBasicInformation";
import OnboardingInvestorUnderwriting from "Pages/Onboarding/Investor/OnboardingInvestorUnderwriting";
import OnboardingInvestorMortgageOptions from "Pages/Onboarding/Investor/OnboardingInvestorMortgageOptions";
import OnboardingInvestorMortgageLTV from "Pages/Onboarding/Investor/OnboardingInvestorMortgageLTV";
import OnboardingUserKYC from "Pages/Onboarding/Investor/OnboardingInvestorKYC";
import OnboardingUserAdminAgreement from "Pages/Onboarding/Investor/OnboardingInvestorAdminAgreement";
import OnboardingInvestorCorporationInformation from "Pages/Onboarding/Investor/Corporation/OnboardingInvestorCorporationInformation";
import OnboardingInvestorBankingInformation from "Pages/Onboarding/Investor/OnboardingInvestorBankingInfomation";
import OnboardingInvestorIndividualInformation from "Pages/Onboarding/Investor/Individual/OnboardingInvestorIndividualInformation";
import OnboardingInvestorTrustInformation from "Pages/Onboarding/Investor/Trust/OnboardingInvestorTrustInformation";
import OnboardingInvestorJointInformation from "Pages/Onboarding/Investor/Joint/OnboardingInvestorJointInformation";
import RequestCapitalUpdate from "Pages/Investors/RequestCapitalUpdate";
import UpdateCapital from "Pages/UpdateCapital/UpdateCapital";

const useStyles = makeStyles((theme) => ({
  mainNoNav: {
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  main: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "52px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "200px",
    },
  },
}));

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  exact: boolean;
  path: string;
  nav: boolean;
  withProps?: object;
  isAuthenticationFlow?: boolean;
}

export const PublicRoute = ({ nav, path, exact, withProps, isAuthenticationFlow = false, component: Component, ...rest }: PublicRouteProps) => {
  const classes = useStyles();
  const componentWithProps = (props: any) => (
    <>
      {nav ? (
        <>
          <Nav />

          {/*<GlobalPageHeader handleDrawerOpen={handleDrawerOpen} isDrawerOpen={isOpen()} isSmWidthOrBigger={isSmWidthOrBigger} />*/}
          <GlobalPageHeader handleDrawerOpen={() => {}} isDrawerOpen={false} isSmWidthOrBigger={true} />
          <main className={classes.main}>
            <Component {...props} {...rest} {...withProps} />
          </main>
        </>
      ) : (
        <>
          <main className={classes.mainNoNav}>
            <Component {...props} {...rest} {...withProps} />
          </main>
        </>
      )}
    </>
  );
  return <Route path={path} exact={exact} component={componentWithProps} />;
};

interface AuthRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  exact: boolean;
  path: string;
  nav: boolean;
  isAuthenticated: boolean;
  redirect?: string;
  withProps?: object;
  isAuthenticationFlow?: boolean;
}

export const AuthRoute = ({ isAuthenticated, nav, path, exact, redirect, withProps, isAuthenticationFlow = false, component: Component, ...rest }: AuthRouteProps) => {
  const classes = useStyles();
  const history = useHistory();
  if (isAuthenticated === false) {
    const componentWithProps = () => <Redirect to={redirect ? redirect : "/authenticate?referrer=" + history.location.pathname} />;
    return <Route path={path} exact={exact} component={componentWithProps} />;
  } else {
    const componentWithProps = (props: any) => (
      <>
        {nav ? (
          <>
            <Nav />

            {/*<GlobalPageHeader handleDrawerOpen={handleDrawerOpen} isDrawerOpen={isOpen()} isSmWidthOrBigger={isSmWidthOrBigger} />*/}
            <GlobalPageHeader handleDrawerOpen={() => {}} isDrawerOpen={false} isSmWidthOrBigger={true} />
            <main className={classes.main}>
              <Component {...props} {...rest} {...withProps} />
            </main>
          </>
        ) : (
          <>
            <main className={classes.mainNoNav}>
              <Component {...props} {...rest} {...withProps} />
            </main>
          </>
        )}
      </>
    );
    return <Route path={path} exact={exact} component={componentWithProps} />;
  }
};

function AppRouter() {
  const user = useContext(UserContext);
  const history = useHistory();

  const urlParts = history.location.pathname.split("/");

  const isAuthenticated = user.isLoggedIn();

  return (
    <Switch>
      <>
        <PublicRoute isAuthenticationFlow={true} path="/login" nav={false} component={LogIn} exact />
        <PublicRoute isAuthenticationFlow={true} path="/login/2fa/verify" nav={false} component={Verify2FA} exact />
        <PublicRoute isAuthenticationFlow={true} path="/signup" nav={false} component={SignUpAccount} exact />
        <PublicRoute isAuthenticationFlow={true} path="/signup/investor" nav={false} component={SignUpAccount} exact />
        <PublicRoute isAuthenticationFlow={true} path="/signup/email/verify" nav={false} component={VerifyEmail} exact />
        <AuthRoute isAuthenticationFlow={true} path="/signup/2fa" redirect="/signup" isAuthenticated={user.isPartialLoggedIn()} nav={false} component={SignUp2FA} exact />
        <AuthRoute isAuthenticationFlow={true} path="/signup/2fa/verify" redirect="/signup" isAuthenticated={user.isPartialLoggedIn()} nav={false} component={Verify2FA} exact />
        <PublicRoute isAuthenticationFlow={true} path="/signup/complete" nav={false} component={SignUpComplete} exact />
        <PublicRoute isAuthenticationFlow={true} path="/authenticate" nav={false} component={WaitForAuthentication} exact />
        <PublicRoute isAuthenticationFlow={true} path="/login/2fa/authenticate" nav={false} component={WaitFor2FAAuthentication} exact />

        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/" nav={false} component={OnboardingUserBasicInformation} exact />

        {/* Corporation */}
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/corp/1" nav={false} component={OnboardingInvestorCorporationInformation} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/corp/2" nav={false} component={OnboardingInvestorBankingInformation} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/corp/3" nav={false} component={OnboardingInvestorUnderwriting} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/corp/4" nav={false} component={OnboardingInvestorMortgageOptions} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/corp/5" nav={false} component={OnboardingInvestorMortgageLTV} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/corp/6" nav={false} component={SignUpComplete} exact />

        {/* Trust */}
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/trust/1" nav={false} component={OnboardingInvestorTrustInformation} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/trust/2" nav={false} component={OnboardingInvestorBankingInformation} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/trust/3" nav={false} component={OnboardingInvestorUnderwriting} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/trust/4" nav={false} component={OnboardingInvestorMortgageOptions} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/trust/5" nav={false} component={OnboardingInvestorMortgageLTV} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/trust/6" nav={false} component={SignUpComplete} exact />

        {/* Individual */}
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/indv/1" nav={false} component={OnboardingInvestorIndividualInformation} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/indv/2" nav={false} component={OnboardingInvestorBankingInformation} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/indv/3" nav={false} component={OnboardingInvestorUnderwriting} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/indv/4" nav={false} component={OnboardingInvestorMortgageOptions} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/indv/5" nav={false} component={OnboardingInvestorMortgageLTV} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/indv/6" nav={false} component={SignUpComplete} exact />

        {/* Joint */}
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/joint/1" nav={false} component={OnboardingInvestorJointInformation} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/joint/2" nav={false} component={OnboardingInvestorBankingInformation} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/joint/3" nav={false} component={OnboardingInvestorUnderwriting} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/joint/4" nav={false} component={OnboardingInvestorMortgageOptions} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/joint/5" nav={false} component={OnboardingInvestorMortgageLTV} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/joint/6" nav={false} component={SignUpComplete} exact />

        {/*
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/4" nav={false} component={(props: any) => <OnboardingInvestorMortgagePositionDetails {...props} title="1st Mortgage Details" pos="first" posShort="1st" />} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/5" nav={false} component={(props: any) => <OnboardingInvestorMortgagePositionDetails {...props} title="2nd Mortgage Details" pos="second" posShort="2nd" />} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/signup/onboarding/investor/6" nav={false} component={(props: any) => <OnboardingInvestorMortgagePositionDetails {...props} title="3rd Mortgage Details" pos="third" posShort="3rd" />} exact />
        */}
        <AuthRoute isAuthenticated={true} path="/signup/onboarding/investor/5" nav={false} component={OnboardingUserKYC} exact />
        <AuthRoute isAuthenticated={true} path="/signup/onboarding/investor/6" nav={false} component={OnboardingUserAdminAgreement} exact />
        <AuthRoute isAuthenticated={true} path="/signup/onboarding/investor/7" nav={false} component={SignUpComplete} exact />
        {/*
        <PublicRoute path="/signup/investor/onboarding/2" nav={false} component={InvestorBlossomInfo} exact />
        <PublicRoute path="/signup/investor/onboarding/3" nav={false} component={InvestorLTVCapital} exact />
*/}
        <AuthRoute isAuthenticated={isAuthenticated} path="/" nav={true} component={Home} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/users" nav={true} component={ListUsers} exact />
        {/*<Route path={"/users/invites"} exact component={ListInvites} />;*/}
        <AuthRoute isAuthenticated={isAuthenticated} path="/users/investor/pending" nav={true} component={ListPendingInvestors} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/investors" nav={true} component={ListInvestors} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/investors/request-capital-update" nav={true} component={RequestCapitalUpdate} exact />
        <AuthRoute isAuthenticated={isAuthenticated} path="/investors/details/:id" nav={true} component={InvestorInfo} exact />

        <AuthRoute isAuthenticated={isAuthenticated} path="/update-capital" nav={false} component={UpdateCapital} exact />
      </>
      {/*
      {user.updateCount === 0 && shouldWaitForUserToUpdate() && ["login", "signup"].indexOf(urlParts[1]) === -1 ? (
        <LoadingSpinnerFullScreen />
      ) : (
      )} */}
    </Switch>
  );
}
export default AppRouter;
