import React, { useContext, useEffect } from "react";

import { UserContext } from "Authentication/UserProvider";
import { useHistory, RouteComponentProps, useLocation } from "react-router-dom";
import User from "Authentication/User";
import { auth, clearRecaptchaVerifier, ILoginResults, maskEmail } from "Firebase/AuthService";
import LoadingSpinnerFullScreen from "Components/Common/LoadingSpinnerFullScreen";

interface WaitForAuthenticationProps {
  referrer?: string;
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const WaitForAuthentication = (props: RouteComponentProps<WaitForAuthenticationProps, {}, ILoginResults>) => {
  const history = useHistory();
  const user = useContext(UserContext);
  let query = useQuery();
  var attempt = 0;

  const referrer = query.get("referrer") ?? localStorage.getItem("referrer") ?? "/";

  function doRedirect() {
    if (user.isLoggedIn() && !User.REQUIRE_2FA) {
      //clearRecaptchaVerifier();
      localStorage.removeItem("referrer");
      history.replace(referrer);
    } else if (!auth.currentUser?.emailVerified && user.isPartialLoggedIn()) {
      history.replace("/signup/email/verify", { email: user.email, maskedEmail: maskEmail((user.email ?? "").toString()), referrer: "/signup" });
    } else if ((auth.currentUser?.multiFactor.enrolledFactors.length ?? 0) === 0 && User.REQUIRE_2FA && user.isPartialLoggedIn()) {
      history.replace("/signup/2fa");
    } else if (props.location?.state?.require2FALogin ?? false) {
      //
      history.replace("/login/2fa/verify", { phone: props.location.state.unmasked2FADestination, maskedPhone: props.location.state.masked2FADestination, referrer: "/login" });
    } else if (user.isLoggedIn() && user.isOnboardingComplete()) {
      localStorage.removeItem("referrer");
      history.replace(referrer);
    } else if (user.isLoggedIn() && !user.isOnboardingComplete()) {
      console.log("> Sending user to onboarding flow", user.getNextOnboardingPageLink());
      history.replace(user.getNextOnboardingPageLink());
    } else {
      attempt++;

      if (attempt < 25) {
        timer = setTimeout(function () {
          doRedirect();
        }, 200);
      } else {
        history.replace("/login" + (referrer ? "/?referrer=" + referrer : ""));
      }
    }
  }

  var timer: any = null;
  useEffect(() => {
    doRedirect();

    return () => {
      clearTimeout(timer);
    };
    // seslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinnerFullScreen />;
};

export default WaitForAuthentication;
