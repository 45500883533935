import { PropsWithChildren } from "react";

import { makeStyles, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ContentWrapper from "Components/Common/ContentWrapper";
import CustomizedTabs, { SubPageRouteInfo } from "./PageTabs";

const useStylesHeader = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    color: "#1b3a57",
  },
}));

interface Props extends PropsWithChildren<any> {
  title: string;
  subPages?: SubPageRouteInfo[];
}
export default function PageWrapper(props: Props) {
  const classes = useStylesHeader();
  return (
    <>
      <ContentWrapper noTopPadding noBottomPadding component="header">
        <Typography variant="h1" noWrap className={classes.title}>
          {props.title}
        </Typography>
      </ContentWrapper>

      <CustomizedTabs subPages={props.subPages ?? undefined} />

      {/*
      <AppBar component="div" elevation={0} position="static" className={classes.appBar}>
        <Toolbar variant="dense">
          <Typography variant="h6" noWrap>
            Dynamic Header
          </Typography>
        </Toolbar>
      </AppBar>
*/}
      <ContentWrapper component="main">{props.children}</ContentWrapper>
    </>
  );
}
