import Investor from "./Investor";

export default class InvestorFinderProducts {
  static _products: any = null;

  static getProducts(allInvestors: any) {
    if (!InvestorFinderProducts._products) InvestorFinderProducts._products = this.parse(allInvestors);
    return InvestorFinderProducts._products;
  }

  static parse(investor: any) {
    var products: any[] = [];

    investor.forEach((i: any) => {
      if (!i.version) return;

      var p = this.getProductFromInvestor(i);
      if (p) products.push(p);
    });

    return products;
  }

  static variableToDisplayText: { [key: string]: any } = {
    mortgagePosition: {
      first: "1st",
      second: "2nd",
      third: "3rd",
    },
    location: {
      bruce: "Bruce",
      dufferin: "Dufferin",
      durham: "Durham",
      elgin: "Elgin",
      essex: "Essex",
      frontenac: "Frontenac",
      grey: "Grey",
      haliburton: "Haliburton",
      halton: "Halton",
      hastings: "Hastings",
      huron: "Huron",
      lambton: "Lambton",
      lanark: "Lanark",
      leedsAndGrenville: "Leeds and Grenville",
      lennoxAndAddington: "Lennox and Addington",
      middlesex: "Middlesex",
      muskoka: "Muskoka",
      niagara: "Niagara",
      northumberland: "Northumberland",
      oxford: "Pxford",
      peel: "Peel",
      perth: "Perth",
      peterborough: "Peterborough",
      prescottAndRussell: "Prescott and Russell",
      renfrew: "Renfrew",
      simcoe: "Simcoe",
      stormontDundasAndGlengarry: "Stormont, Dundas and Glengarry",
      waterloo: "Waterloo",
      wellington: "Wellington",
      york: "York",
    },
    property: {
      singleFamily: "Single-Family Residential",
      construction: "Construction",
      condominium: "Condominium",
      farm: "Farm",
      rooming: "Rooming",
      condoTownhomes: "Condo Townhomes",
      rental: "Rental",
      wellAndSeptic: "Well and Septic",
      agriculturalZoned: "Agricultural Zoned",
      mixedUse: "Mixed Use",
      order: ["singleFamily", "condominium", "condoTownhomes", "mixedUse", "construction", "wellAndSeptic", "rental", "rooming", "agriculturalZoned", "farm"],
    },
  };

  static getProductFromInvestor(i: Investor) {
    var p: any = {};

    p.id = i.id;

    p.availableCapital = i.availableCapital;
    //p.businessEntity = i.businessEntity;

    p.fullyOpen = i.mortgageCriteria.fullyOpen;
    p.length = i.mortgageCriteria.length;
    p.amortization = i.mortgageCriteria.amortization;
    p.incomeDocImportance = i.mortgageCriteria.incomeDocImportance;

    p.maxGDS = i.mortgageCriteria.maxGDS;
    p.maxTDS = i.mortgageCriteria.maxTDS;
    p.minCreditScore = i.mortgageCriteria.minCreditScore;
    p.amount = i.mortgageCriteria.amount;

    p.maxLTV = i.mortgageCriteria.maxLTV;
    p.fee = i.mortgageCriteria.fee;
    p.rate = i.mortgageCriteria.rate;
    p.locations = i.mortgageCriteria.locations;
    p.propertyLTV = i.mortgageCriteria.propertyLTV;

    return p;
  }
}
