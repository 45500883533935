import React, { useCallback, useEffect } from "react";
import clsx from "clsx";

import PropTypes from "prop-types";

import { Checkbox, FormControlLabel, InputAdornment, makeStyles, OutlinedInputProps, TextField } from "@material-ui/core";

import debounce from "lodash.debounce";

const useStyles = makeStyles((theme) => ({}));

export interface FalsifiableTextFieldProps {
  isNumber: boolean;
  includesDecimals?: boolean;
  prefix?: string;
  suffix?: string;
  defaultValue?: any;
  label?: string;

  onChange: (v: any) => void;
  falsePlaceholder: string;
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
  size?: "medium" | "small" | undefined;
  className?: any;
  debounceDependancy?: any;
}

const customParse = (value: any, props: FalsifiableTextFieldProps) => {
  if (value === "") return { value: value, displayValue: value };

  if (props.isNumber && props.includesDecimals && !isNaN(value)) {
    return { value: parseFloat(value), displayValue: formatWithCommas(parseFloat(value)) };
  } else if (props.isNumber && !props.includesDecimals && !isNaN(value)) {
    return { value: parseInt(value), displayValue: formatWithCommas(parseInt(value)) };
  } else {
    return { value: value, displayValue: value };
  }
};

const formatWithCommas = (x: any) => {
  return x.toLocaleString();
};

export default function FalsifiableTextField(props: FalsifiableTextFieldProps) {
  const classes = useStyles();

  const [state, setState] = React.useState<{ value: any; displayValue: string; checkbox?: boolean }>({ value: null, displayValue: "" });

  const handleChange = (newValue: any) => {
    var tmpState = { ...state };

    if (newValue && props.isNumber) newValue = newValue.toString().replace(",", "");

    if (newValue == null) {
      tmpState.value = null;
      tmpState.displayValue = "";
    } else if (newValue === "" || newValue === props.falsePlaceholder || newValue === false) {
      tmpState.value = false;
      tmpState.displayValue = props.falsePlaceholder;
    } else {
      tmpState.value = customParse(newValue, props).value;
      tmpState.displayValue = customParse(newValue, props).displayValue;
    }

    props.onChange(tmpState.value);
    setState(tmpState);
  };

  useEffect(() => {
    handleChange(props.defaultValue);
  }, []);

  const debounceHandleChange = useCallback(
    debounce((newValue) => handleChange(newValue), 150, { leading: false, trailing: true }),
    [props.defaultValue, props.debounceDependancy]
  );
  const inputProps = { inputMode: props.inputMode };
  if (props.includesDecimals) {
    inputProps.inputMode = "decimal";
    //inputProps.pattern = "[0-9]+(.[0-9]+)?";
  }
  const InputProps: Partial<OutlinedInputProps> = {};
  if (props.prefix) {
    if (!state.checkbox) InputProps.startAdornment = <InputAdornment position="start">{props.prefix}</InputAdornment>;
  }
  if (props.suffix) {
    if (!state.checkbox) InputProps.endAdornment = <InputAdornment position="end">{props.suffix}</InputAdornment>;
  }

  return (
    <TextField
      className={clsx(props.className)}
      size={props.size}
      value={state.displayValue}
      type="text"
      inputProps={inputProps}
      InputProps={InputProps}
      fullWidth
      variant="outlined"
      label={props.label}
      onChange={(e) => {
        setState({ ...state, displayValue: customParse(e.target.value, props).displayValue });
        debounceHandleChange(e.target.value);
      }}
    />
  );
}
