import React, { useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { FormControl, FormControlLabel, FormGroup, Checkbox, Box, Grid, TextField, InputAdornment, OutlinedInput, InputLabel } from "@material-ui/core";
import ProfileSetupWrapper from "../ProfileSetupWrapper";

import { setDocumentTitle } from "Components/Common/PageHelper";
import { UserContext } from "Authentication/UserProvider";
import Investor from "Models/Investor";
import ProfileSetupQuestion, { ProfileSetupQuestionLabel } from "../ProfileSetupQuestion";
import TrueFalseToggle from "Components/Common/TrueFalseToggle";
import { MAX_MORTGAGE_AMOUNT } from "Components/Common/Constants";
import { Alert } from "@material-ui/lab";
import MinMaxFieldWithOverrideCheckBox from "Components/Common/TextField/MinMaxFieldWithOverrideCheckBox";

const useStyles = makeStyles((theme) => ({
  subDesc: {
    color: "#9e9e9e",
    "& a": {
      color: "#687994",
    },
  },
  radioLabel: {
    textAlign: "center",
    margin: "0",
    "& $label": {
      fontSize: "0.8rem",
    },
  },
  alertInfo: {
    marginBottom: theme.spacing(2),
  },
}));

export default function OnboardingInvestorMortgageOptions() {
  useEffect(() => {
    setDocumentTitle("Mortgage Options | Profile Setup");
  }, []);

  const classes = useStyles();
  const user = useContext(UserContext);

  const [amount, setAmount] = React.useState(user.activeInvestor?.mortgageCriteria.amount ?? new Investor("").mortgageCriteria.amount);

  const [length, setLength] = React.useState(user.activeInvestor?.mortgageCriteria.length ?? new Investor("").mortgageCriteria.length);
  const [amortization, setAmortization] = React.useState(user.activeInvestor?.mortgageCriteria.amortization ?? new Investor("").mortgageCriteria.amortization);

  const [validationError, setValidationError] = React.useState(false);

  const checkForMinMaxError = (min: any, max: any, sectionName?: any) => {
    if (min === null || max === null || min === "" || max === "") return "Please enter both max & min values for " + sectionName + ".";

    if (min > max) {
      return "Min values must be less than or equal to Max.";
    }

    return false;
  };

  const handleLengthChange = (event: any) => {
    if (event.target.name) setLength({ ...length, [event.target.name as string]: event.target.checked ?? false });
  };
  const handleAmortizationChange = (event: any) => {
    setAmortization({ ...amortization, [event.target.name]: event.target.checked });
  };

  var form: React.RefObject<HTMLFormElement> = React.createRef();

  return (
    <ProfileSetupWrapper
      title="Mortgage Options"
      validateCallback={() => {
        var error = false;

        error = error || !!checkForMinMaxError(amount.min, amount.max);

        error = error || (!length.mo6 && !length.yr1 && !length.yr2 && !length.moreyr2);

        error = error || (!amortization.interestOnly && !amortization.yr20 && !amortization.yr25 && !amortization.yr30 && !amortization.yr35 && !amortization.yr40);

        error = (error || !form.current?.checkValidity()) ?? false;

        if (error) setValidationError(error);

        return !error;
      }}
      locallyStoreUserDataDelegate={() => {
        var currentInvestor = user.activeInvestor;
        if (currentInvestor) {
          currentInvestor.mortgageCriteria.amount = amount;
          currentInvestor.mortgageCriteria.length = length;
          currentInvestor.mortgageCriteria.amortization = amortization;
        }
      }}
    >
      <form autoComplete="off" ref={form}>
        <ProfileSetupQuestion error={validationError && !length.mo6 && !length.yr1 && !length.yr2 && !length.moreyr2} errorMsg="Please select at least one term length.">
          <ProfileSetupQuestionLabel>
            Available term lengths for your mortgage <br />
            <Typography className={classes.subDesc} variant="body2">
              Select all that apply
            </Typography>
          </ProfileSetupQuestionLabel>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={length.mo6} onChange={handleLengthChange} name="mo6" />} label="6 months" />
              <FormControlLabel control={<Checkbox checked={length.yr1} onChange={handleLengthChange} name="yr1" />} label="1 year" />
              <FormControlLabel control={<Checkbox checked={length.yr2} onChange={handleLengthChange} name="yr2" />} label="2 year" />
              <FormControlLabel control={<Checkbox checked={length.moreyr2} onChange={handleLengthChange} name="moreyr2" />} label="Over 2 years" />
            </FormGroup>
          </FormControl>
        </ProfileSetupQuestion>

        <ProfileSetupQuestion error={validationError && !amortization.interestOnly && !amortization.yr20 && !amortization.yr25 && !amortization.yr30 && !amortization.yr35 && !amortization.yr40} errorMsg="Please select at least one amortization period.">
          <ProfileSetupQuestionLabel>
            Available amortization periods for your mortgages *<br />
            <Typography className={classes.subDesc} variant="body2">
              Select all that apply
            </Typography>
          </ProfileSetupQuestionLabel>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={amortization.interestOnly} onChange={handleAmortizationChange} name="interestOnly" />} label="Interest Only" />
              <FormControlLabel control={<Checkbox checked={amortization.yr20} onChange={handleAmortizationChange} name="yr20" />} label="20 years" />
              <FormControlLabel control={<Checkbox checked={amortization.yr25} onChange={handleAmortizationChange} name="yr25" />} label="25 years" />
              <FormControlLabel control={<Checkbox checked={amortization.yr30} onChange={handleAmortizationChange} name="yr30" />} label="30 years" />
              <FormControlLabel control={<Checkbox checked={amortization.yr35} onChange={handleAmortizationChange} name="yr35" />} label="35 years" />
              <FormControlLabel control={<Checkbox checked={amortization.yr40} onChange={handleAmortizationChange} name="yr40" />} label="40 years" />
            </FormGroup>
          </FormControl>
        </ProfileSetupQuestion>

        <ProfileSetupQuestion error={validationError && !!checkForMinMaxError(amount.min, amount.max)} errorMsg={checkForMinMaxError(amount.min, amount.max, "mortgage amount")}>
          <ProfileSetupQuestionLabel>Minimum and maximum amounts mortgages in any position *</ProfileSetupQuestionLabel>

          {!validationError && !!checkForMinMaxError(amount.min, amount.max) && amount.min !== null && amount.max !== null && (
            <Alert className={classes.alertInfo} severity="error">
              Min value must be less than or equal to Max.
            </Alert>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MinMaxFieldWithOverrideCheckBox
                label="Min"
                type="min"
                debounceDependancy={amount}
                min={0}
                max={MAX_MORTGAGE_AMOUNT}
                suffix="$"
                defaultValue={amount.min}
                onChange={(v) => {
                  setAmount({ ...amount, min: v == null ? null : v });
                }}
                overrideLabel="No min"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <MinMaxFieldWithOverrideCheckBox
                label="Max"
                type="max"
                debounceDependancy={amount}
                min={0}
                max={MAX_MORTGAGE_AMOUNT}
                suffix="$"
                defaultValue={amount.max}
                onChange={(v) => {
                  setAmount({ ...amount, max: v == null ? null : v });
                }}
                overrideLabel="No max"
              />
            </Grid>
          </Grid>
        </ProfileSetupQuestion>
      </form>
    </ProfileSetupWrapper>
  );
}
