import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",

    overflow: "auto",

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
  noTopPadding: {
    paddingTop: 0,
  },
  noBottomPadding: {
    paddingBottom: 0,
  },
}));

interface Props extends PropsWithChildren<any> {
  className?: string;
}

export default function ContentWrapper(props: Props) {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.content, props.className, props.noTopPadding && classes.noTopPadding, props.noBottomPadding && classes.noBottomPadding)}>{props.children}</div>
    </>
  );
}
