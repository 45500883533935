import Investor from "Models/Investor";
import User from "../Authentication/User";
import firebase, { auth, functions, provider } from "./_firebase";

declare global {
  interface Window {
    verificationId: string;
    resolver: any;
  }
}

//Need to include <div id="2fa-captcha"></div> in the index.html file
export const recaptchaVerifier = (containerName: string) => {
  try {
    return new firebase.auth.RecaptchaVerifier(containerName, {
      size: "invisible",
      visibility: "hidden",
    });
  } catch {
    //
  }
};
export const clearRecaptchaVerifier = () => {
  if (window.recaptchaVerifier) {
    var e = document.getElementById("2fa-captcha");
    if (e) {
      window.recaptchaVerifier.clear();
      //e.innerHTML = "<div id='recaptcha'></div>";
    }
    console.log("> Cleared ReCAPTCHA");
  }
};

/*
export const shouldWaitForUserToUpdate = () => {
  console.log("> Previously logged in: ", !!localStorage.getItem("previouslyLoggedIn"));
  return !!localStorage.getItem("previouslyLoggedIn");
};
*/

export const maskPhone = (phone: string) => {
  return "+*******" + phone.substring(phone.length - 4);
};

export const maskEmail = (email: string) => {
  return email.replaceAll("(?<=.)[^@](?=[^@]*?[^@]@)|(?:(?<=@.)|(?!^)\\G(?=[^@]*$)).(?=.*[^@]\\.)", "*");
};

export const signOut = async () => {
  console.log("> Signing out user...");
  localStorage.removeItem("previouslyLoggedIn");
  await auth.signOut();
  console.log("> User signed out successfully.");
};

export function signInWithEmailAndPassword(email: string, password: string, cb: loginHandler) {
  console.log("> Signing in with email and password...");
  auth
    .signInWithEmailAndPassword(email, password)
    .then((res: firebase.auth.UserCredential) => {
      successfulInitialLogin(res, cb);
    })
    .catch((error) => {
      console.log(error);
      errorInitialLogin(error, cb);
    });
}

export function signInWithGoogle(cb: loginHandler) {
  console.log("> Signing in with Google...");
  auth
    .signInWithPopup(provider)
    .then(async (res: firebase.auth.UserCredential) => {
      successfulInitialLogin(res, cb);
      //await generateUserDocument(res.user);
    })
    .catch((error: any) => {
      errorInitialLogin(error, cb);
    });
}

export function createUserWithEmailAndPassword(email: string, password: string, cb: loginHandler) {
  console.log("> Create user account using email and password...");

  auth
    .createUserWithEmailAndPassword(email, password)
    .then((res: firebase.auth.UserCredential) => {
      successfulInitialLogin(res, cb);
    })
    .catch((error) => {
      errorInitialLogin(error, cb);
    });
}

export interface ILoginResults {
  isSuccessful: boolean;
  requireNewEmailVerification: boolean;
  require2FAEnrollment: boolean;
  require2FALogin: boolean;
  masked2FADestination: string;
  unmasked2FADestination: string;
  error: string | boolean;
}
declare type loginHandler = (res: ILoginResults) => any;

function successfulInitialLogin(res: firebase.auth.UserCredential, cb: loginHandler) {
  if (!res.user) {
    console.log(">! Something went wrong during the authentication flow.");

    return cb({
      isSuccessful: false,
      requireNewEmailVerification: false,
      require2FAEnrollment: false,
      require2FALogin: false,
      masked2FADestination: "+1**********",
      unmasked2FADestination: "+1**********",
      error: "General Error",
    });
  }

  console.log("> Successfully authenticated user.");
  cb({
    isSuccessful: true,
    requireNewEmailVerification: res?.user?.emailVerified ? false : true,
    require2FAEnrollment: (res?.user?.multiFactor?.enrolledFactors?.length ?? 0) > 0 || User.REQUIRE_2FA,
    require2FALogin: (res?.user?.multiFactor?.enrolledFactors?.length ?? 0) > 0,
    masked2FADestination: "+1**********",
    unmasked2FADestination: "+1**********",
    error: false,
  });
}

function errorInitialLogin(error: any, cb: loginHandler) {
  if (error.code === "auth/unverified-email") {
    console.log("> Successfully authenticated but email has not been verified.");

    localStorage.removeItem("previouslyLoggedIn");
    window.resolver = error.resolver;
    cb({
      isSuccessful: true,
      requireNewEmailVerification: true,
      require2FAEnrollment: false,
      require2FALogin: false,
      masked2FADestination: "+1**********",
      unmasked2FADestination: "+1**********",
      error: false,
    });
  } else if (error.code === "auth/multi-factor-auth-required") {
    console.log("> Partially authenticated but require 2FA.");

    localStorage.removeItem("previouslyLoggedIn");
    window.resolver = error.resolver;

    cb({
      isSuccessful: true,
      requireNewEmailVerification: false,
      require2FAEnrollment: false,
      require2FALogin: true,
      masked2FADestination: maskPhone(window.resolver.hints[0].phoneNumber),
      unmasked2FADestination: window.resolver.hints[0].phoneNumber,
      error: false,
    });
  } else {
    console.log(">! Something went wrong during the authentication flow.");
    localStorage.removeItem("previouslyLoggedIn");
    cb({
      isSuccessful: false,
      requireNewEmailVerification: false,
      require2FAEnrollment: false,
      require2FALogin: false,
      masked2FADestination: "+1**********",
      unmasked2FADestination: "+1**********",
      error: error.code,
    });
  }
}

export const enroll2FAMobile = async (phoneNumber: string, user: User) => {
  console.log("> Enrolling for 2FA...");
  try {
    const firebaseUser = auth.currentUser;

    if (firebaseUser != null) {
      const session = await firebaseUser.multiFactor.getSession();

      user.phone = phoneNumber;
      await user.save();

      const phoneOpts = {
        phoneNumber,
        session,
      };

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      window.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneOpts, window.recaptchaVerifier);

      if (window.verificationId == null) {
        console.log(">! Something went wrong during 2FA enrollment.");
        return false;
      } else {
        console.log("> Verification code sent for 2FA enrollment.");
        return true;
      }
    } else {
      console.log(">! Cannot enroll for 2FA before signing in.");
      return false;
    }
  } catch (e) {
    console.log(">! Something went wrong while enrolling for 2FA.", e);
    return false;
  }
};

export const complete2FAMobileEnrollment = async (code: string, user: User) => {
  console.log("> Validating 2FA code for enrollment...");
  const firebaseUser = auth.currentUser;

  if (firebaseUser != null) {
    try {
      const cred: firebase.auth.AuthCredential = firebase.auth.PhoneAuthProvider.credential(window.verificationId, code);

      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

      await firebaseUser.multiFactor.enroll(multiFactorAssertion, "phone number");
      user.enrolledFor2FA = true;
      await user.save();

      console.log("> Successfully enrolled for 2FA.");
      return true;
    } catch (e) {
      console.log(">! Something went wrong while asserting the verification code for 2FA enrollment.", e);
      return false;
    }
  } else {
    console.log(">! Cannot verify 2FA code before signing in.");
    return false;
  }
};

export const sendCodeFor2FAMobile = async () => {
  console.log("> Sending code for 2FA...");
  try {
    const phoneOpts = {
      multiFactorHint: window.resolver.hints[0],
      session: window.resolver.session,
    };

    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    window.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneOpts, window.recaptchaVerifier);

    console.log("> Successfully sent 2FA code.");
    return true;
  } catch (e) {
    console.log(">! Something went wrong while attempting to send a 2FA code.", e);
    return false;
  }
};

export const verify2FAMobile = async (code: string, user: User) => {
  console.log("> Verifying 2FA code...");
  try {
    const cred = firebase.auth.PhoneAuthProvider.credential(window.verificationId, code);

    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    const credential = await window.resolver.resolveSignIn(multiFactorAssertion);
    localStorage.setItem("previouslyLoggedIn", "1");

    console.log("> Successfully verified 2FA code.");
    return true;
  } catch (e) {
    console.log(">! Something went wrong while attempting to assert the 2FA code.", e);
    localStorage.removeItem("previouslyLoggedIn");
    return false;
  }
};

/*
const getUserDocument = async (uid: string | null) => {
  if (!uid) return User.NullUser();
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    const userData = userDocument.data();

    return await User.fromUserDocument(uid, userData);
  } catch (error) {
    return User.NullUser();
  }
};
*/
// NEW NEW NEW NEW NEW NEW

export const sendVerificationEmail = async () => {
  console.log("> Sending verification email...");
  var actionCodeSettings = {
    url: process.env.REACT_APP_DOMAIN + "authenticate",
  };

  if (auth.currentUser) {
    try {
      await auth.currentUser.sendEmailVerification(actionCodeSettings);

      console.log("> Successfully sent verification email.");
      return true;
    } catch {
      console.log(">! Something went wrong while attempting to send verification email.");
      return false;
    }
  } else {
    console.log(">! Cannot send verification email before signing in.");
    return false;
  }
};

export const updateUser = async (user: User | null) => {
  console.log("> Updating user data...");

  if (!user) return false;
  if (!user.uid) return false;

  console.log("> Paramters for upading user data are valid...");

  try {
    var updateUserAPI = functions.httpsCallable("updateUser");
    var results = await updateUserAPI({ user: user.getUserDocument() });

    if (results.data && !results.data.error) {
      console.log("> User data successfully updated.");
      return true;
    } else {
      console.log(">! Something went wrong trying to update user data.");
      return false;
    }
  } catch (e) {
    console.log(">! Something went wrong trying to update user data.", e);
    return false;
  }
};

export const getUser = async (fromAuthStateChangedHandler: boolean = false): Promise<User> => {
  console.log("> Getting user data...");

  try {
    var getUserAPI = functions.httpsCallable("getUser");
    var results = await getUserAPI();

    if (results.data && !results.data.error) {
      console.log("> Successfully retrieved user data.");

      return await userFromFirebaseDoc(results);
    } else {
      console.log(">! Something went wrong while trying to retrieve user data.", results);
      return User.NullUser();
    }
  } catch (e) {
    console.log(">!! Something went wrong while trying to retrieve user data.", e);
    return User.NullUser();
  }
};

const userFromFirebaseDoc = async (res: firebase.functions.HttpsCallableResult) => {
  console.log("> Parsing user data...");
  let user = User.NullUser();

  user.uid = res.data.uid;
  user.email = res.data.email;
  user.phone = res.data.phone;
  user.firstName = res.data.firstName;
  user.lastName = res.data.lastName;

  user.address = res.data.address;
  user.dob = res.data.dob;
  if (res.data.docs) user.docs = { ...res.data.docs };

  user.enrolledFor2FA = res.data.enrolledFor2FA;
  user.emailVerified = res.data.emailVerified;

  user.profileCompletion = {
    ...res.data.profileCompletion,
  };

  user.role = res.data.role;

  user.avatarUrl = res.data.avatarUrl;

  user.mailingList = { ...res.data.mailingList };

  user.permissions = [...res.data.permissions];

  user.investorsRaw = { ...res.data.investors };
  user.setActiveInvestorId(res.data.activeInvestorId);
  /*
  if (user.role === User.Roles.INVESTOR) {
    console.log("> Parsing investor data...");
    //console.log(res);
    const i = await getInvestorDoc(res.data.uid);
    user.investorProfile = i === false ? new Investor(res.data.uid) : i;

    console.log("> Successfully parsed investor data...");
  }
  */

  console.log("> Successfully parsed user data.");
  return user;
};

export const getAllUsersStatus = async (fromAuthStateChangedHandler: boolean = false): Promise<any[]> => {
  console.log("> Getting all users and their status...");

  try {
    var getUserAPI = functions.httpsCallable("getAllUsersStatus");
    var results = await getUserAPI({ currentUserVersion: User.VERSION });

    if (results.data && !results.data.error) {
      console.log("> Successfully retrieved all users and their status.");

      return results.data;
    } else {
      console.log(">! Something went wrong while trying to retrieve all users and their status.");
      return [];
    }
  } catch (e) {
    console.log(">! Something went wrong while trying to retrieve all users and their status.");
    return [];
  }
};

//export const provider = new firebase.auth.GoogleAuthProvider();
//export const auth = firebase.auth();
//export const functions = firebase.functions();
//export const db = firebase.firestore();
//export const fieldval = firebase.firestore.FieldValue;
//export default firebase;
export { auth } from "./_firebase";
