import React, { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  childWrapper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  error: {
    border: "2px solid #f44336;",
  },
  errorMsg: {
    marginBottom: theme.spacing(2),
  },
}));

export interface SinglePageQuestionProps {
  error?: boolean;
  className?: any;
  id?: any;
  label?: any;
  labelIsComponent?: boolean;
  errorMsg?: string | boolean;
  children?: ReactNode;
}

export default function SinglePageQuestion(props: SinglePageQuestionProps) {
  const classes = useStyles();
  return (
    <Paper id={props.id} className={clsx(classes.childWrapper, props.error && classes.error, props.className)}>
      {props.error && (
        <Alert severity="error" className={classes.errorMsg}>
          {props.errorMsg}
        </Alert>
      )}

      {props.labelIsComponent ? (
        <>
          {props.label && (
            <>
              {props.label}
              <Divider className={classes.divider} />
            </>
          )}
        </>
      ) : (
        <>
          {props.label && (
            <>
              <Typography component="div">{props.label}</Typography>
              <Divider className={classes.divider} />
            </>
          )}
        </>
      )}
      {props.children}
    </Paper>
  );
}
