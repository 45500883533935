import React, { useState, useEffect } from "react";
import { auth, getUser } from "Firebase/AuthService";
import User from "Authentication/User";
import { useHistory, useLocation } from "react-router-dom";
const UserContext = React.createContext<User>(User.NullUser());
const UserDispatchContext = React.createContext<React.Dispatch<React.SetStateAction<User>> | undefined>(undefined);

const UserProvider: React.FC<{}> = ({ children }) => {
  const [user, setUser] = useState(User.NullUser());
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (userAuth) => {
        if (userAuth) {
          console.log("> Auth state changed and provided new user info.");
          var user = await getUser(true);
          user.updateCount++;
          setUser(user);
        } else {
          console.log("> Auth state changed and did not provide user info.");
          var nullUser = User.NullUser();
          nullUser.updateCount++;
          setUser(nullUser);
        }
      },
      (error) => {
        console.log("> Auth state changed and caught an error.");
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  //TODO: !!!! USE useLocation if renders keep signing out the user
  if (location.pathname === "/login" || location.pathname === "/signup") return <>{children}</>;
  else return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  /*
  return (
    <>
      <UserContext.Provider value={user}>
        <UserDispatchContext.Provider value={setUser}>{children}</UserDispatchContext.Provider>
      </UserContext.Provider>
    </>
  );*/
};

export { UserProvider, UserContext, UserDispatchContext };
