import React, { PropsWithChildren, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useHistory, Router } from "react-router-dom";

import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button, Divider, IconButton, Paper } from "@material-ui/core";
import SubPageBar from "Components/Navigation/SubPageBar";
import ContentWrapper from "Components/Common/ContentWrapper";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { FormatListBulleted } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import User from "Authentication/User";
import { UserContext } from "Authentication/UserProvider";
import ButtonWithSpinner from "Components/Common/Button/ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  currentStep: {
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  totalSteps: {
    fontSize: "0.7em",
    marginTop: "-5px",
  },
  titleContainer: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
  },
  titles: {
    marginLeft: theme.spacing(2),
  },
  fullCircle: {
    color: "#efefef",
  },
  alert: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  nextButton: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },
  noMail: {
    color: "#8e8e8e",
  },
  buttonContainer: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export interface CircularProgressWithLabelProps {
  current: number;
  total: number;
}

function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  const classes = useStyles();

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" className={classes.fullCircle} size={50} value={100} />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={50} variant="determinate" value={(100 * props.current) / props.total} />
      </Box>
      {props.current !== 0 && (
        <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
          <div>
            <Typography className={classes.currentStep} align="center" variant="caption" component="p" color="textSecondary">{`${props.current}`}</Typography>
            <Typography className={classes.totalSteps} align="center" variant="caption" component="p" color="textSecondary">{`of ${props.total}`}</Typography>
          </div>
        </Box>
      )}
    </Box>
  );
}

export interface ProfileSetupWrapperProps extends PropsWithChildren<any> {
  title: string;
  validateCallback: () => void;
  locallyStoreUserDataDelegate: () => void;
  isOnboardingProfile?: boolean;
}

export default function ProfileSetupWrapper(props: ProfileSetupWrapperProps) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [processing, setProcessing] = React.useState(false);
  const [errorCount, setErrorCount] = React.useState(0);

  var step = parseInt(history.location.pathname.split("/").pop() ?? "1");
  if (isNaN(step)) step = 1;
  console.log(step);
  /*
  var totalSteps = step === 0 ? 999 : User.TotalProfileSetupStepsForRoles[(user.role ?? User.Roles.INVESTOR) as string]; //+ 1;
  totalSteps = totalSteps ? totalSteps : 1;
  */

  const handleSave = async (e: any, locallyStoreUserDataDelegate: any, validateCallback: any) => {
    e.preventDefault();
    const valid = validateCallback();

    if (!valid) setErrorCount(errorCount + 1);
    else setErrorCount(0);

    if (valid) {
      locallyStoreUserDataDelegate();
      if (props.isOnboardingProfile) {
        user.setProfileOnboardingCompletedSteps(step);
        if (user.activeInvestor) {
          user.setMyPendingInvestorOnboardingStatusToOnboarding();
          await user.activeInvestor?.save();
        }
        await user.save();
      } else {
        user.setMyPendingInvestorOnboardingCompletedSteps(step);
        //user.setMyPendingInvestorOnboardingStatusToOnboarding();
        console.log("saving");
        await user.activeInvestor?.save();
        console.log("saved");
      }

      console.log("next", getNextUrl(step + 1));
      history.push(getNextUrl(step + 1));
      //console.log("next", getNextUrl(step + 1));
      //setProcessing(false);
    } else {
      setProcessing(false);
    }
  };

  function getNextUrl(step: number) {
    if (user.isOnboardingComplete()) {
      return "/signup/complete";
    } else {
      return user.getNextOnboardingPageLink();
    }
  }

  const handleBack = () => {
    console.info("Save Data");
  };

  const handleDoneWithoutMail = async () => {
    if (props.isOnboardingProfile) user.setProfileOnboardingCompletedSteps(step);
    else user.setMyPendingInvestorOnboardingCompletedSteps(step);

    if (props.isOnboardingProfile) user.setProfileOnboardingComplete();
    else user.setMyPendingInvestorOnboardingCompleted();

    //TODO
    //await saveUserDocument(user);

    history.push("/lenderfinder");
  };

  const handleDoneWithMail = async () => {
    if (!user.mailingList) user.mailingList = {};

    if (props.isOnboardingProfile) user.setProfileOnboardingCompletedSteps(step);
    else user.setMyPendingInvestorOnboardingCompletedSteps(step);

    if (props.isOnboardingProfile) user.setProfileOnboardingComplete();
    else user.setMyPendingInvestorOnboardingCompleted();
    user.setMailingListNewConfirmation();

    //TODO
    //await saveUserDocument(user);

    history.push("/lenderfinder");
  };

  useEffect(() => {
    if (errorCount) {
      const refs = [...(document.querySelectorAll("div[error]:not([error='false'])") as any)];

      if (refs && refs.length > 0) refs[0].scrollIntoView({ behavior: "smooth" });
    }
  }, [errorCount]);

  return (
    <>
      <SubPageBar title="Account Onboarding" transparent={false} />
      <ContentWrapper>
        <Paper className={classes.titleContainer}>
          {step >= 1 && false && !props.isOnboardingProfile && (
            <IconButton
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {!props.isOnboardingProfile && <CircularProgressWithLabel total={User.InvestorOnboardingDetails[user.activeInvestor?.investorType ?? "indv"].TotalSteps} current={step} />}
          <Typography className={classes.titles} align="center" variant="h5" component="h5">
            {props.title}
          </Typography>
        </Paper>

        {errorCount !== 0 && (
          <Alert className={classes.alert} severity="error">
            Please complete all sections with required fields (*).
          </Alert>
        )}
        {props.children}
        {errorCount !== 0 && (
          <Alert className={classes.alert} severity="error">
            Please complete all sections with required fields (*).
          </Alert>
        )}
        <div className={classes.actions}>
          {
            /*step <= totalSteps*/ true && (
              <>
                <ButtonWithSpinner
                  showSpinner={processing}
                  fullWidth={true}
                  variant="contained"
                  size="large"
                  color="secondary"
                  endIcon={!processing ? <ArrowForwardIcon /> : null}
                  onClick={async (e: any) => {
                    setProcessing(true);
                    handleSave(e, props.locallyStoreUserDataDelegate, props.validateCallback);
                  }}
                >
                  {processing ? "Saving..." : "Save & continue"}
                </ButtonWithSpinner>
              </>
            )
          }
          {
            /*step === totalSteps &&*/ false && (
              <>
                <Button
                  className={classes.noMail}
                  disableElevation={true}
                  size="large"
                  onClick={() => {
                    handleDoneWithoutMail();
                  }}
                >
                  No, not at this time.
                </Button>
                <Button
                  className={classes.nextButton}
                  disableElevation={true}
                  size="large"
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => {
                    handleDoneWithMail();
                  }}
                >
                  Yes and complete setup.
                </Button>
              </>
            )
          }
        </div>
      </ContentWrapper>
    </>
  );
}
