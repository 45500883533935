import React, { PropsWithChildren, useContext, useEffect, useState } from "react";

import { Button, CircularProgress, Divider, InputAdornment, makeStyles, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { Link as RouterLink, Redirect, RouteComponentProps } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

import { auth, signInWithGoogle, signOut, maskPhone, updateUser, complete2FAMobileEnrollment, verify2FAMobile, sendVerificationEmail, maskEmail } from "Firebase/AuthService";
import { default as GoogleIcon } from "Icons/Google";

import logo from "full-logo.svg";
import logoDev from "full-logo-dev.svg";
import { UserContext } from "Authentication/UserProvider";
import ContentWrapper from "Components/Common/ContentWrapper";
import SubPageBar from "Components/Navigation/SubPageBar";
import { SettingsPhone } from "@material-ui/icons";

import isInt from "validator/lib/isInt";
import ButtonWithSpinner from "Components/Common/Button/ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100vh",
  },
  paper: { maxWidth: 420, flex: 1, margin: "auto" },
  formInput: {
    width: "80%",
  },
  form: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  logoContainer: {
    flexGrow: 1,
    lineHeight: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },
  logo: {
    height: theme.spacing(5),
  },
  forgotPasswordLinkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "0",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  buttonWithSpinner: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    right: theme.spacing(1),
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props extends PropsWithChildren<any> {}
type LocationState = {
  phone?: string;
  maskedPhone: string;
  referrer: string;
};

var timer: any = null;

export default function VerifyEmail(props: RouteComponentProps<Props, {}, LocationState>) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();

  const [codeSent, setCodeSent] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const allowEmailPasswordLogin = true;

  const doVerifiedCheck = () => {
    var emailVerified = false;

    auth.currentUser?.reload().then(() => {
      if (auth.currentUser) {
        if (!auth.currentUser.emailVerified) {
          timer = setTimeout(function () {
            doVerifiedCheck();
          }, 1000);
        } else {
          user.emailVerified = true;
          user.save().then((success) => {
            if (!success) {
              setError(<Alert severity="error">There seems to be an issue with verifiying your email, please contact your Blossom rep.</Alert>);
            } else {
              history.replace("/authenticate");
            }
          });
        }
      } else {
        history.replace("/signup");
      }
    });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
    // seslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {completed && props.location.state.referrer === "/signup" && <Redirect to={{ pathname: "/signup/complete" }} />}
      {completed && props.location.state.referrer === "/login" && <Redirect to={{ pathname: "/login/2fa/authenticate" }} />}
      <SubPageBar
        transparent={false}
        isFullScreen={true}
        title="Verify Email"
        onBackClick={() => {
          history.push("/signup");
        }}
      ></SubPageBar>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <form className={classes.form} noValidate autoComplete="off">
            {!error && (
              <Typography align="center" variant="body1">
                {!codeSent && (
                  <>
                    We need to verify your email. Please click the button below to send a verification email to <b>{maskEmail(user.email ?? "")}</b>.
                  </>
                )}
                {codeSent && (
                  <>
                    A verification email was sent to <b>{maskEmail(user.email ?? "")}</b>. Please click the link within it to verify your email.
                  </>
                )}
              </Typography>
            )}
            <>
              {error}
              {!error && (
                <ButtonWithSpinner
                  showSpinner={codeSent}
                  fullWidth={true}
                  variant="contained"
                  size="large"
                  color="secondary"
                  endIcon={!codeSent ? <ArrowForwardIcon /> : null}
                  onClick={() => {
                    doVerifiedCheck();

                    setCodeSent(true);
                    setError(null);
                    sendVerificationEmail().then(function (success) {
                      if (success) {
                        setCodeSent(true);
                      } else {
                        setCodeSent(false);
                        setError(<Alert severity="error">There seems to be an issue with sending you the verification email, please contact your Blossom rep.</Alert>);
                      }
                    });
                  }}
                >
                  {!codeSent && <>Send verification email</>}
                  {codeSent && <>Waiting...</>}
                </ButtonWithSpinner>
              )}
            </>
          </form>
        </Paper>
      </div>
    </>
  );
}
