import { useContext, useEffect, useState } from "react";

import { Link as RouterLink, Redirect, useHistory } from "react-router-dom";
import { Box, Button, Divider, makeStyles, Typography } from "@material-ui/core";
import { UserContext } from "Authentication/UserProvider";
import PageWrapper from "Components/Common/PageWrapper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LoadingSpinner from "Components/Common/LoadingSpinner";
import SubPageRoutes from "./SubPageRoutes";
import IconButton from "@material-ui/core/IconButton";
import { getAllPendingOrApprovedInvestorsInfo } from "Firebase/InvestorService";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import Investor, { InvestorStatus } from "Models/Investor";
import { InvestorTypeHelper } from "Shared/Investor/InvestorType";
import { format, formatDistance } from "date-fns";
import { Skeleton } from "@material-ui/lab";
import { SKELETON_TABLE_ROW_HEIGHT } from "Utils/Skeleton";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  type: {
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  warningColor: {
    color: theme.palette.error.main,
    fontSize: "1rem",
  },
}));

var rows: any[] = [];
export default function ListInvestors() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [investors, setInvestors] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  //const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);

  const updateData = (isMounted: boolean) => {
    if (isMounted) {
      setLoading(true);

      const promise = getAllPendingOrApprovedInvestorsInfo().then((investors) => {
        rows = [];
        investors.forEach((ii: any) => {
          const i: Investor = Investor.fromDataDocument(ii.id, ii.data);
          console.log(ii);
          rows.push({
            id: ii.id,
            name: (
              <>
                {i.getDisplayName()[0]}
                <Typography className={classes.type} variant="subtitle2">
                  {i.getDisplayName()[1]} ({InvestorTypeHelper.toReadableString(i.investorType ?? "indv") + (i.investorSubType !== "" ? i.investorSubType : "")})
                </Typography>
              </>
            ),
            totalCapital: i.availableCapital[0].now ?? "-",
            maxLTV: i.mortgageCriteria.maxLTV ?? "-",
            lastDeal: i.lastDeal ? formatDistance(new Date(), new Date(i.lastDeal)) + " ago" : "-",
            status: i.status,
          });
        });
        if (isMounted) {
          setLoading(false);
          setInvestors(rows);
        }
      });
    }
    /*
    const promise = getUserInvites().then((invites) => {
      rows = [];
      invites.forEach((i: any) => {
        rows.push(createData(i.firstName + " " + i.lastName, i.email, i.role, i.created, i.emailLastSent));
      });
      setLoading(false);
      setUsers(rows);
    });*/
  };

  useEffect(() => {
    let isMounted = true;

    updateData(isMounted);

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <PageWrapper title="Investors" subPages={SubPageRoutes} selectedPageRoute="">
        <Box position="relative">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Capital Available</TableCell>
                  <TableCell align="right">Max LTV</TableCell>
                  <TableCell align="right">Last Deal</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Skeleton height={SKELETON_TABLE_ROW_HEIGHT} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {investors.length > 0 ? (
                      rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">${row.totalCapital.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</TableCell>
                          <TableCell align="right">{row.maxLTV}</TableCell>
                          <TableCell align="right">{row.lastDeal}</TableCell>
                          <TableCell align="right">
                            {row.status} {row.status === InvestorStatus.PendingApproval ? <WarningIcon className={classes.warningColor} /> : null}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="small"
                              aria-label="delete"
                              onClick={() => {
                                history.push(`/investors/details/${row.id}`);
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              aria-label="send"
                              onClick={() => {
                                history.push(`/investors/details/${row.id}`);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>No investors found.</TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PageWrapper>
    </>
  );
}
