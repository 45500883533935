import firebase, { functions } from "./_firebase";

export const getPendingInvestors = async () => {
  try {
    console.log("> Retrieving pending invites...");
    var getPendingInvestorsAPI = functions.httpsCallable("getPendingInvestors");
    var results = await getPendingInvestorsAPI();
    console.log("> Retrieved pending invites.");
    if (results.data.error) return [];

    return results.data.invites;
  } catch (e) {
    return [];
  }
};

export const addPendingInvestor = async (userInfo: { email: string; name: string; role: string; investorType: string; investorSubType: string; members: { name: string; email: string; status: string; completedSteps: number }[] }) => {
  try {
    var addPendingInvestorAPI = functions.httpsCallable("addPendingInvestor");
    var results = await addPendingInvestorAPI(userInfo);
    console.log(results);
    if (results.data.error) return results.data.error;
    return false;
  } catch (e) {
    return false;
  }
};

export const deleteUserInvite = async (id: string) => {
  try {
    var deleteUserInviteAPI = functions.httpsCallable("deleteUserInvite");
    var results = await deleteUserInviteAPI({ id: id });
    if (results.data.error) return false;
    return true;
  } catch (e) {
    return false;
  }
};

export const notifyUserForPendingInvestor = async (id: string) => {
  try {
    var notifyUserForPendingInvestorAPI = functions.httpsCallable("notifyUserForPendingInvestor");
    var results = await notifyUserForPendingInvestorAPI({ id: id });
    if (results.data.error) return false;
    return true;
  } catch (e) {
    return false;
  }
};

export const savePendingInvestorData = async (pendingInvestor: any) => {
  console.log("STUB! Save pending investor info");
  return pendingInvestor;
};
