import React, { useContext, useEffect, useState } from "react";

import { Button, CircularProgress, Divider, makeStyles, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { Link as RouterLink, Redirect, useLocation } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

import { auth, signInWithGoogle, signInWithEmailAndPassword, signOut, sendCodeFor2FAMobile, maskPhone, ILoginResults, recaptchaVerifier } from "Firebase/AuthService";
import { default as GoogleIcon } from "Icons/Google";

import logo from "full-logo.svg";
import logoDev from "full-logo-dev.svg";
import { UserContext } from "Authentication/UserProvider";
import ContentWrapper from "Components/Common/ContentWrapper";
import SubPageBar from "Components/Navigation/SubPageBar";
import ButtonWithSpinner from "Components/Common/Button/ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100vh",
  },
  paper: { maxWidth: 420, flex: 1, margin: "auto" },
  formInput: {
    width: "80%",
  },
  form: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  logoContainer: {
    flexGrow: 1,
    lineHeight: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },
  logo: {
    height: theme.spacing(5),
  },
  forgotPasswordLinkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "0",
  },
  buttonWithSpinner: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

declare global {
  interface Window {
    recaptchaVerifier: any;
  }
}

window.recaptchaVerifier = recaptchaVerifier("2fa-captcha");

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function LogIn() {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();

  window.recaptchaVerifier.reset();

  useEffect(() => {
    signOut();
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any | null>(null);
  const [processing, setProcessing] = useState(false);

  const [results, setResults] = React.useState<ILoginResults>({
    isSuccessful: false,
    requireNewEmailVerification: false,
    require2FAEnrollment: false,
    require2FALogin: false,
    masked2FADestination: "",
    unmasked2FADestination: "",
    error: false,
  });

  if (query.get("referrer") && query.get("referrer") !== "") localStorage.setItem("referrer", query.get("referrer") ?? "");

  const signInWithEmailAndPasswordHandler = (event: any, email: string, password: string) => {
    event.preventDefault();
    setProcessing(true);

    signInWithEmailAndPassword(email, password, (res) => {
      if (res.error) {
        setProcessing(false);
        setError(
          <>
            <Alert severity="warning">If you signed up using your Google account click 'Log in with Google'.</Alert>
            <Alert severity="error">Invalid email or password.</Alert>
          </>
        );
      } else {
        if (res.require2FALogin) {
          sendCodeFor2FAMobile().then(() => {
            setResults({ ...res });
          });
        } else {
          setResults({ ...res });
        }
      }
    });
  };

  let logInWithGoogle = () => {
    setProcessing(true);
    signInWithGoogle((res) => {
      if (res.error) {
        setProcessing(false);
        setError(<Alert severity="error">Unable to login via Google.</Alert>);
      } else {
        setResults({ ...res });
      }
    });
  };

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  const allowEmailPasswordLogin = true;

  const onFormSubmit = (e: any) => {
    signInWithEmailAndPasswordHandler(e, email, password);
  };

  return (
    <>
      {results.isSuccessful && <Redirect push to={{ pathname: "/authenticate", state: { ...results } }} />}
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Alert severity="info">If you haven't signed up previously, please follow the instruction in your email and/or request access from your administrator.</Alert>
          <form className={classes.form} noValidate autoComplete="off" onSubmit={onFormSubmit}>
            <div className={classes.logoContainer}>
              <img src={process.env.REACT_APP_ENV === "prod" ? logo : logoDev} className={classes.logo} alt="Blossom MS" />
            </div>
            {allowEmailPasswordLogin && (
              <>
                {error}
                <div>
                  <TextField fullWidth autoComplete="email" type="email" name="userEmail" label="Email" variant="outlined" onChange={onChangeHandler} />
                </div>
                <div>
                  <TextField fullWidth autoComplete="current-password" type="password" name="userPassword" label="Password" variant="outlined" onChange={onChangeHandler} />
                </div>
                <div className={classes.forgotPasswordLinkContainer}>
                  <Link component={RouterLink} to="/login/forgot">
                    Forgot Password?
                  </Link>
                </div>
                <div className={classes.buttonWithSpinner}>
                  <Button type="submit" fullWidth disabled={processing} variant="contained" size="large" color="primary" endIcon={<ArrowForwardIcon />}>
                    Log in
                  </Button>
                  {processing && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
                </div>
                {/*
                <Divider variant="middle" /> */}
              </>
            )}

            {/*
            <ButtonWithSpinner
              showSpinner={processing}
              fullWidth
              variant="contained"
              size="large"
              color="default"
              startIcon={<GoogleIcon />}
              onClick={(event: any) => {
                event.preventDefault();
                logInWithGoogle();
              }}
            >
              Log in with Google
            </ButtonWithSpinner>

            <Divider variant="middle" />
            */}

            {/*
            <Typography align="center" variant="body2">
              Don't have an account?{" "}
              <Link replace component={RouterLink} color="secondary" to="/signup">
                Create one
              </Link>{" "}
            </Typography>*/}
          </form>
        </Paper>
      </div>
    </>
  );
}
